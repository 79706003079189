import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { SxProps } from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";

export const useStylesCommon = makeStyles((theme: Theme) =>
  createStyles({
    formStyle: {
      background: "#EBEBEB",
      boxShadow: "0px 7px 16px rgba(0, 0, 0, 0.08)",
      borderRadius: "10px",
      textAlign: "left",
      padding: "1.7vw 2.5vw",
      width: "42.9vw",
      display: "flex;",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    primaryButton: {
      background: "var(--first-color)",
      fontFamily: "var(--bodyText-font-family)",
      fontWeight: 500,
      fontSize: "16px",
      color: "var(--button-text-color)",
      borderRadius: "10px",
      textTransform: "inherit",
      "&:hover": {
        background: "var(--first-color)",
        color: "var(--button-text-color)",
        boxShadow: "0 0 10px var(--first-color)",
        border: "1px solid var(--first-color)",
      },
    },
    outlineButton: {
      borderRadius: "10px",
      border: " 0.5px solid var(--first-color)",
      fontFamily: "var(--bodyText-font-family);",
      fontWeight: 500,
      textTransform: "inherit",
      background: "transparent",
      "&:hover": {
        border: " 0.5px solid var(--first-color)",
        boxShadow: "0 0 10px var(--first-color)",
        background: "transparent",
      },
    },
    disabledOutlineButton: {
      borderRadius: "10px",
      border: " 0.5px solid var(--first-color)",
      fontFamily: "var(--bodyText-font-family);",
      fontWeight: 500,
      textTransform: "inherit",
      background: "transparent",
    },
    micAndVideoOnPopup: {
      cursor: "pointer",
      width: "40px !important",
      height: "40px !important",
      padding: "7px",
      borderRadius: "50%",
      border: "1px solid black",
      "&:hover": {
        background: "#E0E0E0",
      },
    },
    micAndVideoOff: {
      cursor: "pointer",
      width: "40px !important",
      height: "40px !important",
      padding: "7px",
      borderRadius: "50%",
      color: "white",
      background: "#ff2400",
      "&:hover": {
        background: "#ed2929",
        color: "white",
      },
    },
    micAndVideoOn: {
      cursor: "pointer",
      width: "40px !important",
      height: "40px !important",
      padding: "7px",
      borderRadius: "50%",
      color: "white",
      border: "1px solid white",
      "&:hover": {
        background: "#808080",
        color: "black",
      },
    },
    myVideo: {
      width: "78px",
      height: "60px",
      position: "absolute",
      top: "9%",
      left: "1%",
      zIndex: 7,
      borderRadius: "10px",
      border: "2px solid var(--first-color)",
    },
    userVideoDiv: {
      width: "225px",
      maxWidth: "600px",
      maxHeight: "450px",
      overflow: "hidden",
    },
    userVideo: {
      width: "100%",
      height: "100%",
      borderRadius: "20px",
      border: "2px solid var(--first-color)",
      zIndex: 20,
    },
    draggingCursor: {
      cursor: "all-scroll",
    },
    doubleArrow: {
      width: "24px !important",
      height: "20px !important",
      color: "var(--first-color)",
    },
  })
);

export const fullScreenStyle = {
  display: "flex",
  justifyContent: "flex-end",
  bottom: 5,
  flexDirection: "column",
  position: "fixed",
  zIndex: 15,
  boxShadow: "0 3px 10px rgba(0, 0, 0, 0.14)",
  height: "100vh",
  borderRadius: "10px",
};

export const nonFullScreenStyle = (
  isVideo: boolean,
  isFullScreen: boolean
): SxProps => {
  const style: SxProps = {
    position: "absolute",
    bottom: 10,
    right: isFullScreen ? 0 : undefined,
  };
  if (!isFullScreen) {
    style.left = isVideo ? 25 : 260;
  }
  return style;
};

export const userVideoStyle = {
  width: "100%",
  height: "100%",
  borderRadius: "20px",
  border: "2px solid var(--first-color)",
  zIndex: 20,
};
