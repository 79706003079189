import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import io from "socket.io-client";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useParams } from "react-router-dom";
import Peer from "simple-peer";
import Draggable from "react-draggable";
import { Resizable } from "re-resizable";
import "react-quill/dist/quill.snow.css";
import CancelSharpIcon from "@mui/icons-material/CancelSharp";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import VideocamOffOutlinedIcon from "@mui/icons-material/VideocamOffOutlined";
import MicNoneOutlinedIcon from "@mui/icons-material/MicNoneOutlined";
import MicOffOutlinedIcon from "@mui/icons-material/MicOffOutlined";
import CallEndIcon from "@mui/icons-material/CallEnd";
import Tooltip from "@material-ui/core/Tooltip";
import Modal from "react-modal";
import DeleteIcon from "@mui/icons-material/Delete";

// To do later
// import sdk from '@stackblitz/sdk';
import { Box, Typography } from "@mui/material";

// Icons
import AddIcon from "@mui/icons-material/Add";

// CSS
import "../../css/interviewCoding.css";

// Static
import {
  baseUrl,
  Status,
  sectionStatus,
  // StackblitzDefaults,
  RouterNames,
  AssessmentType,
  secondsDelay,
  compilerTypes,
  datamodelsUrl,
  InterviewLanguageProps,
  ListOfLanguages,
  colors,
  endInterviewText,
} from "../../static/constants";
import { InterviewLanguages } from "../../static/constants";
import {
  createQuestionAndResponse,
  deleteQuestionAndResponse,
  fetchResponsesArray,
  fetchSnippetCode,
  setActiveQuestion,
  updateEditorCode,
  updateQuestion,
  updateStatusToSubmitted,
} from "../../api/requests/interviewQuestions";
import { runCode } from "../../api/requests/runCode";
import {
  ThemeConfigurationType,
  CodeResponse,
  InterviewQuestion,
} from "../Types/Index";
import PopupMessage from "../popups/popupMessage";
import {
  StyledHeader,
  Header,
  ZecodeLogo,
  Questions,
  EndInterviewLogoWrapper,
  RequestVideoButton,
  EndInterviewButton,
  ZesstaLogo,
  StyledContainer,
  StyledLeftContainer,
  StyledQuestionContainer,
  StyledAddQuestionContainer,
  StyledButtonsContainer,
  StyledJupyterContainer,
  DropDownContainer,
  DropDownHead,
  DropdownBox,
  ListOptions,
  IDEList,
  StyledMainContainer,
  EditorWrap,
  CodingContainer,
  CodingButtonsContainer,
  Label,
  StyledButtonContainer,
  RunButton,
  Run,
  ResetButton,
  ClearLog,
  PreWrapper,
  VideoContainer,
  AudioVideoButtonContainer,
  AcceptCallPopup,
  AcceptRejectButtonContainer,
  AcceptButton,
  RejectButton,
  dropdownIcon,
  RevokeAccessButton,
  DialogActionsWrapper,
  DialogText,
  YesButtonWrapper,
  DialogWrapper,
  DialogContentWrapper,
  QuestionNumberButton,
  CodingAndOutputContainer,
  EditorContainer,
  OutputConsole,
  ArrowBox,
  VerticalText,
  ConsoleHeader,
  ConsoleText,
  HeaderText,
  DenyButton,
  AllowButton,
  DeleteBox,
} from "./interviewStyles";
import {
  getInterviewCandidate,
  updateInterviewStatus,
  saveSocketId,
  candidateCallAccepted,
  screenReloaded,
  updateCandidateLastActiveStatus,
  requestAccess,
  requestAllowed,
  fetchCandidateLastActiveStatus,
  fetchInterviewerLastActiveStatus,
  screenSharingEnded,
  updateInterviewerLastActiveStatus,
  endCall,
} from "../../api/requests/interview";
import moment from "moment";
import { setProfileDetailSettings } from "../../util/util";
import { getEnterpriseTheme } from "../../api/requests/themeConfiguration";
import { getEnterpriseDetails } from "../../api/requests/enterprise";
import {
  fullScreenStyle,
  nonFullScreenStyle,
  useStylesCommon,
} from "../common/styles";
import styled from "styled-components";
import SnackbarComponent from "../common/snackbar";
import ReplayIcon from "@mui/icons-material/Replay";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import InterviewMonacoComponent from "./InterviewMonacoComponent";
import SandpackComponent from "./sandpackComponent";
import InterviewQuestionEditor from "./interviewQuestionEditorComponent";
import { StyledLink } from "./candidateCoding";
import { commonStrings } from "../../static/commonStrings";

// Image
const Loading = "/images/Load Icon - F.gif";
const RunCodeIcon = "/images/runCodeIcon.svg";
const zecodeLogo = "/images/zecodeSideBarLogo.svg";
const blackDropdownArrowIcon = "/images/dropdown_black.svg";

const socket = io(baseUrl!);

export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ImageWrapper = styled.img`
  width: 12px;
  height: 12px;
`;

const ThemeIcon = styled.img<{ width: string; height: string }>`
  width: ${(props) => (props.width ? props.width : "15px")} !important;
  height: ${(props) => (props.height ? props.height : "15px")} !important;
`;

const InterviewerTyping = styled.div`
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
`;

const InterviewCoding = () => {
  const { id } = useParams();
  const commonClasses = useStylesCommon();
  const navigate = useNavigate();
  const socketUpdateResponse = useRef(false);
  const socketUpdateQuestion = useRef(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const isInterviewerData = searchParams.get("i");
  const interviewQuestionId = searchParams.get("interviewQuestionId");
  const [selectedIDE, setSelectedIDE] = useState<number>(16);
  const [submittedData, setSubmittedData] = useState("");
  const [selectedIDEValue, setSelectedIDEValue] = useState<number>();
  const [languageDisplay, setLanguageDisplay] = useState(false);
  const [popUpSection, setPopUpSection] = useState<string>("");
  const [idToCall, setIdToCall] = useState<string>("");
  const [idToShare, setIdToShare] = useState<string>("");
  const [output, setOutput] = useState<any>();
  const [theme, setTheme] = useState<boolean>(false);
  const [profileDetails, setProfileDetails] =
    useState<ThemeConfigurationType>();
  const [enterpriseDetails, setEnterpriseDetails] = useState<any>();
  const [codeFiles, setCodeFiles] = useState<any>();
  const [activeFile, setActiveFile] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [typingAccessRequested, setTypingAccessRequested] =
    useState<boolean>(false);
  const [typingAccessAllowed, setTypingAccessAllowed] = useState<boolean>(
    isInterviewerData ? false : true
  );
  const [snackbar, setSnackbar] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [closeSnackbar, setCloseSnackbar] = useState<any>("");
  const [snackbarType, setSnackbarType] = useState<any>("");
  const [codeDetails, setCodeDetails] = useState<CodeResponse[]>([
    {
      files: codeFiles,
    },
  ]);

  const [questionDetails, setQuestionDetails] = useState<InterviewQuestion[]>(
    []
  );
  const [activeQuestionNum, setActiveQuestionNum] = useState(0);
  const [status, setStatus] = useState<string>("true");
  const [enterpriseId, setEnterpriseId] = useState();
  const [candidate, setCandidate] = useState("");
  const [interviewer, setInterviewer] = useState("");
  const [popUpTrigger, setPopUpTrigger] = useState<boolean>(false);
  const [candidateSocketId, setCandidateSocketId] = useState<string>("");
  const [interviewerSocketId, setInterviewerSocketId] = useState<string>("");
  const [codeSnippet, setCodeSnippet] = useState("");
  const [me, setMe] = useState("");
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [shareStream, setShareStream] = useState<MediaStream | null>(null);
  const [receivingCall, setReceivingCall] = useState<boolean>(false);
  const [sharingActive, setSharingActive] = useState<boolean>(false);
  const [shareAccepted, setShareAccepted] = useState<boolean>(false);
  const [caller, setCaller] = useState("");
  const [screencaller, setScreenCaller] = useState("");
  const [callerSignal, setCallerSignal] = useState<any>();
  const [screenSignal, setScreenSignal] = useState<any>();
  const [callAccepted, setCallAccepted] = useState(false);
  const [callAcceptedBE, setCallAcceptedBE] = useState(false);
  const [callRejected, setCallRejected] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [videoCam, setVideoCam] = useState<boolean>(true);
  const [audio, setAudio] = useState<boolean>(true);
  const [interviewCallPopup, setInterviewCallPopup] = useState<boolean>(false);
  const [screenSharePopup, setScreenSharePopup] = useState<boolean>(false);
  const [pageReloaded, setPageReloaded] = useState<boolean>(false);
  const [hideVideo, setHideVideo] = useState<boolean>(false);
  const [isCandidateActive, setIsCandidateActive] = useState<boolean>(true);
  const [showButton, setShowButton] = useState<boolean>(false);
  const [myVideoDOM, setVideoDOM] = useState<any>({ current: null });
  const [loader, setLoader] = useState(false);
  const [isOutputConsoleOpen, setIsOutputConsoleOpen] =
    useState<boolean>(false);
  const [showERDiagram, setShowERDiagram] = useState<boolean>(false);
  const [dragDisabled, setDragDisabled] = useState<boolean>(false);
  const [isResizing, setIsResizing] = useState<boolean>(false);
  const [shareFullScreen, setShareFullScreen] = useState<boolean>(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [videoPosition, setVideoPosition] = useState({ x: 0, y: 0 });

  const myVideo = useCallback((node: any) => {
    setVideoDOM({ current: node });
  }, []);

  const popupVideoRef = useRef<HTMLVideoElement>(null);
  const popupShareRef = useRef<HTMLVideoElement>(null);
  const userVideo = useRef<any>(null);
  const shareVideo = useRef<any>(null);
  const connectionRef = useRef<any>(null);
  const shareConnectionRef = useRef<any>(null);

  //Jupyter Notebook URL
  const jupyterURL = process.env.REACT_APP_JUPYTER_NOTEBOOK_URL;

  //To create new question or update Question

  const updateEditorCodeDetail = async () => {
    try {
      const currentCodeDetail = codeDetails[activeQuestionNum];

      if (currentCodeDetail) {
        if (status !== sectionStatus.submitted) {
          socketUpdateResponse.current &&
            (await updateEditorCode(
              currentCodeDetail._id,
              currentCodeDetail.code,
              currentCodeDetail.ide,
              currentCodeDetail.template,
              currentCodeDetail.files,
              !!isInterviewerData,
              activeFile
            ));
          socketUpdateResponse.current = true;
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateQuestionDetail = async () => {
    try {
      const currentQuestionDetail = questionDetails[activeQuestionNum];
      if (currentQuestionDetail) {
        if (status !== sectionStatus.submitted) {
          socketUpdateQuestion.current &&
            (await updateQuestion(
              currentQuestionDetail._id,
              currentQuestionDetail.questionDescription
            ));
          socketUpdateQuestion.current = true;
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const addSocket = async (interviewId: string) => {
    try {
      await saveSocketId(
        interviewId,
        candidateSocketId ? candidateSocketId : null,
        interviewerSocketId ? interviewerSocketId : null
      );
    } catch (error) {
      console.error(error);
    }
  };

  // To fetch QuestionsArray from backend

  const fetchResponseArray = async () => {
    const response = await fetchResponsesArray(id);
    if (
      response?.codeResponses?.length > 0 &&
      response?.questions?.length > 0
    ) {
      socketUpdateResponse.current = false;
      socketUpdateQuestion.current = false;
      setCodeDetails(response?.codeResponses);
      setSelectedIDE(response?.codeResponses[response?.activeQuestion]?.ide);
      setQuestionDetails(response?.questions);
      setActiveQuestionNum(
        response?.activeQuestion ? response?.activeQuestion : 0
      );
    }
  };

  const RejectCall = async () => {
    if (id) {
      await candidateCallAccepted(id, false);
    }
  };

  const fetchInterviewDetails = async () => {
    if (id) {
      const response = await getInterviewCandidate({
        id,
        isInterviewerStartTime: true,
        isInterviewerData,
      });
      const interviewCandidateResponse = response?.data?.data;
      if (interviewCandidateResponse) {
        // Show page level disclaimer, when unassigned interviewer try's to open the link
        if (
          isInterviewerData &&
          interviewCandidateResponse.platformUserId !== isInterviewerData
        ) {
          navigate("/");
        }
        setEnterpriseId(interviewCandidateResponse.enterpriseId);
        const enterpriseID = interviewCandidateResponse.enterpriseId;
        const themeResponse =
          enterpriseID && (await getEnterpriseTheme(enterpriseID));
        themeResponse &&
          themeResponse?.data &&
          themeResponse?.data?.data &&
          setProfileDetailSettings(themeResponse?.data?.data);
        setProfileDetails(themeResponse?.data?.data);
        setStatus(interviewCandidateResponse.status);
        setCandidate(interviewCandidateResponse.name);
        setInterviewer(interviewCandidateResponse.interviewerName);
        setIdToCall(interviewCandidateResponse.candidateSocketId);
        setIdToShare(interviewCandidateResponse.interviewerSocketId);
        if (interviewCandidateResponse.status === sectionStatus.inProgress) {
          if (isInterviewerData) {
            navigate(`${RouterNames.interview}/${id}?i=${isInterviewerData}`);
          } else {
            navigate(`${RouterNames.interview}/${id}`);
          }
        } else if (
          interviewCandidateResponse.status === sectionStatus.submitted
        ) {
          if (isInterviewerData) {
            navigate(`${RouterNames.interview}/${id}?i=${isInterviewerData}`);
          } else {
            navigate(`${RouterNames.interviewCompleted}/${id}`);
          }
        }
      }
    }
  };

  //When click on add button new question will create
  const onAddQuestion = async () => {
    const response = await createQuestionAndResponse(id, enterpriseId);
    const questionResponse = response?.data?.data;
    if (response) {
      setQuestionDetails(questionResponse.questionsResponse);
      setCodeDetails(questionResponse?.editorResponse);
      setActiveQuestionNum(questionResponse?.editorResponse?.length - 1);
      setSelectedIDE(
        questionResponse?.editorResponse[
          questionResponse?.editorResponse?.length - 1
        ]?.ide
      );
    }
  };

  const onDeleteQuestionHandler = async () => {
    setPopUpSection(Status.deleteQuestion);
    setPopUpTrigger(true);
    setOutput("");
  };

  const onDeleteQuestion = async () => {
    setPopUpTrigger(false);
    if (questionDetails.length === 1) {
      questionDetails[activeQuestionNum].questionDescription = "";
      setSelectedIDE(16);
      setCodeDetails(
        codeDetails?.map((item, index) =>
          activeQuestionNum === index
            ? {
                ...item,
                code: 'print("Hello world")',
                ide: 16,
                template: "python",
              }
            : { ...item }
        )
      );
      setQuestionDetails(
        questionDetails?.map((item, index) =>
          activeQuestionNum === index
            ? { ...item, questionDescription: "" }
            : { ...item }
        )
      );
    } else {
      const response = await deleteQuestionAndResponse(
        id,
        questionDetails[activeQuestionNum]?._id,
        activeQuestionNum === questionDetails?.length - 1
          ? 0
          : activeQuestionNum,
        activeQuestionNum === questionDetails?.length - 1
          ? questionDetails[0]?._id
          : questionDetails[activeQuestionNum + 1]?._id,
        activeQuestionNum === questionDetails?.length - 1
          ? codeDetails[0]?._id
          : codeDetails[activeQuestionNum + 1]?._id,
        activeQuestionNum === questionDetails?.length - 1
          ? codeDetails[0]?.ide
          : codeDetails[activeQuestionNum + 1]?.ide
      );
      if (response) {
        setQuestionDetails(response?.data?.questionsResponse);
        setCodeDetails(response?.data?.editorResponse);
        setActiveQuestionNum(response?.data?.index);
      }
    }
  };

  const onSelectQuestion = async (index: number) => {
    if (activeQuestionNum !== index) {
      await setActiveQuestion(
        id,
        questionDetails[index]._id,
        codeDetails[index]._id,
        !!isInterviewerData,
        index,
        codeDetails[index].ide
      );
      setActiveQuestionNum(index);
      setSelectedIDE(codeDetails[index].ide ?? 16);
      setOutput("");
    }
  };

  useEffect(() => {
    const interviewQuestionIdIndex = codeDetails.findIndex(
      (code) => code.interviewQuestionId === interviewQuestionId
    );
    interviewQuestionIdIndex && onSelectQuestion(interviewQuestionIdIndex);
  }, [interviewQuestionId, codeDetails, codeDetails.length]);

  /**
   * Fetches project setup data from backend & stores it in answers state.
   */
  const fetchProjectSetupData = async (id: number) => {
    try {
      const activeQuestion = questionDetails[activeQuestionNum];
      setCodeDetails([]);
      if (activeQuestion && activeQuestion._id) {
        const response = await fetchSnippetCode(id, true, activeQuestion._id);
        setCodeSnippet(response?.data?.setup?.code);
        const data = response?.data?.setup;
        setCodeFiles(data?.files);
        setCodeDetails(
          codeDetails?.map((item, index) =>
            activeQuestionNum === index
              ? {
                  ...item,
                  code: response?.data?.setup.code,
                  ide: id,
                  template: data?.template,
                  files: data?.files,
                }
              : { ...item }
          )
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  // // Handlers
  const languagedisplayHandler = () => {
    !isInterviewerData &&
      typingAccessAllowed &&
      setLanguageDisplay((languageDisplay) => !languageDisplay);
  };

  const closeDropdownHandler = () => {
    if (languageDisplay) {
      setLanguageDisplay(false);
    }
  };

  const codeExecute = async () => {
    setLoading(true);
    setIsOutputConsoleOpen(true);
    const sourcecode = codeDetails[activeQuestionNum]?.code;
    const template =
      selectedIDE === 16 ? "python3" : codeDetails[activeQuestionNum]?.template;
    const response = await runCode(
      template,
      sourcecode,
      id,
      AssessmentType.Interview
    );
    if (response?.exitCode || response.code) {
      if (response.code) {
        const sqlErrorMessage = `ERROR: ${response.errno} (${response.sqlState}) ${response.sqlMessage}`;
        setOutput(sqlErrorMessage);
      } else {
        setOutput(response?.stderr);
      }
    } else if (response?.status === "error") {
      setOutput(response.message);
    } else if (response?.exitCode === 0) {
      setOutput(response.stdout);
    } else if (response?.length === 0) {
      setOutput("No Records Found");
    } else if (response?.info === "") {
      setOutput("");
    } else {
      setOutput(response);
    }
    setLoading(false);
  };

  //when clicked on Reset button,a popUp will be displayed for the confirmation to reset the code.
  const onResetButtonClickHandler = () => {
    setPopUpSection(Status.interviewResetCode);
    setPopUpTrigger(true);
    setOutput("");
  };

  //If the Yes button is clicked in the PopUp displayed for the confirmation to reset the code,
  //the code will be reset.
  const resetCodeHandler = async () => {
    const activeQuestion = questionDetails[activeQuestionNum];
    if (activeQuestion && activeQuestion._id) {
      const response = await fetchSnippetCode(
        selectedIDE,
        true,
        activeQuestion._id
      );
      setCodeDetails(
        codeDetails?.map((item, index) =>
          activeQuestionNum === index
            ? { ...item, code: response?.data?.setup.code }
            : { ...item }
        )
      );
      setPopUpTrigger(false);
    }
  };

  const onMonacoEditorChange = (code?: string) => {
    setCodeDetails(
      codeDetails?.map((item, index) =>
        activeQuestionNum === index ? { ...item, code } : { ...item }
      )
    );
  };

  const submitHandler = () => {
    setPopUpSection(Status.interviewCompleted);
    setPopUpTrigger(true);
  };

  const leaveButtonHandler = async () => {
    setPopUpSection(Status.interviewLeave);
    setPopUpTrigger(true);
  };

  const leaveInterviewHandler = async () => {
    navigate(`${RouterNames.interviewCompleted}/${id}?l=leave`);
    await updateStatusToSubmitted(id!, enterpriseId!, isInterviewerData);
  };

  const endInterviewHandler = async () => {
    setCallAccepted(false);
    setCallEnded(true);
    if (connectionRef.current) {
      connectionRef.current.destroy();
    }
    if (id) {
      const response = await updateInterviewStatus({
        id,
        status: sectionStatus.submitted,
        isInterviewerData,
        enterpriseId,
      });
      response?.data?.status === "success" &&
        navigate(
          `${RouterNames.interviewCompleted}/${id}?i=${isInterviewerData}`
        );
    }
  };
  //If the Yes button is clicked in the PopUp displayed for the confirmation to change programming language,
  //the ide gets changed to the selected language.
  const ideChangeHandler = async () => {
    setPopUpTrigger(false);
    const ideIdValue = Number(selectedIDEValue);
    setSelectedIDE(ideIdValue);
    fetchProjectSetupData(ideIdValue);
    setOutput("");
  };

  // when the candidate tries to change the programming language ,a popUp will get displayed for the confirmation.
  const onIdeChangeHandler = (value: number) => {
    setSelectedIDEValue(value);
    setPopUpSection(Status.interviewChangeIDE);
    setPopUpTrigger(true);
  };

  const reactQuillChange = (value: string) => {
    setQuestionDetails(
      questionDetails.map((item, index) =>
        activeQuestionNum === index
          ? { ...item, questionDescription: value }
          : { ...item }
      )
    );
  };

  const listof7 = (data: InterviewLanguageProps[]): ListOfLanguages =>
    data.length > 7 ? [data.slice(0, 7), ...listof7(data.slice(7))] : [data];

  const filteredCompilers = (type: string) =>
    InterviewLanguages.filter(
      (item: InterviewLanguageProps) => item.type === type
    );

  const ideData = (key?: number) =>
    InterviewLanguages?.find((obj) => obj.id === key)?.data;

  //To do in future
  // const checkAndEmbedStackblitz = (questions: any) => {
  //   sdk.embedProject(StackblitzDefaults.ID, questions, {
  //     openFile: StackblitzDefaults.OPEN_FILE,
  //     height: StackblitzDefaults.HEIGHT,
  //     hideNavigation: true,
  //     forceEmbedLayout: true,
  //   });
  // };

  const fetchEnterpriseDetails = async (id: string) => {
    const response = await getEnterpriseDetails(id);
    response && response?.data && setEnterpriseDetails(response?.data);
  };

  const snackbarCloseHandler = () => {
    setCallRejected(false);
  };

  const CallsnackbarCloseHandler = () => {
    setPageReloaded(false);
  };

  const requestEditAccess = async (editAccess?: boolean) => {
    if (!editAccess) {
      setTypingAccessAllowed(false);
    }
    await requestAccess(id, !!isInterviewerData, editAccess);
  };

  const allowEditAccess = async (editAccess?: boolean) => {
    await requestAllowed(id, !!isInterviewerData, editAccess);
  };

  const getCandidateLastActiveTime = async (interviewId: string) => {
    const lastActiveTime = await fetchCandidateLastActiveStatus(interviewId);
    const time = new Date(lastActiveTime[0].lastActive);
    if (time) {
      const timestampNow = Date.now();
      const differenceInMilliSeconds = Math.abs(timestampNow - time.getTime());
      const candidateJoiningDelayNotificationSecond =
        enterpriseDetails?.notificationTime?.candidate * 60 * 1000; // calculate notification time
      if (
        differenceInMilliSeconds > candidateJoiningDelayNotificationSecond &&
        status === sectionStatus.inProgress &&
        localStorage.getItem(`candidateJoined${id}`) !== "1"
      ) {
        setShowButton(false);
        setSnackbar(true);
        setMessage(commonStrings.interview.interviewMessages.candidateLeft);
        setCloseSnackbar(null);
        setSnackbarType("info");
        localStorage.setItem(`candidateJoined${id}`, "1");
      }
      if (differenceInMilliSeconds < 5000) {
        setIsCandidateActive(true);
        setShowButton(true);
        localStorage.removeItem(`candidateJoined${id}`);
      }
    }
  };

  const getInterviewerLastActiveTime = async (interviewId: string) => {
    const lastActive = await fetchInterviewerLastActiveStatus(interviewId);
    const time = new Date(lastActive.lastActiveTime);
    if (time) {
      const timestampNow = Date.now();
      const differenceInMilliSeconds = Math.abs(timestampNow - time.getTime());
      const interviewerJoiningDelayNotificationSecond =
        enterpriseDetails?.notificationTime?.interviewer * 60 * 1000; // calculate notification time
      if (
        differenceInMilliSeconds > interviewerJoiningDelayNotificationSecond &&
        status === sectionStatus.inProgress &&
        localStorage.getItem(`interviewerJoined${id}`) !== "1"
      ) {
        setSnackbar(true);
        setMessage(commonStrings.interview.interviewMessages.interviewerLeft);
        setCloseSnackbar(null);
        setSnackbarType("info");
        localStorage.setItem(`interviewerJoined${id}`, "1");
      }
      if (differenceInMilliSeconds < 5000) {
        localStorage.removeItem(`interviewerJoined${id}`);
      }
    }
  };

  const keyUpHandler = async (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (
      event.ctrlKey &&
      event.keyCode === 90 &&
      codeDetails[activeQuestionNum]?.code === codeSnippet
    ) {
      event.preventDefault();
    }
  };

  const clickModal = () => {
    setShowERDiagram(true);
  };

  const closeModal = () => {
    setShowERDiagram(false);
  };

  useEffect(() => {
    fetchInterviewDetails();
    fetchResponseArray();
    setVideoCam(true);
    setAudio(true);
  }, []);

  useEffect(() => {
    id && addSocket(id);
  }, [candidateSocketId, id, interviewerSocketId]);

  useEffect(() => {
    if (id) {
      if (!isInterviewerData) {
        const getData = setInterval(async () => {
          await updateCandidateLastActiveStatus(id);
        }, 5000);
        return () => clearInterval(getData);
      } else {
        const getData = setInterval(async () => {
          await getCandidateLastActiveTime(id);
        }, 10000);

        return () => clearInterval(getData);
      }
    }
  }, [enterpriseDetails, id, isInterviewerData, status]);

  useEffect(() => {
    if (id) {
      if (isInterviewerData) {
        const getData = setInterval(async () => {
          await updateInterviewerLastActiveStatus(id);
        }, 5000);
        return () => clearInterval(getData);
      } else {
        const getData = setInterval(async () => {
          await getInterviewerLastActiveTime(id);
        }, 10000);

        return () => clearInterval(getData);
      }
    }
  }, [enterpriseDetails, id, isInterviewerData, status]);

  useEffect(() => {
    enterpriseId && fetchEnterpriseDetails(enterpriseId);
  }, [enterpriseId]);

  useEffect(() => {
    socket.on("connection", () => {
      /**/
    });
    socket.on(`setActiveQuestion/${id}`, (data: any) => {
      if (!!isInterviewerData !== data?.isInterviewer) {
        socketUpdateResponse.current = false;
        if (data?.questionsResponse) {
          setQuestionDetails(data?.questionsResponse);
        }
        if (data?.editorResponse) {
          setCodeDetails(data?.editorResponse);
        }
        if (data?.deleted) {
          setSnackbar(true);
          setMessage(commonStrings.interview.interviewMessages.questionDeleted);
          setCloseSnackbar(null);
          setSnackbarType("success");
        }
        setActiveQuestionNum(data?.index);
        setSelectedIDE(data?.ide);
      }
      setOutput("");
    });
    socket.on(`FromIDE3/${id}`, (data: any) => {
      if (data?.response === "Submitted") {
        setSubmittedData(data?.response);
        setActiveFile(data?.activeFile);
        setSnackbar(true);
        setMessage(commonStrings.interview.interviewMessages.candidateLeft);
        setCloseSnackbar(null);
        setSnackbarType("info");
      }
    });
    socket.on(`candidateLeftTheInterview/${id}`, (data) => {
      setSnackbar(true);
      setMessage(data?.response);
      setCloseSnackbar(null);
      setSnackbarType("info");
    });
    socket.on(`interviewerLeftTheInterview/${id}`, (data) => {
      setSnackbar(true);
      setMessage(data?.response);
      setCloseSnackbar(null);
      setSnackbarType("info");
    });
  }, []);

  useEffect(() => {
    socket.on(
      `CandidateJoined/${id}`,
      (data: { idToCall: string; message: string }) => {
        if (isInterviewerData && data?.message === "Candidate Joined") {
          setIdToCall(data?.idToCall);
        }
      }
    );
    socket.on(`InterviewerToShare/${id}`, (data: { idToShare: string }) => {
      if (!isInterviewerData) {
        setIdToShare(data?.idToShare);
      }
    });
  }, []);

  useEffect(() => {
    socket.on(`updateEditorCode/${id}`, (data: any) => {
      if (!!isInterviewerData !== data?.isInterviewer) {
        socketUpdateResponse.current = false;
        setActiveFile(data?.activeFile);
        setSelectedIDE(data?.ide);
        setCodeDetails(data?.result);
      }
      setOutput("");
    });
  }, []);

  useEffect(() => {
    socket.on(`updateQuestion/${id}`, (data: any) => {
      if (!isInterviewerData) {
        socketUpdateQuestion.current = false;
        setQuestionDetails(data?.result);
      }
    });
  }, []);

  useEffect(() => {
    if (callAcceptedBE && myVideoDOM.current) {
      myVideoDOM.current.srcObject = stream;
    }
  }, [callAcceptedBE, stream, myVideoDOM]);

  useEffect(() => {
    function onDisconnect() {
      setCallAcceptedBE(true);
    }
    socket.on(`rejected/${id}`, (data: { isCallAccepted: boolean }) => {
      if (data.isCallAccepted) {
        onDisconnect();
      }
      if (isInterviewerData) {
        if (!data.isCallAccepted) {
          setCallRejected(true);
          setSnackbar(true);
          setMessage(commonStrings.interview.interviewMessages.callRejected);
          setCloseSnackbar(snackbarCloseHandler);
          setSnackbarType("info");
        }
      }
    });
  }, [id, isInterviewerData]);

  useEffect(() => {
    socket.on(
      `requestAllowed/${id}`,
      (data: { isInterviewerData: boolean; requestAllowed: boolean }) => {
        if (isInterviewerData) {
          if (!data.isInterviewerData) {
            if (data.requestAllowed) {
              setTypingAccessAllowed(true);
              setSnackbar(true);
              setMessage(
                commonStrings.interview.interviewMessages.accessGranted
              );
              setCloseSnackbar(null);
              setSnackbarType("success");
            } else {
              setTypingAccessAllowed(false);
              setSnackbar(true);
              setMessage(
                commonStrings.interview.interviewMessages.accessDenied
              );
              setCloseSnackbar(null);
              setSnackbarType("info");
            }
          }
        }
      }
    );
    socket.on(
      `requestAccess/${id}`,
      (data: { isInterviewer: boolean; accessRequest: boolean }) => {
        if (!!isInterviewerData !== data?.isInterviewer) {
          setTypingAccessRequested(data?.accessRequest);
          if (!data.accessRequest) {
            setTypingAccessAllowed(true);
          }
        }
      }
    );
    socket.on(`ScreenReloaded/${id}`, () => {
      setPageReloaded(true);
      setHideVideo(true);
      if (!isInterviewerData) {
        setSharingActive(false);
        setSnackbar(true);
        setMessage(commonStrings.interview.interviewMessages.callDisconnected);
        setCloseSnackbar(CallsnackbarCloseHandler);
        setSnackbarType("info");
        handleEnded();
      }
    });
    socket.on(`endVideoCall/${id}`, () => {
      if (!isInterviewerData) {
        endVideoCall();
      }
    });
  }, [id, isInterviewerData]);

  useEffect(() => {
    if (selectedIDE <= 4 || selectedIDE === 17) {
      if (typingAccessAllowed) {
        const getData = setTimeout(async () => {
          await updateEditorCodeDetail();
        });

        return () => clearTimeout(getData);
      }
    } else {
      const getData = setTimeout(async () => {
        await updateEditorCodeDetail();
      });

      return () => clearTimeout(getData);
    }
  }, [codeDetails, codeFiles, activeFile, selectedIDE, typingAccessAllowed]);

  useEffect(() => {
    updateQuestionDetail();
  }, [questionDetails]);

  const callUser = (id: string) => {
    const peer = new Peer({
      initiator: true,
      trickle: false,
      stream: stream as MediaStream,
    });
    peer.on("signal", (data: object) => {
      socket.emit("callUser", {
        userToCall: id,
        signalData: data,
        from: me,
      });
    });
    peer.on("stream", (stream: MediaStream) => {
      if (userVideo.current) userVideo.current.srcObject = stream;
    });
    socket.on("callAccepted", (signal) => {
      setCallAccepted(true);
      peer.signal(signal);
    });

    connectionRef.current = peer;
  };

  const answerCall = async () => {
    setHideVideo(false);
    setPageReloaded(false);
    setReceivingCall(false);
    setCallAccepted(true);
    setStream(stream);

    const peer = new Peer({
      initiator: false,
      trickle: false,
      stream: stream as MediaStream,
    });
    peer.on("signal", (data: object) => {
      socket.emit("answerCall", { signal: data, to: caller });
    });
    peer.on("stream", (peerStream: MediaStream) => {
      if (userVideo.current) userVideo.current.srcObject = peerStream;
    });

    peer.signal(callerSignal);
    id && (await candidateCallAccepted(id, true));
  };

  const endVideoCallHandler = () => {
    setPopUpSection(Status.endCall);
    setPopUpTrigger(true);
  };

  const endVideoCall = async () => {
    setPopUpTrigger(false);
    if (connectionRef.current) {
      connectionRef.current.destroy();
    }
    if (shareConnectionRef.current) {
      shareConnectionRef.current.destroy();
    }
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
    }
    if (shareStream) {
      shareStream.getTracks().forEach((track) => track.stop());
    }
    setCallAccepted(false);
    setCallEnded(true);
    setShareAccepted(false)
    setSharingActive(false);
    if (userVideo.current) {
      userVideo.current = null;
    }
    if (shareVideo.current) {
      shareVideo.current = null;
    }
    socket.off("callAccepted");
    socket.off("shareAccepted");
    if (id && isInterviewerData) {
      await endCall(id);
      window.location.reload();
    } else {
      setSnackbar(true);
      setMessage(commonStrings.interview.interviewMessages.callEnded);
      setCloseSnackbar(null);
      setSnackbarType("info");
      setTimeout(() => {
        window.location.reload();
      }, 4000);
    }
  };

  const shareScreen = (id: string) => {
    const peer = new Peer({
      initiator: true,
      trickle: false,
      stream: shareStream as MediaStream,
    });

    peer.on("signal", (data: object) => {
      socket.emit("screenShare", {
        userToCall: id,
        signalData: data,
        from: me,
      });
    });
    peer.on("stream", (stream: MediaStream) => {
      if (shareVideo.current) shareVideo.current.srcObject = stream;
    });
    socket.on("shareAccepted", (signal) => {
      peer.signal(signal);
    });

    shareConnectionRef.current = peer;
  };

  const answerShare = async () => {
    setScreenSharePopup(false);
    setSharingActive(true);
    const peer = new Peer({
      initiator: false,
      trickle: false,
    });
    peer.on("signal", (data: object) => {
      socket.emit("answerShare", { signal: data, to: screencaller });
    });
    peer.on("stream", (stream: MediaStream) => {
      if (shareVideo.current) shareVideo.current.srcObject = stream;
    });
    peer.signal(screenSignal);
    setShareAccepted(true);
  };

  const toggleCameraHandler = () => {
    if (!stream) {
      return;
    }
    const videoStream = stream
      .getTracks()
      .find((track) => track.kind === "video");

    if (videoStream) {
      videoStream.enabled = !videoStream.enabled;
      setVideoCam(videoStream.enabled);
    }

    if (myVideoDOM.current) {
      myVideoDOM.current.srcObject = stream;
    }
  };

  const toggleAudioHandler = () => {
    if (!stream) {
      return;
    }
    const audioStream = stream
      .getTracks()
      .find((track) => track.kind === "audio");

    if (audioStream) {
      audioStream.enabled = !audioStream.enabled;
      setAudio(audioStream.enabled);
    }

    if (myVideoDOM.current) {
      myVideoDOM.current.srcObject = stream;
    }
  };

  const handleDrag = (e: React.MouseEvent<HTMLDivElement>) => {
    const resizableRect = (
      e.currentTarget as HTMLDivElement
    ).getBoundingClientRect();
    const cursorX = e.clientX;
    const cursorY = e.clientY;
    const buffer = 10;
    const nearLeft = cursorX - resizableRect.left < buffer;
    const nearRight = resizableRect.right - cursorX < buffer;
    const nearTop = cursorY - resizableRect.top < buffer;
    const nearBottom = resizableRect.bottom - cursorY < buffer;
    if (nearLeft || nearRight || nearTop || nearBottom) {
      setDragDisabled(true);
    } else {
      setDragDisabled(false);
    }
  };

  const handleEnded = async () => {
    setSharingActive(false);
    id && (await screenSharingEnded(id, true));
  };

  const enableResize = shareFullScreen
    ? { right: true, left: false }
    : { right: true, left: true };

  useEffect(() => {
    if (shareStream) {
      const [track] = shareStream.getVideoTracks();
      track.addEventListener("ended", handleEnded);
      return () => {
        track.removeEventListener("ended", handleEnded);
      };
    }
  }, [id, shareStream]);

  useEffect(() => {
    if (interviewCallPopup || receivingCall) {
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: true })
        .then((stream) => {
          setStream(stream);
          if (popupVideoRef && popupVideoRef.current) {
            popupVideoRef.current.srcObject = stream;
          }
        })
        .catch((error) => {
          alert("Please enable camera and microphone");
        });
    }
  }, [interviewCallPopup, receivingCall]);

  useEffect(() => {
    if (screenSharePopup && !isInterviewerData) {
      navigator.mediaDevices
        .getDisplayMedia({ video: true, audio: true })
        .then((stream) => {
          setShareStream(stream);
          if (popupShareRef && popupShareRef.current) {
            popupShareRef.current.srcObject = stream;
          }
        })
        .catch((error) => {
          setScreenSharePopup(false);
        });
    }
  }, [isInterviewerData, screenSharePopup]);

  useEffect(() => {
    socket.on("me", (id) => {
      !isInterviewerData
        ? setCandidateSocketId(id)
        : setInterviewerSocketId(id);
      setMe(id);
    });
    socket.on("callUser", (data) => {
      setVideoCam(true);
      setAudio(true);
      setReceivingCall(true);
      setCaller(data.from);
      setCallerSignal(data.signal);
    });

    socket.on("screenShare", (data) => {
      setScreenSharePopup(true);
      setScreenCaller(data.from);
      setScreenSignal(data.signal);
    });

    socket.on(`sharingStopped/${id}`, (data) => {
      if (isInterviewerData) {
        setShareAccepted(false);
        setSnackbar(true);
        setMessage(commonStrings.interview.interviewMessages.stoppedSharing);
        setCloseSnackbar(null);
        setSnackbarType("info");
        setShareFullScreen(false);
      }
    });
  }, [id, isInterviewerData]);

  const template: string | undefined = InterviewLanguages?.filter(
    (id) => id?.id === selectedIDE
  )[0]?.value;

  const intitalFileState = useMemo(() => {
    const result: { [key: string]: string } = {};
    if (codeDetails[activeQuestionNum]?.files) {
      Object.entries(codeDetails[activeQuestionNum].files)?.forEach(
        ([key, value]) => {
          result[key] = (value as { code: string })?.code;
        }
      );
    }

    return result;
  }, [codeDetails, activeQuestionNum]);

  const handleUpdation = (data: object) => {
    setCodeFiles(data);
    setActiveFile(activeFile);
    const existingQuestions = codeDetails;
    if (existingQuestions[activeQuestionNum]) {
      existingQuestions[activeQuestionNum].files = data;
      setCodeDetails(existingQuestions);
    }
  };

  useEffect(() => {
    const handleBeforeunload = async () => {
      if ((callAccepted || sharingActive) && id) {
        isInterviewerData
          ? await screenReloaded(id, true, true)
          : await screenReloaded(id, true, false);
      }
      await updateStatusToSubmitted(id!, enterpriseId!, isInterviewerData);
    };
    window.addEventListener("beforeunload", handleBeforeunload);
    return () => window.removeEventListener("beforeunload", handleBeforeunload);
  });

  return (
    <Box style={{ maxHeight: "100vh" }} onClick={closeDropdownHandler}>
      <StyledHeader>
        <Header>
          <ZecodeLogo className="Logo" src={zecodeLogo} alt="zecode" />
          {isInterviewerData && (
            <Typography sx={{ color: colors.silverGray }}>
              Candidate Name : {candidate}
            </Typography>
          )}
          {!isInterviewerData && (
            <Typography sx={{ color: colors.silverGray }}>
              Interviewer Name : {interviewer}
            </Typography>
          )}
        </Header>

        <EndInterviewLogoWrapper>
          {status !== sectionStatus.submitted &&
            isInterviewerData &&
            !callAccepted && (
              <Tooltip title={!showButton ? "Awaiting Participation" : ""}>
                <div>
                  <RequestVideoButton
                    disabled={!showButton}
                    onClick={() => {
                      setVideoCam(true);
                      setAudio(true);
                      setInterviewCallPopup(true);
                    }}
                  >
                    Initiate Call
                  </RequestVideoButton>
                </div>
              </Tooltip>
            )}
          {!isInterviewerData && !sharingActive && (
            <RequestVideoButton
              onClick={() => {
                setScreenSharePopup(true);
              }}
            >
              Share Screen
            </RequestVideoButton>
          )}
          {status !== sectionStatus.submitted &&
            enterpriseDetails?.subscriptions.includes(endInterviewText) &&
            !isInterviewerData && (
              <EndInterviewButton onClick={submitHandler}>
                End Interview
              </EndInterviewButton>
            )}
          {status !== sectionStatus.submitted && isInterviewerData && (
            <EndInterviewButton onClick={submitHandler}>
              End Interview
            </EndInterviewButton>
          )}
          {status !== sectionStatus.submitted &&
            !enterpriseDetails?.subscriptions.includes(endInterviewText) &&
            !isInterviewerData && (
              <EndInterviewButton onClick={leaveButtonHandler}>
                Leave Interview
              </EndInterviewButton>
            )}

          <ZesstaLogo
            className="Logo"
            src={
              profileDetails?.imageURL
                ? profileDetails?.imageURL
                : enterpriseDetails?.imageURL
                ? enterpriseDetails?.imageURL
                : zecodeLogo
            }
            alt="logo"
          />
        </EndInterviewLogoWrapper>
      </StyledHeader>

      <StyledContainer $isDark={theme}>
        <Resizable
          defaultSize={{
            width: "20%",
            height: "100%",
          }}
          maxWidth={"50%"}
          minWidth={"20%"}
        >
          <StyledLeftContainer>
            <StyledAddQuestionContainer>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Questions>Questions</Questions>
                {isInterviewerData &&
                  !(
                    questionDetails.length === 1 &&
                    (questionDetails[0].questionDescription === "" ||
                      questionDetails[0].questionDescription === "<p><br></p>")
                  ) && (
                    <DeleteBox onClick={onDeleteQuestionHandler}>
                      <DeleteIcon
                        style={{ color: "#FF4D6A", height: "22px" }}
                      />
                    </DeleteBox>
                  )}
              </div>
              <StyledButtonsContainer>
                {codeDetails &&
                  codeDetails.length &&
                  codeDetails?.map((item, index) => {
                    return (
                      <QuestionNumberButton
                        $Active={activeQuestionNum === index}
                        key={index.toString()}
                        onClick={() => onSelectQuestion(index)}
                      >
                        <span>{index + 1}</span>
                      </QuestionNumberButton>
                    );
                  })}
                {isInterviewerData && status !== sectionStatus.submitted && (
                  <QuestionNumberButton $AddIcon={true} onClick={onAddQuestion}>
                    <AddIcon style={{ height: "15px", width: "15px" }} />
                  </QuestionNumberButton>
                )}
              </StyledButtonsContainer>
            </StyledAddQuestionContainer>

            <StyledQuestionContainer
              $isDark={theme}
              $interviewer={!!isInterviewerData}
            >
              <InterviewQuestionEditor
                activeQuestion={questionDetails[activeQuestionNum]}
                isInterviewer={!!isInterviewerData}
                reactQuillChange={reactQuillChange}
              />
            </StyledQuestionContainer>
          </StyledLeftContainer>
        </Resizable>

        {selectedIDE === 18 ? (
          <StyledJupyterContainer>
            <DropDownContainer onClick={languagedisplayHandler}>
              <DropDownHead
                className="dropdown-head"
                isDisplay={languageDisplay}
              >
                <label>
                  {codeDetails[activeQuestionNum]?.ide
                    ? ideData(codeDetails[activeQuestionNum]?.ide)
                    : "Select IDE"}
                </label>
                <img
                  src={
                    window
                      .getComputedStyle(document.documentElement)
                      .getPropertyValue("--button-text-color") === "#000000"
                      ? blackDropdownArrowIcon
                      : dropdownIcon
                  }
                  alt="dropDownIcon"
                />
              </DropDownHead>
              {languageDisplay ? (
                <DropdownBox className="dropdown-box">
                  <p>General</p>
                  <ListOptions>
                    {listof7(InterviewLanguages)?.map((data, index) => (
                      <ul key={index}>
                        {data?.map((item, num) => (
                          <IDEList
                            $active={
                              codeDetails[activeQuestionNum]?.ide === item?.id
                            }
                            onClick={() => onIdeChangeHandler(item?.id)}
                            key={num}
                          >
                            {item?.image && (
                              <img
                                src={item?.image}
                                height={16}
                                width={16}
                                alt="Icon"
                              />
                            )}
                            <span>{item?.data}</span>
                          </IDEList>
                        ))}
                      </ul>
                    ))}
                  </ListOptions>
                </DropdownBox>
              ) : (
                ""
              )}
            </DropDownContainer>
            <iframe
              title="Interactive Jupyter Notebook"
              src={jupyterURL}
              height="100%"
            />
          </StyledJupyterContainer>
        ) : (
          <StyledMainContainer>
            <EditorWrap>
              <CodingContainer
                $isDark={theme}
                onKeyUp={(event) => {
                  keyUpHandler(event);
                }}
              >
                <CodingButtonsContainer $isDark={theme}>
                  <DropDownContainer onClick={languagedisplayHandler}>
                    <DropDownHead
                      isInterviewer={
                        !!isInterviewerData || !typingAccessAllowed
                      }
                      className="dropdown-head"
                      isDisplay={languageDisplay}
                    >
                      <Label
                        isInterviewer={
                          !!isInterviewerData || !typingAccessAllowed
                        }
                      >
                        {codeDetails[activeQuestionNum]?.ide
                          ? ideData(codeDetails[activeQuestionNum]?.ide)
                          : "Select IDE"}
                      </Label>
                      {
                        <img
                          src={
                            window
                              .getComputedStyle(document.documentElement)
                              .getPropertyValue("--button-text-color") ===
                            "#000000"
                              ? blackDropdownArrowIcon
                              : dropdownIcon
                          }
                          alt="dropDownIcon"
                        />
                      }
                    </DropDownHead>
                    {languageDisplay ? (
                      <DropdownBox className="dropdown-box">
                        <div>
                          <p style={{ color: "var(--first-color)" }}>
                            {compilerTypes.general}
                          </p>
                          <ListOptions>
                            {listof7(
                              filteredCompilers(compilerTypes.general)
                            )?.map((data, index) => (
                              <ul key={index}>
                                {data?.map((item, num) => (
                                  <IDEList
                                    $active={
                                      codeDetails[activeQuestionNum]?.ide ===
                                      item?.id
                                    }
                                    onClick={() => onIdeChangeHandler(item?.id)}
                                    key={num}
                                  >
                                    {item?.image && (
                                      <img
                                        src={item?.image}
                                        height={16}
                                        width={16}
                                        alt="Icon"
                                      />
                                    )}
                                    <span>{item?.data}</span>
                                  </IDEList>
                                ))}
                              </ul>
                            ))}
                          </ListOptions>
                        </div>
                        <div>
                          <p style={{ color: "var(--first-color)" }}>
                            {compilerTypes.frontend}
                          </p>
                          <ListOptions>
                            {listof7(
                              filteredCompilers(compilerTypes.frontend)
                            )?.map((data, index) => (
                              <ul key={index}>
                                {data?.map((item, num: number) => (
                                  <IDEList
                                    $active={
                                      codeDetails[activeQuestionNum]?.ide ===
                                      item?.id
                                    }
                                    onClick={() => onIdeChangeHandler(item?.id)}
                                    key={num}
                                  >
                                    {item?.image && (
                                      <img
                                        src={item?.image}
                                        height={16}
                                        width={16}
                                        alt="Icon"
                                      />
                                    )}
                                    <span>{item?.data}</span>
                                  </IDEList>
                                ))}
                              </ul>
                            ))}
                          </ListOptions>
                        </div>
                        <div>
                          <p style={{ color: "var(--first-color)" }}>
                            {compilerTypes.databases}
                          </p>
                          <ListOptions>
                            {listof7(
                              filteredCompilers(compilerTypes.databases)
                            )?.map((data, index) => (
                              <ul key={index}>
                                {data?.map((item, num) => (
                                  <IDEList
                                    $active={
                                      codeDetails[activeQuestionNum]?.ide ===
                                      item?.id
                                    }
                                    onClick={() => onIdeChangeHandler(item?.id)}
                                    key={num}
                                  >
                                    {item?.image && (
                                      <img
                                        src={item?.image}
                                        height={16}
                                        width={16}
                                        alt="Icon"
                                      />
                                    )}
                                    <span>{item?.data}</span>
                                  </IDEList>
                                ))}
                              </ul>
                            ))}
                          </ListOptions>
                        </div>
                      </DropdownBox>
                    ) : (
                      ""
                    )}
                  </DropDownContainer>
                  {selectedIDE === 13 && (
                    <>
                      <StyledLink href="#" onClick={clickModal}>
                        Link To ER Diagram
                      </StyledLink>
                      <Modal
                        isOpen={showERDiagram}
                        onRequestClose={closeModal}
                        contentLabel="ER Diagram"
                        className="datamodelscontainer"
                      >
                        <img
                          className="datamodels"
                          src={datamodelsUrl[0].url}
                          alt="datamodel"
                        />
                        <button
                          className={commonClasses.primaryButton}
                          onClick={closeModal}
                        >
                          Close
                        </button>
                      </Modal>
                    </>
                  )}
                  <StyledButtonContainer>
                    {isInterviewerData && showButton && (
                      <RevokeAccessButton
                        $isAccess={true}
                        onClick={() => {
                          typingAccessAllowed
                            ? requestEditAccess(false)
                            : requestEditAccess(true);
                        }}
                      >
                        {typingAccessAllowed
                          ? "Release Access"
                          : "Request Access"}
                      </RevokeAccessButton>
                    )}
                    {!isInterviewerData && !typingAccessAllowed && (
                      <RevokeAccessButton
                        onClick={() => {
                          allowEditAccess(false);
                          setTypingAccessAllowed(true);
                        }}
                      >
                        Revoke Access
                      </RevokeAccessButton>
                    )}
                    {selectedIDE && selectedIDE > 4 && selectedIDE !== 17 && (
                      <>
                        <RunButton onClick={codeExecute}>
                          {loading ? (
                            <ImageWrapper src={Loading} />
                          ) : (
                            <ThemeIcon
                              src={RunCodeIcon}
                              alt="runCode"
                              width="10px"
                              height="10px"
                            />
                          )}
                          <Run>Run code</Run>
                        </RunButton>
                        {/* To do later */}
                        {/* <TestCasesButton>Run Testcases</TestCasesButton> */}
                        {!isInterviewerData && (
                          <ResetButton onClick={onResetButtonClickHandler}>
                            <Box
                              style={{ fontSize: "12px", marginLeft: "7px" }}
                            >
                              Reset code
                            </Box>
                            <ReplayIcon style={{ width: "14px" }} />
                          </ResetButton>
                        )}
                      </>
                    )}
                  </StyledButtonContainer>
                </CodingButtonsContainer>
                <CodingAndOutputContainer>
                  {selectedIDE && (selectedIDE <= 4 || selectedIDE === 17) && (
                    <>
                      {!isInterviewerData && loader === true ? (
                        <InterviewerTyping>
                          Interviewer is Typing...
                        </InterviewerTyping>
                      ) : (
                        ""
                      )}
                      <div style={{ height: "100%", width: "76vw" }}>
                        <SandpackComponent
                          template={template}
                          activeFile={activeFile}
                          intitalFileState={intitalFileState}
                          codeFiles={codeFiles}
                          handleUpdation={handleUpdation}
                          setActiveFile={setActiveFile}
                          typingAccessAllowed={typingAccessAllowed}
                          status={status}
                        />
                      </div>
                    </>
                  )}
                  <EditorContainer $isOutputConsoleOpen={isOutputConsoleOpen}>
                    {selectedIDE && selectedIDE > 4 && selectedIDE !== 17 && (
                      <>
                        {!isInterviewerData && loader ? (
                          <InterviewerTyping>
                            Interviewer is Typing...
                          </InterviewerTyping>
                        ) : (
                          ""
                        )}
                        {!isInterviewerData ? (
                          <InterviewMonacoComponent
                            activeCodeDetails={codeDetails[activeQuestionNum]}
                            selectedIDE={selectedIDE}
                            theme={theme}
                            onMonacoEditorChange={onMonacoEditorChange}
                            typingAccessAllowed={typingAccessAllowed}
                            loader={loader}
                            status={status}
                          />
                        ) : (
                          <InterviewMonacoComponent
                            activeCodeDetails={codeDetails[activeQuestionNum]}
                            selectedIDE={selectedIDE}
                            theme={theme}
                            onMonacoEditorChange={onMonacoEditorChange}
                            typingAccessAllowed={typingAccessAllowed}
                            loader={loader}
                            status={status}
                          />
                        )}
                      </>
                    )}
                  </EditorContainer>

                  {!isOutputConsoleOpen ? (
                    <OutputConsole $isOutputConsoleOpen={isOutputConsoleOpen}>
                      <ArrowBox
                        $isOutputConsoleOpen={isOutputConsoleOpen}
                        onClick={() => {
                          setIsOutputConsoleOpen(!isOutputConsoleOpen);
                        }}
                      >
                        <KeyboardDoubleArrowLeftIcon
                          className={commonClasses.doubleArrow}
                        />
                      </ArrowBox>
                      <VerticalText>OUTPUT CONSOLE</VerticalText>
                    </OutputConsole>
                  ) : (
                    <OutputConsole $isOutputConsoleOpen={isOutputConsoleOpen}>
                      <ConsoleHeader>
                        <ArrowBox
                          $isOutputConsoleOpen={isOutputConsoleOpen}
                          onClick={() => {
                            setIsOutputConsoleOpen(!isOutputConsoleOpen);
                          }}
                        >
                          <KeyboardDoubleArrowRightIcon
                            className={commonClasses.doubleArrow}
                          />
                        </ArrowBox>
                        <ConsoleText>
                          Output Console
                          <ClearLog
                            onClick={() => setOutput("")}
                            $isDark={theme}
                          >
                            Clear Log
                          </ClearLog>
                        </ConsoleText>
                      </ConsoleHeader>
                      <PreWrapper $isDark={theme} $background={false}>
                        {codeDetails[activeQuestionNum]?.ide === 13 &&
                        output &&
                        output[0] !== "E" &&
                        output[0] !== "N" ? (
                          <table>
                            <thead>
                              <tr>
                                {output
                                  ? Object.keys(output[0])?.map(
                                      (obj, index) => <th key={index}>{obj}</th>
                                    )
                                  : ""}
                              </tr>
                            </thead>
                            <tbody>
                              {output?.length > 0 &&
                                output?.map((obj: any, rum: any) => (
                                  <tr key={rum}>
                                    {Object.entries(obj)?.map(
                                      (item: any, ind) => (
                                        <td key={ind}>
                                          {item[0].includes("DATE") ||
                                          item[0].includes("FROM")
                                            ? moment(item[1]).format(
                                                "DD-MM-YYYY"
                                              )
                                            : item[1]}
                                        </td>
                                      )
                                    )}
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        ) : (
                          output
                        )}
                      </PreWrapper>
                    </OutputConsole>
                  )}
                </CodingAndOutputContainer>
              </CodingContainer>
            </EditorWrap>
          </StyledMainContainer>
        )}
        {shareAccepted && (
          <Draggable
            disabled={isResizing || dragDisabled || shareFullScreen}
            position={shareFullScreen ? { x: 0, y: 0 } : videoPosition}
            onDrag={(e, data) => setVideoPosition({ x: data.x, y: data.y })}
            onStop={(e, data) => setVideoPosition({ x: data.x, y: data.y })}
          >
            <Box
              sx={
                shareFullScreen
                  ? fullScreenStyle
                  : nonFullScreenStyle(false, shareFullScreen)
              }
              onMouseMove={handleDrag}
            >
              <VideoContainer>
                <Resizable
                  defaultSize={{
                    width: "225px",
                    height: "100%",
                  }}
                  enable={enableResize}
                  maxWidth={"900px"}
                  maxHeight={"100vh"}
                  minWidth={shareFullScreen ? "75vw" : "225px"}
                  onResizeStart={() => setIsResizing(true)}
                  onResizeStop={() => setIsResizing(false)}
                >
                  <div>
                    <video
                      playsInline
                      ref={shareVideo}
                      autoPlay
                      style={{}}
                      className={`${commonClasses.userVideo} ${
                        !shareFullScreen ? commonClasses.draggingCursor : ""
                      }`}
                    />
                    <AudioVideoButtonContainer>
                      {" "}
                      {shareFullScreen ? (
                        <FullscreenExitIcon
                          onClick={() => {
                            setShareFullScreen(false);
                          }}
                          className={commonClasses.micAndVideoOn}
                        ></FullscreenExitIcon>
                      ) : (
                        <FullscreenIcon
                          onClick={() => {
                            setShareFullScreen(true);
                          }}
                          className={commonClasses.micAndVideoOn}
                        ></FullscreenIcon>
                      )}
                    </AudioVideoButtonContainer>
                  </div>
                </Resizable>
              </VideoContainer>
            </Box>
          </Draggable>
        )}
        {callAccepted && !hideVideo && (
          <Draggable
            disabled={isResizing || dragDisabled || shareFullScreen}
            position={shareFullScreen ? { x: 0, y: 0 } : position}
            onDrag={(e, data) => setPosition({ x: data.x, y: data.y })}
            onStop={(e, data) => setPosition({ x: data.x, y: data.y })}
          >
            <Box
              sx={
                shareFullScreen
                  ? nonFullScreenStyle(true, true)
                  : nonFullScreenStyle(true, false)
              }
              onMouseMove={handleDrag}
            >
              <VideoContainer>
                {callAccepted && !callEnded ? (
                  <Resizable
                    defaultSize={{
                      width: "225px",
                      height: "100%",
                    }}
                    enable={enableResize}
                    maxWidth={"600px"}
                    minWidth={shareFullScreen ? "24.5vw" : "225px"}
                    onResizeStart={() => setIsResizing(true)}
                    onResizeStop={() => setIsResizing(false)}
                  >
                    <video
                      playsInline
                      ref={userVideo}
                      autoPlay
                      className={`${commonClasses.userVideo} ${
                        !shareFullScreen ? commonClasses.draggingCursor : ""
                      }`}
                    />
                  </Resizable>
                ) : null}

                {stream && (
                  <video
                    playsInline
                    muted
                    ref={myVideo}
                    autoPlay
                    className={`${commonClasses.myVideo} ${commonClasses.draggingCursor}`}
                  />
                )}
              </VideoContainer>
              <AudioVideoButtonContainer>
                {videoCam ? (
                  <VideocamOutlinedIcon
                    className={commonClasses.micAndVideoOn}
                    onClick={toggleCameraHandler}
                  ></VideocamOutlinedIcon>
                ) : (
                  <VideocamOffOutlinedIcon
                    className={commonClasses.micAndVideoOff}
                    onClick={toggleCameraHandler}
                  ></VideocamOffOutlinedIcon>
                )}
                {audio ? (
                  <MicNoneOutlinedIcon
                    className={commonClasses.micAndVideoOn}
                    onClick={toggleAudioHandler}
                  ></MicNoneOutlinedIcon>
                ) : (
                  <MicOffOutlinedIcon
                    className={commonClasses.micAndVideoOff}
                    onClick={toggleAudioHandler}
                  ></MicOffOutlinedIcon>
                )}
                {isInterviewerData && (
                  <CallEndIcon
                    className={commonClasses.micAndVideoOff}
                    onClick={endVideoCallHandler}
                  ></CallEndIcon>
                )}
              </AudioVideoButtonContainer>
            </Box>
          </Draggable>
        )}
      </StyledContainer>
      <PopupMessage
        submittedData={submittedData}
        interviewId={id}
        popupTrigger={popUpTrigger}
        popupSection={popUpSection}
        setPopupTrigger={setPopUpTrigger}
        resetCodeHandler={resetCodeHandler}
        ideChangeHandler={ideChangeHandler}
        endInterviewHandler={endInterviewHandler}
        onDeleteQuestion={onDeleteQuestion}
        onEndCall={endVideoCall}
        leaveInterviewHandler={leaveInterviewHandler}
      />

      {!isInterviewerData ? (
        receivingCall ? (
          <AcceptCallPopup>
            {
              <video
                playsInline
                muted
                ref={popupVideoRef}
                autoPlay
                style={{ width: "180px", height: "150px" }}
              />
            }
            <AudioVideoButtonContainer>
              {videoCam ? (
                <VideocamOutlinedIcon
                  className={commonClasses.micAndVideoOnPopup}
                  onClick={toggleCameraHandler}
                ></VideocamOutlinedIcon>
              ) : (
                <VideocamOffOutlinedIcon
                  className={commonClasses.micAndVideoOff}
                  onClick={toggleCameraHandler}
                ></VideocamOffOutlinedIcon>
              )}
              {audio ? (
                <MicNoneOutlinedIcon
                  className={commonClasses.micAndVideoOnPopup}
                  onClick={toggleAudioHandler}
                ></MicNoneOutlinedIcon>
              ) : (
                <MicOffOutlinedIcon
                  className={commonClasses.micAndVideoOff}
                  onClick={toggleAudioHandler}
                ></MicOffOutlinedIcon>
              )}
            </AudioVideoButtonContainer>
            <AcceptRejectButtonContainer>
              <AcceptButton disabled={!stream} onClick={answerCall}>
                Accept
              </AcceptButton>
              <RejectButton
                disabled={!stream}
                onClick={() => {
                  RejectCall();
                  setCallAccepted(false);
                  setReceivingCall(false);
                  setVideoCam(true);
                  setAudio(true);
                  setPageReloaded(false);
                  setHideVideo(false);
                }}
              >
                Reject
              </RejectButton>
            </AcceptRejectButtonContainer>
          </AcceptCallPopup>
        ) : null
      ) : interviewCallPopup ? (
        <AcceptCallPopup>
          <CancelSharpIcon
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              setVideoCam(true);
              setAudio(true);
              setInterviewCallPopup(false);
            }}
          ></CancelSharpIcon>

          {
            <video
              playsInline
              muted
              ref={popupVideoRef}
              autoPlay
              style={{ width: "180px", height: "150px" }}
            />
          }
          <AudioVideoButtonContainer>
            {videoCam ? (
              <VideocamOutlinedIcon
                className={commonClasses.micAndVideoOnPopup}
                onClick={toggleCameraHandler}
              ></VideocamOutlinedIcon>
            ) : (
              <VideocamOffOutlinedIcon
                className={commonClasses.micAndVideoOff}
                onClick={toggleCameraHandler}
              ></VideocamOffOutlinedIcon>
            )}
            {audio ? (
              <MicNoneOutlinedIcon
                className={commonClasses.micAndVideoOnPopup}
                onClick={toggleAudioHandler}
              ></MicNoneOutlinedIcon>
            ) : (
              <MicOffOutlinedIcon
                className={commonClasses.micAndVideoOff}
                onClick={toggleAudioHandler}
              ></MicOffOutlinedIcon>
            )}
          </AudioVideoButtonContainer>
          <RequestVideoButton
            disabled={!stream}
            onClick={() => {
              setInterviewCallPopup(false);
              callUser(idToCall);
              setPageReloaded(false);
              setHideVideo(false);
            }}
          >
            Request Call
          </RequestVideoButton>
        </AcceptCallPopup>
      ) : null}
      {screenSharePopup && !isInterviewerData && (
        <AcceptCallPopup>
          <CancelSharpIcon
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              setScreenSharePopup(false);
            }}
          ></CancelSharpIcon>
          {
            <video
              playsInline
              muted
              ref={popupShareRef}
              autoPlay
              style={{ width: "180px", height: "150px" }}
            />
          }
          <RequestVideoButton
            disabled={!shareStream}
            onClick={() => {
              setScreenSharePopup(false);
              shareScreen(idToShare);
              setSharingActive(true);
            }}
          >
            Start Sharing
          </RequestVideoButton>
        </AcceptCallPopup>
      )}
      {snackbar && (
        <SnackbarComponent
          open={snackbar}
          message={message}
          close={closeSnackbar}
          setSnackbar={setSnackbar}
          snackbarType={snackbarType}
          isInterviewer={!!isInterviewerData}
        />
      )}

      {isInterviewerData && (callAccepted || sharingActive) && (
        <DialogWrapper
          open={pageReloaded || !isCandidateActive}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContentWrapper>
            <DialogText>
              {commonStrings.interview.interviewMessages.connectionLost}
            </DialogText>
          </DialogContentWrapper>
          <DialogActionsWrapper>
            <YesButtonWrapper
              onClick={() => {
                window.location.reload();
                setPageReloaded(false);
              }}
              autoFocus
            >
              OK
            </YesButtonWrapper>
          </DialogActionsWrapper>
        </DialogWrapper>
      )}

      {screenSharePopup && !!isInterviewerData && (
        <DialogWrapper
          open={screenSharePopup && !!isInterviewerData}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContentWrapper>
            <HeaderText>
              {" "}
              {
                commonStrings.interview.interviewMessages
                  .shareScreenPermissionHeader
              }
            </HeaderText>
            <DialogText>
              {commonStrings.interview.interviewMessages.shareScreenPermissions}
            </DialogText>
          </DialogContentWrapper>
          <DialogActionsWrapper>
            <YesButtonWrapper
              onClick={() => {
                answerShare();
                setPageReloaded(false);
              }}
              autoFocus
            >
              View Screen
            </YesButtonWrapper>
          </DialogActionsWrapper>
        </DialogWrapper>
      )}

      {typingAccessRequested && (
        <DialogWrapper
          open={typingAccessRequested}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContentWrapper>
            <HeaderText>Access Request</HeaderText>
            <DialogText>
              {commonStrings.interview.interviewMessages.accessPermissions}
            </DialogText>
          </DialogContentWrapper>

          <DialogActionsWrapper>
            <AllowButton
              autoFocus
              onClick={() => {
                setTypingAccessRequested(false);
                setTypingAccessAllowed(false);
                allowEditAccess(true);
              }}
            >
              <span style={{ width: "43px", height: "24px" }}>Allow</span>
            </AllowButton>
            <DenyButton
              onClick={() => {
                setTypingAccessRequested(false);
                allowEditAccess(false);
              }}
            >
              <span style={{ width: "43px", height: "24px" }}>Deny</span>
            </DenyButton>
          </DialogActionsWrapper>
        </DialogWrapper>
      )}
    </Box>
  );
};

export default InterviewCoding;
