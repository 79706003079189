import React from 'react';
import { List, Box, Typography } from '@mui/material';
import styled from 'styled-components';

interface ISideNavProps {
  content: {
    name: string;
    icon: string;
    width: number;
    height: number;
    value?: string;
  }[];
}

const Container = styled(Box)`
  display: flex;
  width: 261px;
  min-height: 100vh;
  max-height: 100vh;
  flex-direction: column;
  padding-top:3%;
  align-items:center;
  background: #15182b;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0vw;
  }
  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
  }
`;

const BoxContainer = styled(Box)`
  && {
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items:flex-start;
    flex-wrap: wrap;
    gap: 20px;
    padding: 5px 0px 0px 5px;
    color: #000821;
  }
`;


const HeadingBox = styled(Box)`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;
const StyledName = styled(Typography)`
  && {
    font-family: var(--bodyText-font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #f1f1f1;
  }
`;

const StyledValue = styled(Typography)`
  && {
    display: flex;
    flex-wrap: wrap;
    font-family: var(--bodyText-font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #d9d9d9 !important;
    padding-left: 30px;
    padding-top: 8px;
  }
`;

const SkillsWrapper = styled.div`
  display: flex;
  padding-left: 20px;
  padding-top: 8px;
`;

const SkillsContainer = styled.ul`
  width: 95px;
  padding-left: 40px;
  && li {
    font-family: var(--bodyText-font-family);
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    display: list-item;
    color: #d9d9d9;
    padding-bottom: 5px;
  }
  && li::marker {
    font-size: 10px;
  }
`;

const SkillContainer = styled.ul`
  width: 95px;
  && li {
    font-family: var(--bodyText-font-family);
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    display: list-item;
    color: #d9d9d9;
    padding-bottom: 5px;
  }
  && li::marker {
    font-size: 10px;
  }
`;

const SkillRightContainer = styled.ul`
  padding: 0px 0px 0px 20px;
  && li {
    font-family: var(--bodyText-font-family);
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    padding-bottom: 5px;
    display: list-item;
    color: #d9d9d9;
  }
  && li::marker {
    font-size: 10px;
  }
`;

const StyledIcon = styled.img`
  margin-right: 10px;
`;

const SideNav: React.FC<ISideNavProps> = ({ content }) => {
  return (
    <Container>
      <List component='nav' aria-labelledby='nested-list-subheader'>
        <BoxContainer>
          {content?.map(({ name, icon, width, height, value }, index) => (
              !name?.toLowerCase()?.includes('skill') ? (
                <Box key={index}>
                  <HeadingBox>
                    <StyledIcon src={icon} width={width} height={height} />
                    <StyledName>{name}</StyledName>
                  </HeadingBox>
                  <BoxContainer>
                    <StyledValue>{value}</StyledValue>
                  </BoxContainer>
                </Box>
              ) : (
                    value &&
                      value?.split(',').length &&
                      value?.split(',').length > 0 && (
                        <Box key={index}>
                          <HeadingBox>
                            <StyledIcon
                              src={icon}
                              width={width}
                              height={height}
                            />
                            <StyledName>{name}</StyledName>
                          </HeadingBox>
                          <Box>
                            {value?.split(',').length &&
                            value?.split(',').length > 3 ? (
                              <SkillsWrapper>
                                <SkillContainer>
                                  {value
                                    ?.split(',')
                                    ?.splice(
                                      0,
                                      Math.ceil(value?.split(',').length / 2)
                                    )
                                    .map((obj: string, ind: number) => (
                                      <li key={ind}>{obj}</li>
                                    ))}
                                </SkillContainer>
                                <hr />
                                <SkillRightContainer>
                                  {value
                                    ?.split(',')
                                    ?.splice(
                                      Math.ceil(value?.split(',').length / 2),
                                      value.length
                                    )
                                    .map((obj: string, ind: number) => (
                                      <li key={ind}>{obj}</li>
                                    ))}
                                </SkillRightContainer>
                              </SkillsWrapper>
                            ) : (
                              <SkillsContainer>
                                {value
                                  ?.split(',')
                                  .map((obj: string, ind: number) => (
                                    <li key={ind}>{obj}</li>
                                  ))}
                              </SkillsContainer>
                            )}
                          </Box>
                        </Box>
                      )
              )
          ))}
        </BoxContainer>
      </List>
    </Container>
  );
};

export default SideNav;
