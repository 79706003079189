import { getData } from '../apiUtils';
import { client } from '../client';
import Endpoints from '../Endpoints';

export const getEnterpriseById = (id: string) => {
  return client.get(`${Endpoints.enterprise}/${id}`);
};

export const getEnterpriseDetails = (enterpriseId: string) => {
  if (enterpriseId) {
    return client
      .get(`${Endpoints.enterprise}/${enterpriseId}`)
      .then(getData);
  }
};
