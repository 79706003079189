import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
// CSS
import '../../css/assessmentCompleted.css';

// Static
import { sectionStatus } from '../../static/constants';
import { commonStrings } from '../../static/commonStrings';
import { Box, Typography, Divider } from '@mui/material';
import {
  getCandidateDetails,
  getSubmissionEmail,
} from '../../api/requests/candidate';
import { getEnterpriseById } from '../../api/requests/enterprise';
import { setProfileDetailSettings } from '../../util/util';
import { ThemeConfigurationType } from '../Types/Index';
import { getItem } from '../../util/storage';
import ZecodeUrl from '../common/ZecodeUrl';
import { zecodeLogo } from '../../static/constants';
import { getEnterpriseTheme } from '../../api/requests/themeConfiguration';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

//Images
const Zecode = '/images/ZecodeLogo.svg';
const Submission = '/images/submission.gif';

// To do for later
// const HelpIcon = '/images/HelpIcon.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footerLogo: {
      width: '75px',
      marginLeft: '10px',
      marginBottom: '33px'
    },
  })
);

const StyledContainer = styled.div`
  min-height: 100vh;
  background-position: center;
  background-size: 100% auto;
  mix-blend-mode: overlay;
  background-size: cover;
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 30px;
  background-color: #000821;
`;

const StyledHeader = styled(Box)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2vw 5vw 0vh 3.4vw;
  }
  && img {
    margin-bottom: 22px;
  }
`;

const Logo = styled.img`
  width: 190px;
  height: 45px;
`;

const LogoImage = styled.img`
  height: 60px;
  width: 60px;
  border-radius: 60px;
  margin-left:10px;
`;

const Footer = styled.div`
  padding: 0vh 5vw 5vh 3.5vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 100px;
  font-family: var(--bodyText-font-family);
  line-height: 21px;
  font-size: 14px;
  font-weight: 400;
`;

const Main = styled(Box)`
  background: #ebebeb;
  box-shadow: 0px 7px 16px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  display: flex;
  height: 320px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin: 50px 32% 0px 32%;
`;

const StyledSubmissionText = styled(Typography)`
  && {
    font-family: var(--bodyText-font-family);
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: #000821;
  }
`;

const StyledDivider = styled(Divider)`
  && {
    border-color: #4A90E2;
    border-bottom-width: 1px;
    width: 80%;
  }
`;

const Content = styled(Typography)`
  && {
    font-family: var(--bodyText-font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #443f3f;
    padding: 0px 1vw;
  }
`;

export const Span = styled.span`
color:#ffffff;
`;

export const PoweredBy = styled.div`
display: flex;
margin-right:20px;
margin-left:auto;
`;

type testStatusType = {
  openEnd: string;
  multipleChoice: string;
  codingChallenges: string;
  comprehension: string;
};

const AssessmentCompleted = () => {
  const { id } = useParams();
  const classes = useStyles();
  const enterpriseId = getItem('enterpriseId');
  const navigate = useNavigate();
  const [profileDetails, setProfileDetails] =
    useState<ThemeConfigurationType>();
  const [enterpriseLogo, setEnterpriselogo] = useState('');
  const [testStatus, setTestStatus] = useState<testStatusType>({
    openEnd: '',
    multipleChoice: '',
    codingChallenges: '',
    comprehension: '',
  });
  const [totalQuestions, setTotalQuestions] = useState<any>({
    openEnd: 0,
    multipleChoice: 0,
    codingChallenges: 0,
    comprehension: 0,
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const getExamSubmitted = searchParams.get('examSubmitted');

  const fetchUserDetails = async () => {
    try {
      const response = await getCandidateDetails(id!);
      const newData = { ...totalQuestions };
      newData.codingChallenges =
        response?.data?.data[0]?.assessmentDetails?.questions?.length;
      newData.openEnd =
        response?.data?.data[0]?.assessmentDetails?.openEndQuestions?.length;
      newData.multipleChoice =
        response?.data?.data[0]?.assessmentDetails?.multipleChoiceQuestions?.length;
      newData.comprehension =
        response?.data?.data[0]?.assessmentDetails?.comprehensionQuestions?.length;
      setTotalQuestions(newData);
      if (response?.data.data[0]?.testResponse?.testStatus)
        setTestStatus(response?.data.data[0]?.testResponse?.testStatus);
      else {
        const { inComplete } = sectionStatus;
        setTestStatus({
          ...testStatus,
          openEnd: inComplete,
          multipleChoice: inComplete,
          codingChallenges: inComplete,
          comprehension: inComplete,
        });
      }
    } catch (error) {
      navigate('/');
    }
  };

  const submissionMail = async () => {
    const response = await getCandidateDetails(id!);
    const enterprise = await getEnterpriseById(
      response?.data?.data[0].assessmentDetails?.enterpriseId
    );
    const profile = await getEnterpriseTheme(
      response?.data?.data[0].assessmentDetails?.enterpriseId
    );
    setEnterpriselogo(enterprise?.data?.data?.imageURL);
    const submissionMailResponse = await getSubmissionEmail(
      response?.data?.data[0].email,
      response?.data?.data[0].assessmentDetails?.name,
      enterprise.data?.data?.name,
      profile?.data?.data?.imageURL
        ? profile?.data?.data?.imageURL
        : enterprise?.data?.data?.imageURL
          ? enterprise?.data?.data?.imageURL
          : zecodeLogo,
      profile?.data?.data?.firstColor
    );
    if (submissionMailResponse && submissionMailResponse?.status === 'success') {
      searchParams.delete('examSubmitted');
      setSearchParams(searchParams);
    }
  };

  const fetchProfileDetails = async () => {
    const response = await getEnterpriseTheme(enterpriseId!);
    response &&
      response?.data &&
      response?.data?.data &&
      setProfileDetailSettings(response?.data?.data);
    setProfileDetails(response?.data?.data);
  };

  useEffect(() => {
    fetchUserDetails();
    enterpriseId && fetchProfileDetails();
    getExamSubmitted && submissionMail();
  }, []);

  useEffect(() => {
    const handlePopState = async () => {
      window.history.pushState(null, window.location.href);
    };
    window.history.pushState(null, window.location.href);
    window.onpopstate = () => window.history.go(1);
    window.addEventListener('popstate', handlePopState);
    return () => window.removeEventListener('popstate', handlePopState);
  });



  return (
    <StyledContainer>
      <StyledHeader>
        <LogoImage
          src={
            profileDetails?.imageURL
              ? profileDetails?.imageURL
              : enterpriseLogo
                ? enterpriseLogo
                : zecodeLogo
          }
          alt='zessta Logo'
        />
      </StyledHeader>
      <Main>
        <img src={Submission} alt='Submission' width={174} height={174} />
        <StyledSubmissionText>
          {commonStrings.assessment.submissionTexts.submissionText}
        </StyledSubmissionText>
        <StyledDivider />
        <Content>{commonStrings.assessment.submissionTexts.content}</Content>
      </Main>
      <Footer>
        {/* To do for later */}
        {/* <HelpIconWrapper>
          <img src={HelpIcon} alt='HelpIcon' />
          <p color='white'>Help</p>
        </HelpIconWrapper> */}
        <ZecodeUrl />
        <PoweredBy >
          <Span>
            powered by {' '}&nbsp;
          </Span>
          <b style={{ color: '#fff', fontSize: '14px' }}>ze</b>
          <b style={{ color: '#BF85FA', fontSize: '14px' }}>[</b>
          <b style={{ color: '#fff', fontSize: '14px' }}>code</b>
          <b style={{ color: '#BF85FA', fontSize: '14px' }}>]</b>
        </PoweredBy>
      </Footer>
    </StyledContainer>
  );
};

export default AssessmentCompleted;
