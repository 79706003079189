import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Divider, Typography } from '@mui/material';
import styled from 'styled-components';

// Static
import {
  RouterNames,
  assessmentIdForNewCandidate,
} from '../../static/constants';
import { commonStrings } from '../../static/commonStrings';
import { UserDetails } from '../Types/Index';
import ZecodeUrl from '../common/ZecodeUrl';
import { debounce } from 'lodash';
import { createCandidateDetails } from '../../api/requests/newCandidate';

const ZesstaLogo = '/images/ZesstaLogo.svg';
const Zecode = '/images/Zecode.svg';

//styled components
const StyledContainer = styled.div`
  min-height: 100vh;
  background-position: center;
  background-color: black;
  background-size: 100% auto;
  mix-blend-mode: overlay;
  background-size: cover;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 30px 60px 10px 60px;
`;

const StyledHeader = styled(Box)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const LogoContainer = styled.div`
  display: flex;
`;

const Logo = styled.img`
  width: 190px;
  height: 45.43px;
  margin-bottom: 0px !important;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
`;

const ContentLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

const InvitationText = styled(Typography)`
  && {
    color: var(--first-color);
    font-family: var(--bodyText-font-family);
    font-size: 34px;
    font-weight: 600;
    text-align: left;
  }
`;

const InvitationTextDescription = styled(Typography)`
  && {
    width: 40vw;
    font-family: var(--bodyText-font-family);
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: #707070;
  }
`;
const StyledDivider = styled(Divider)`
  && {
    background: linear-gradient(
      90.01deg,
      #d1d1d1 -7.6%,
      rgba(255, 255, 255, 0) 102.36%
    );
    width: 87%;
    height: 5px;
    border-radius: 50px;
    margin-top: 26px;
    margin-bottom: 32px;
  }
`;

const StyledCard = styled.div`
  && {
    width: 30.55vw;
    border-radius: 16px;
    background-color: #ebebeb;
    display: flex;
    flex-direction: column;
    padding: 42px 37px;
    box-shadow: 0px 7px 16px rgba(0, 0, 0, 0.08);
    justify-content: start;
    flex-wrap: wrap;
    gap: 15px;
    min-height: 557px;
  }
`;

const CardHeading = styled(Typography)`
  && {
    font-family: var(--bodyText-font-family);
    font-size: 34px;
    font-weight: 600;
    line-height: 51px;
    letter-spacing: 0em;
    text-align: left;
    color: #000821;
  }
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 16px;
`;

const CardDescription = styled(Typography)`
  && {
    font-family: var(--bodyText-font-family);
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--first-color);
  }
`;

const CardShortNote = styled(Typography)`
  && {
    font-family: var(--bodyText-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #443f3f;
  }
`;
const StyledButton = styled(Button)`
  && {
    background-color: var(--first-color);
    align-items: center;
    width: 100%;
    height: 48px;
    border-radius: 8px;
    text-transform: capitalize;
    color: #ffffff;
    border: 2px solid var(--first-color);
    font-family: var(--bodyText-font-family);
    font-weight: 500;
    font-size: 16px;
    color: #ebebeb;
    &:hover {
      background: #fef6f6;
      color: #000000;
      border: 2px solid var(--first-color);
    }
  }
`;

const CardDivider = styled(Divider)`
  && {
    width: 50%;
    border-color: #bdbcbc;
  }
`;

const Footer = styled.div`
  padding-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const LogoImage = styled.img`
  height: 35px;
`
const FieldContainer = styled(Box)``

const FieldBox = styled(Box)<{ $error: boolean }>`
  && input {
    width: 100%;
    font-size: 16px;
    padding: 13px 10px;
    font-family: var(--bodyText-font-family);
    border: 1px solid transparent;
    box-shadow: 0px 0px 24px 2px rgba(188,188,188,0.24);
    border-radius: 10px;
  }
  && input:focus-visible {
    outline: none;
  }
  && label, p {
    font-size: 14px;
    font-family: var(--bodyText-font-family);
    color: #00821;
  }
  && p {
    margin-bottom: 15px;
    color: ${props => props.$error ? 'rgb(255, 77, 106)' : 'transparent'};
  }
`

const CandidateCreation = () => {
  const navigate = useNavigate();
  const [ userDetails, setUserDetails ] = useState<UserDetails>({name: '', email: ''})
  const [ isError, setIsError ] = useState<boolean>(false)

  const userDetailTypes = {
    Name: 'name',
    Email: 'email'
  }

  const fieldData = [
    {
      title: 'Name',
      value: userDetails.name,
      type: userDetailTypes.Name,
      error: isError && !userDetails.name
    },
    {
      title: 'Email',
      value: userDetails.email,
      type: userDetailTypes.Email,
      error: isError && (!userDetails.email || !userDetails.email.includes('@'))
    }
  ]

  const onClickProceedHandler = debounce(async () => {
    if(userDetails.name.trim() && userDetails.email.trim() && userDetails.email.includes('@')) {
      const details = {
        assessmentId: assessmentIdForNewCandidate ?? '',
        name: userDetails.name,
        email: userDetails.email
      }
      const response: any = await createCandidateDetails(details);
      if(response?.status === 'success') {
        navigate(`${RouterNames.assessmentDetails}/${response?.candidateId}`);
      }
    } else {
      setIsError(true)
    }
  }, 500);

  const changeHandler = (value: string, type: string) => {
    const data: any = { ...userDetails };
    data[type] = value;
    setUserDetails(data)
  }

  return (
    <StyledContainer>
      <StyledHeader>
        <LogoContainer>
          <Logo className='Logo' src={Zecode} alt='zecode' />
        </LogoContainer>
        <LogoImage
          src={ZesstaLogo}
          alt='zessta Logo'
        />
      </StyledHeader>
      <StyledContent>
        <ContentLeft>
          <InvitationText>
            {commonStrings.assessment.assessmentStartPage.descriptionTextOne}
            <br />
            {commonStrings.assessment.assessmentStartPage.descriptionTextTwo}
          </InvitationText>
          <StyledDivider />
          <InvitationTextDescription>
            {commonStrings.assessment.assessmentStartPage.note}
          </InvitationTextDescription>
        </ContentLeft>
        <StyledCard>
          <CardHeading>
            {
              commonStrings.assessment.assessmentStartPage
                .candidateInvitationCardHeading
            },
          </CardHeading>
          <CardContent>
            <CardDescription>
              {commonStrings.assessment.assessmentStartPage.welcomeNote}
              Zessta ,
            </CardDescription>
            <CardDivider />
            <CardShortNote>
              {commonStrings.assessment.assessmentStartPage.descriptionNote}
              Fresher
            </CardShortNote>
          </CardContent>
          <FieldContainer>
            {fieldData.map((obj: any, ind: number) => 
            <FieldBox $error={obj.error} key={ind}>
              <label>{obj.title}</label>
              <input 
                type='text'
                defaultValue={obj.value}
                onChange={(event: any) => changeHandler(event?.target?.value, obj.type)} 
              />
              <p>{obj.error ? `Please, enter the 
              ${obj.type === userDetailTypes.Email && userDetails.email && 
                !userDetails.email.includes('@') ? 'valid' : ''} ${obj.type}` 
              : '.'}</p>
            </FieldBox>)}
          </FieldContainer>
          <StyledButton onClick={onClickProceedHandler}>
            {commonStrings.assessment.assessmentStartPage.buttonText}
          </StyledButton>
        </StyledCard>
      </StyledContent>
      <Footer>
        <ZecodeUrl />
      </Footer>
    </StyledContainer>
  );
};

export default CandidateCreation;
