import React from 'react';
import { Box } from '@mui/material';
import { zecodeGrey } from '../../static/constants';

export const ZecodeText = ({ isHeader }: { isHeader?: boolean }) => {
  const textColor = '#fff';
  const highlightColor = '#BF85FA';
  const fontSize = isHeader ? '35px' : '14px';
  const fontFamily = 'var(--bodyText-font-family)';
  const marginLeft = isHeader ? '' : 'auto';

  return (
    <Box sx={{ display: 'flex', marginLeft, color: zecodeGrey }}>
      <Box>
        {!isHeader && <>powered by&nbsp;</>}
        <b style={{ color: textColor, fontSize, fontFamily }}>ze</b>
        <b style={{ color: highlightColor, fontSize, fontFamily }}>[</b>
        <b style={{ color: textColor, fontSize, fontFamily }}>code</b>
        <b style={{ color: highlightColor, fontSize, fontFamily }}>]</b>
      </Box>
    </Box>
  );
};
