import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
// CSS
import "../../css/assessmentCompleted.css";

// Static
import { commonStrings } from "../../static/commonStrings";
import { Box, SxProps } from "@mui/material";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { getEnterpriseById } from "../../api/requests/enterprise";
import { setProfileDetailSettings } from "../../util/util";
import { ThemeConfigurationType } from "../Types/Index";
import { getEnterpriseTheme } from "../../api/requests/themeConfiguration";
import { getInterviewCandidate } from "../../api/requests/interview";
import ZecodeUrl from "../common/ZecodeUrl";
import { ZecodeText } from "../common/zecodeText";
import { useStylesCommon } from "../common/styles";
import { RouterNames, sectionStatus } from "../../static/constants";

const zecodeLogo = "/images/zecodeSideBarLogo.svg";
const BackgroundImage = "/images/interviewsBackground.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      display: "flex",
      position: "relative",
      textAlign: "center",
      height: "100vh",
    },
    logo: {
      width: "65px",
      height: "65px",
      borderRadius: "40px",
    },
    interviewTextContent: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    mainContainer: {
      backgroundImage: `url(${BackgroundImage})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    },
    enterpriseName: {
      fontWeight: 500,
      paddingBottom: "30px",
    },
    footerLogo: {
      width: "75px",
      marginLeft: "10px",
      marginBottom: "27px",
      marginTop: "3px",
      height: "18px",
    },
  })
);

const Footer = styled.div`
  display: flex;
  padding: 0vh 4vw 0vh 2vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: var(--bodyText-font-family);
  line-height: 21px;
  font-size: 14px;
  font-weight: 400;
`;

const InterviewText = styled(Box)`
  margin-bottom: 20px;
  font-family: var(--bodyText-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: capitalize;
  color: #ffffff;
  white-space: pre;
`;

const ThankYouText = styled(Box)`
  font-family: var(--bodyText-font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #e0e0e0;
  margin-top: 20px;
`;

const Hr = styled.hr`
  width: 500px;
  height: 0px;
  margin-bottom: 30px;
  border: 1px solid #e0e0e0;
`;

const StyledContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
`;

const LogoWrapper = styled(Box)`
  display: flex;
  justify-content: flex-start;
  margin-right: 80px;
  margin-top: 20px;
  margin-left: 38px;
`;

const StyledButton = styled.button`
  height: 100%;
  text-transform: capitalize;
  margin-top: 14px;
  font-size: 14px;
  color: #ffffff;
`;

const InterviewCompleted = () => {
  const commonClasses = useStylesCommon();
  const navigate = useNavigate();
  const classes = useStyles();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const isLeaveButtonClicked = searchParams.get("l");

  const [enterpriseLogo, setEnterpriselogo] = useState("");
  const [status, setStatus] = useState();
  const [enterpriseId, setEnterpriseId] = useState();
  const [enterpriseName, setEnterpriseName] = useState("");
  const [profileDetails, setProfileDetails] =
    useState<ThemeConfigurationType>();

  const fetchInterviewDetails = async () => {
    if (id) {
      const response = await getInterviewCandidate({ id });
      setEnterpriseId(response?.data?.data?.enterpriseId);
      response &&
        response.data &&
        response.data.data &&
        setStatus(response?.data?.data?.status);
      const enterprise = await getEnterpriseById(enterpriseId!);
      setEnterpriselogo(enterprise?.data?.data?.imageURL);
      setEnterpriseName(enterprise?.data?.data?.name);
    }
  };

  const fetchProfileDetails = async () => {
    const response = await getEnterpriseTheme(enterpriseId!);
    response &&
      response?.data &&
      response?.data?.data &&
      setProfileDetailSettings(response?.data?.data);
    setProfileDetails(response?.data?.data);
  };

  const onClickRejoinHandler = () => {
    if (status === sectionStatus.inProgress) {
      navigate(`${RouterNames.interview}/${id}`);
    }
  };

  useEffect(() => {
    fetchInterviewDetails();
    fetchProfileDetails();
    status === "Submitted" &&
      navigate(`${RouterNames.interviewCompleted}/${id}`);
  }, [enterpriseId]);

  return (
    <StyledContainer className={classes.mainContainer}>
      <LogoWrapper>
        <img
          src={
            profileDetails?.imageURL
              ? profileDetails?.imageURL
              : enterpriseLogo
              ? enterpriseLogo
              : zecodeLogo
          }
          alt="logo"
          className={classes.logo}
        />
      </LogoWrapper>
      {isLeaveButtonClicked === "leave" && status === "In progress" ? (
        <Box>
          <InterviewText sx={{ textTransform: "unset" }}>
            {
              commonStrings.assessment.candidateSubmissiontexts
                .leaveInterviewText
            }
          </InterviewText>
          <Box sx={rejoinText}>
            {commonStrings.assessment.candidateSubmissiontexts.rejoinText}
          </Box>
          <Box sx={{ ...rejoinText, marginBottom: "20px" }}>
            {commonStrings.assessment.candidateSubmissiontexts.rejoinSubText}
          </Box>
          <InterviewText>
            <StyledButton
              onClick={onClickRejoinHandler}
              className={commonClasses.outlineButton}
            >
              Rejoin interview
            </StyledButton>
          </InterviewText>
        </Box>
      ) : (
        <Box className={classes.interviewTextContent}>
          <InterviewText>
            {commonStrings.assessment.candidateSubmissiontexts.submissionText}
          </InterviewText>
          <Hr />
          <ThankYouText>
            {commonStrings.assessment.candidateSubmissiontexts.content}
            <Box className={classes.enterpriseName}>{enterpriseName}</Box>
          </ThankYouText>
        </Box>
      )}
      <Footer>
        <ZecodeUrl />
        <ZecodeText />
      </Footer>
    </StyledContainer>
  );
};

export default InterviewCompleted;

const rejoinText: SxProps = {
  fontFamily: "var(--bodyText-font-family)",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  color: "#ffffff",
  whiteSpace: "pre",
};
