import {
  FormLabel,
  Box,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
//  CSS
import '../../css/help.css';

// Static
import { RouterNames } from '../../static/constants';
const zecodeLogo = '/images/zecodeLogo.png';

const Help = () => {
  const [selectSection, setSelectSelection] = useState('');
  const { id } = useParams();

  return (
    <div className='container'>
      <div>
        <div>
          <img className='Logo' src={zecodeLogo} alt='zecode'></img>
        </div>
        <Link to={`${RouterNames.assessment}/${id}`} className='Link'>
          <span>&larr; Back to Assessment</span>
        </Link>
      </div>
      <p>Raise your issue</p>
      <RadioGroup row>
        <FormControlLabel
          value='Application related'
          control={<Radio />}
          label={'Application related'}
        />
        <FormControlLabel
          value='Assessment related'
          control={<Radio />}
          label={'Assessment related'}
        />
      </RadioGroup>
      <Box sx={{ maxWidth: 200 }}>
        <FormControl fullWidth>
          <InputLabel id='demo-simple-select-label'>Select Section</InputLabel>
          <Select
            className='selectSection'
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={selectSection}
            label='select Section'
            onChange={(event: any) => {
              setSelectSelection(event.target.value);
            }}
          >
            <MenuItem value={'Open-ended'}>Open-ended</MenuItem>
            <MenuItem value={'Multiple Choice'}>Multiple Choice</MenuItem>
            <MenuItem value={'Coding Challenges'}>Coding Challenges</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <FormLabel id='demo-row-radio-buttons-group-label'>
        Problem with:
      </FormLabel>
      <RadioGroup row>
        <FormControlLabel
          value='Question'
          control={<Radio />}
          label='Question'
        />
        <FormControlLabel value='Others' control={<Radio />} label='Others' />
      </RadioGroup>
      <Box sx={{ maxWidth: 400 }}>
        <TextField
          fullWidth
          id='outlined-multiline-static'
          label='Subject/ Question'
          multiline
          rows={4}
          placeholder='Write your issue briefly...'
        />
      </Box>
      <button>SEND MESSAGE</button>
    </div>
  );
};

export default Help;
