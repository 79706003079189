import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import 'react-multi-carousel/lib/styles.css';
import io from 'socket.io-client';
import {
  Alert,
  Box,
  Button,
  Grid,
  Snackbar,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { getPlagiarismDetails } from '../../api/requests/plagarism';
import 'react-multi-carousel/lib/styles.css';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';

//css
import '../../css/assessment.css';

// Components
import {
  OpenEnded,
  MultipleChoice,
  CodingChallenges,
  DatabaseQuestions,
  Comprehension,
} from './assessmentSections';
import PopupMessage from '../popups/popupMessage';
import CandidateCoding from '../candidates/candidateCoding';

// Static
import {
  baseUrl,
  Languages,
  RouterNames,
  SectionDetails,
  SECTIONS,
  sectionStatus,
  Status,
  Time,
  QuestionStatus,
  demoAssessmentId,
  AvailableModules,
  zecodeLogo,
  StorageKeys,
  leavingTabsCount,
  examSubmitted,
  candidateStatus,
} from '../../static/constants';

// Util
import { getItem, removeItem, setItem } from '../../util/storage';
import { ThemeConfigurationType, statusType } from '../Types/Index';
import { candidateResponses, createCompareSession, updateMarkForReview } from '../../api/requests/candidate';
import { updateAssessmentStatus } from '../../api/requests/candidate';
import { fetchAssessmentTimer } from '../../api/requests/assessmentTimer';
import { getCandidateDetails } from '../../api/requests/candidate';
import { updatePlagarismDetails } from '../../api/requests/plagarism';
import Proctor from '../candidates/Proctor';
import { setProfileDetailSettings } from '../../util/util';
import { getEnterpriseById } from '../../api/requests/enterprise';
import { getEnterpriseTheme } from '../../api/requests/themeConfiguration';
import moment from 'moment';
import CountDown from '../common/countDown';
import { getNameFromEmail } from '../common/getNameFromEmail';

//Icons
const Answered = '/images/Answered.svg';
const Unanswered = '/images/Unanswered.svg';
const Review = '/images/review.svg';
const timeIcon = '/images/TimeIcon_white.svg';
const NameIcon = '/images/NameIcon_white.svg';
const DatabaseIcon = '/images/databasequestions_Icon.svg';
const socket = io(baseUrl!);
export const Loader = '/images/assessmentLoader.gif';

// styled components
const Container = styled(Box)`
  display: flex;
  width: calc(100vw - 261px);
  flex-direction: column;
  min-height: 100vh;
  height: 100vh;
  ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    width: 0px;
  }
  ::-webkit-scrollbar {
    width: 0px;
  }
`;

const SidenavContainer = styled(Box)`
  display: flex;
  width: 275px;
  padding-top: 50px;
  min-height: 100vh;
  max-height: 100vh;
  flex-direction: column;
  background-color: #15182b;
  &::-webkit-scrollbar {
    width: 0vw;
  }
  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
  }
`;

const StyledIcon = styled.img`
  margin-right: 10px;
  width:19px;
  height:21px;
`;

const SectionsTypography = styled(Typography)`
  && {
    font-family: var(--bodyText-font-family);
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0em;
    color: #f1f1f1;
  }
`;

const StyledEachTabContainer = styled.div`
  padding: 10px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  cursor: pointer;
  height: 60vh;
  overflow: auto;
    ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
  }
  ::-webkit-scrollbar {
    width: 0.4vw;
  }
`;

const StyledEachTab = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0px 10px 0px;
`;

const StyledSectionBox = styled.div`
  padding: 0px 0px 0px 20px;
  margin-top: 16px;
  width: 261px;
`;

const EachSection = styled.div`
  padding-left: 20px;
  display: flex;
  flex-direction: row;
`;

const StyledQuestionsDiv = styled.div<{ $background: boolean }>`
  && {
    width: 261px;
    border-left: ${(props) =>
    props.$background ? '8px solid  var(--first-color)' : 'none'};
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 10px;
  }
`;

const EachQuestionDiv = styled.div<{ $border: boolean; $background: string }>`
  width: 22px;
  height: 22px;
  border: ${(props) =>
    props.$border ? '1px solid  var(--first-color)' : '1px solid transparent'};
  background-color: ${(props) => props.$background && props.$background};
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-left: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 300;
  margin-top: 8px;
  color: #000821 !important;
`;

const StyledMainContent = styled.div`
  overflow: auto;
  max-height: calc(100vh - 62px);
  height: calc(100vh - 62px);
  ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
  }
  ::-webkit-scrollbar {
    width: 0.2vw;
  }
  padding: 20px 0px 2.7% 3.5vw;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 25px;
`;

const SectionHeading = styled(Typography)`
  && {
    font-family: var(--bodyText-font-family);
    font-size: 28px;
    font-weight: 600;
    line-height: 51px;
    letter-spacing: 0em;
    text-align: left;
    color: #000821;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  && {
    .MuiTypography-root {
      font-family: var(--bodyText-font-family);
      font-weight: 400;
      font-size: 16px;
      color: #000821;
    }
  }
`;

const StyledButtonContainer = styled.div`
  && {
    display: flex;
    flex-direction: column;
  }
`;

const StyledButton = styled(Button)`
  && {
    height: 38px;
    max-width: 200px;
    background: transparent;
    border: 2px solid var(--first-color);
    border-radius: 8px;
    font-family: var(--bodyText-font-family);
    font-size: 14px!important;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-transform: capitalize;
    margin:28px 0px 28px 30px;
    color: #f1f1f1 !important;
    white-space: nowrap;
    &:hover {
      border: 2px solid var(--first-color);
      box-shadow: 0 0 20px var(--first-color);
    }
  }
`;

const LabelGrid = styled(Grid)`
  && {
    justify-content: start;
    padding-left: 20px;
  }
`;
const StyledLabel = styled.img`
  margin: 4px 10px 0px 0px;
  width: 10px;
  height: 10px;
`;

const StyledLabelTypography = styled(Typography)`
  && {
    font-family: var(--bodyText-font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #f1f1f1;
  }
`;

const EachLabelDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledHeader = styled(Box)`
  && {
    display: flex;
    height: 3.8vw;
    width:100%;
    top: 0;
    justify-content: center;
    flex-direction:column;
    align-items: inherit;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.17);
    z-index: 1;
    padding: 0px 3.3% 0px 3.5vw;
    @media (max-width: 1350px) {
    height: 5.5vw;
  }
  }
`;


const CodingContainer = styled(Box) <{ $isDark: boolean }>`
  display: flex;
  max-width: 100vw;
  background-color: ${(props) => (props.$isDark ? '#FcFcFc' : '#1E1B31')};
  min-height: 100vh;
`;

const LogoWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content:space-between;
`;

const ZecodeLogo = styled.img`
  padding: 22px;
  width: 65px;
  height: 90px;
  border-radius: 50%;
`;

const CountDownContainer = styled(Box)`
  display: flex;
  background-color: transparent;
  font-family: var(--bodyText-font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000821;
`;

const TimeLeftWrapper = styled(Box)`
  width: 300px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SectionName = styled.span`
  font-family: var(--bodyText-font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #f1f1f1;
`;

const NameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Name = styled(Box)`
  font-family: var(--bodyText-font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000821;
`;

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 3vw;
`;

const MainWrapper = styled(Box)`
  display: flex;
`;

const NameAndTimeWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content:space-between;
  width:100%;
`;

const CheckboxWrapper = styled(Checkbox)``;

const SquareRoundedIconWrapper = styled(SquareRoundedIcon)`
  color: #d9d9d9;
  bottom: 0;
  right: 4px;
`;

const SectionTopMargin = styled.div`
display: flex;
flex-direction: column;
`;

const CheckBoxIconWrapper = styled(CheckBoxIcon)`
  color: var(--first-color);
  bottom: 0;
  right: 4px;
`;

export const LoaderWrapper = styled.div`
  display: flex; 
  justify-content: center; 
  align-items: center; 
  height: 100vh; 
  margin: auto;

`;

export const LoaderImageWrapper = styled.img`
  width: 40px;
  height: 40px;
`;

export type LanguageType = {
  id: number;
  data: string;
  value: string;
};

export type TimeTakenType = {
  startTime: number;
  endTime: number;
  extraTime: number;
  submittedTime: number;
};

const Assessment = () => {
  const navigate = useNavigate();
  const { id, sessionToken } = useParams();
  const enterpriseId = getItem('enterpriseId');

  // useState
  const [popupTrigger, setPopupTrigger] = useState(false);
  const [isCodeEditorOpen, setIsCodeEditorOpen] = useState(false);
  const [snackbarTrigger, setSnackbarTrigger] = useState(false);
  const [popupMessage, setPopupMessage] = useState<string>();
  const [candidateName, setCandidateName] = useState<string>();
  const [markedForReviewQuestions, setMarkedForReviewQuestions] = useState<
    string[]
  >([]);
  const [questionDetails, setQuestionDetails] = useState<any>();
  const [section, setSection] = useState(''); // Storing the section name we are in

  const [popupSection, setPopupSection] = useState<string>(''); // Storing the section name to change b|w sections
  const [remainingTime, setRemainingTime] = useState<number>();
  const [timeLeft, setTimeLeft] = useState('');
  const [languages, setLanguages] = useState<Array<LanguageType>>([]);
  const [totalTimeTaken, setTotalTimeTaken] = useState<number>();
  const [leftTab, setLeftTab] = useState<boolean>(false);
  const [profileDetails, setProfileDetails] =
    useState<ThemeConfigurationType>();
  const [enterpriseLogo, setEnterpriseLogo] = useState('');
  const [enterpriseSubscriptions, setEnterpriseSubscriptions] = useState<
    Array<string>
  >([]);
  const [theme, setTheme] = useState<boolean>(false);
  const [malpracticeFound, setMalpracticeFound] = useState<boolean>(false);
  // for phase 2
  // const [sectionTimer, setSectionTimer] = useState<sectionTimer>({
  //   openEnd: convertTime(20),
  //   multipleChoice: convertTime(10),
  //   codingChallenges: convertTime(30),
  // });

  const [status, setStatus] = useState<statusType>({
    openEnd: sectionStatus.inComplete,
    multipleChoice: sectionStatus.inComplete,
    codingChallenges: sectionStatus.inComplete,
    databaseQuestions: sectionStatus.inComplete,
    comprehension: sectionStatus.inComplete,
  });
  const [questions, setQuestions] = useState<any>({
    question: {
      openEnd: [],
      multipleChoice: [],
      codingChallenges: [],
      databaseQuestions: [],
      comprehension: [],
    },
    questionNo: {
      openEnd: 0,
      multipleChoice: 0,
      codingChallenges: 0,
      databaseQuestions: 0,
      comprehension: 0,
    }, // Question number we are in
    answer: {
      openEnd: [],
      multipleChoice: [],
      codingChallenges: [],
      databaseQuestions: [],
      comprehension: [],
    }, // Storing answers.
  });
  const [currentTime, setCurrentTime] = useState<number>();
  const [loader, setLoader] = useState<boolean>(false);
  const questionRef = useRef<HTMLDivElement | null>(null);
  // ref
  const refs = useRef<any>({
    totalTime: React.createRef(),
    openEnd: React.createRef(),
    multipleChoice: React.createRef(),
    codingChallenges: React.createRef(),
    comprehension: React.createRef(),
  });

  const submitHandler = () => {
    localStorage.removeItem('markedForReview');
    setPopupSection(Status.submitAssessment);
    setPopupTrigger(true);
  };

  const timeEnd = moment(timeLeft);

  const timeCompletion = async () => {
    await updateResponses();
    await updateStatus();
    setPopupTrigger(true);
    setPopupSection(Status.timeCompleted);
  };

  // for phase 2
  //starting and pausing the timer when changing the sections
  // const handleCountdown = (sectionName: string) => {
  //     SectionDetails.forEach((sectionDetail) => {
  //         if (sectionDetail.key === sectionName)
  //             refs.current[sectionDetail.key].current.start();
  //         else
  //             refs.current[sectionDetail.key].current.pause();
  //     });
  // };

  // storing question index after clicking on question number
  const sectionQuestions = (sectionName: string, questionId: number) => {
    if (sectionName === SECTIONS.openEnd)
      setQuestions({
        ...questions,
        questionNo: { ...questions.questionNo, openEnd: questionId },
      });
    else if (sectionName === SECTIONS.multipleChoice)
      setQuestions({
        ...questions,
        questionNo: { ...questions.questionNo, multipleChoice: questionId },
      });
    else if (sectionName === SECTIONS.comprehension)
      setQuestions({
        ...questions,
        questionNo: { ...questions.questionNo, comprehension: questionId },
      });
    else if (sectionName === SECTIONS.codingChallenges) {
      setQuestions({
        ...questions,
        questionNo: { ...questions.questionNo, codingChallenges: questionId },
      });
      openHandler(questionId);
    } else if (sectionName === SECTIONS.databaseQuestions) {
      setQuestions({
        ...questions,
        questionNo: { ...questions.questionNo, databaseQuestions: questionId },
      });
      openHandler(questionId);
    }
  };

  //saving answers in the database
  const updateResponses = async (answers?: any) => {
    try {
      /*
        Points to consider:
        1. questions.answer.codingChallenges[currentQuestionIndex].code[0] always gives the latest code to be updated
        2. There is no difference between "question,answer" and "candidateResponsesCopy"
      */

      const candidateResponsesCopy = { ...questions.answer };

      const presentSection = localStorage.getItem('section');
      if (presentSection === 'codingChallenges') {
        // Extract the "currentQuestionIndex" from the "localStorage"
        const currentQuestionIndex = localStorage.getItem(
          'currentQuestionIndex'
        );
        // Now discard the previous state of the code and extract the current code and the current "codingChallenge" entity
        const latestCodingChallengeEntity = {
          ...questions?.answer?.codingChallenges[currentQuestionIndex!],
        };
        latestCodingChallengeEntity!.code = [
          latestCodingChallengeEntity?.code[0],
        ];
        candidateResponsesCopy!.codingChallenges = [
          { ...latestCodingChallengeEntity },
        ];
        candidateResponses(
          status,
          answers ? answers : candidateResponsesCopy,
          id!,
          latestCodingChallengeEntity?.id,
          presentSection
        );
      } else {
        delete candidateResponsesCopy.codingChallenges;
        candidateResponses(
          status,
          answers ? answers : candidateResponsesCopy,
          id!,
        );
      }
    } catch (error) {
      console.error(error);
    }
  };


  const updateStatus = async () => {
    try {
      const params = {timeSpent:totalTimeTaken,markedForReview:markedForReviewQuestions,timeUp:true}
      await updateAssessmentStatus(params,id!);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchTimeLeft = async (candidateTimeSpent?: number) => {
    try {
      const response = await fetchAssessmentTimer(id!);
      const data = response?.data?.data;
      const originalTimestamp = data?.endTime;
      const originalDate = new Date(originalTimestamp);
      // Adjust the hours by adding extra time
      let updatedDate;
      if (data?.submittedTime) {
        updatedDate = new Date(
          originalDate.getTime());
      } else {
        updatedDate = new Date(
          originalDate.getTime() + data?.extraTime * 60 * 1000
        );
      }
      const updatedTimestamp = updatedDate.toISOString();
      setTimeLeft(data?.extraTime > 0 ? updatedTimestamp : data?.endTime);
      const timeTaken =
        (Date.parse(data?.endTime) - Date.parse(data?.startTime)) / Time.MINUTE;
      let timeSpent;
      if (data?.submittedTime) {
        timeSpent = candidateTimeSpent! + timeTaken;
      }
      else {
        timeSpent = timeTaken + data?.extraTime
      }
      setTotalTimeTaken(timeSpent);
      if (data?.submittedTime) {
        if ((updatedDate < response?.data?.dateNow || (data?.submittedTime && questionDetails?.data?.data[0]?.isTimeExtended === true)) &&
          id !== demoAssessmentId) {
          removeItem(StorageKeys.WarningCount);
          removeItem(StorageKeys.ProctorStatus);
          navigate(`${RouterNames.assessmentCompleted}/${id}?${examSubmitted}`);
        }
      } else {
        if ((Date.parse(data?.endTime) + data?.extraTime * Time.MINUTE < response?.data?.dateNow) &&
          id !== demoAssessmentId) {
          removeItem(StorageKeys.WarningCount + id);
          removeItem(StorageKeys.ProctorStatus + id);
          removeItem(leavingTabsCount);
          removeItem(StorageKeys.candidateName);
          navigate(`${RouterNames.assessmentCompleted}/${id}?${examSubmitted}`);
        }
      }
      if (data?.submittedTime) {
        if (new Date().toISOString() > updatedDate.toISOString()) {
          setPopupSection(Status.timeCompleted);
          removeItem(StorageKeys.WarningCount + id);
          removeItem(StorageKeys.ProctorStatus + id);
          removeItem(leavingTabsCount);
          removeItem(StorageKeys.candidateName);
          navigate(`${RouterNames.assessmentCompleted}/${id}?${examSubmitted}`);
        }
      } else {
        if (new Date().toISOString() > data?.endTime) {
          setPopupSection(Status.timeCompleted);
          removeItem(StorageKeys.WarningCount);
          removeItem(StorageKeys.ProctorStatus);
          navigate(`${RouterNames.assessmentCompleted}/${id}?${examSubmitted}`);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const shuffleOptions = (array: any[]) => {
    for (
      let currentIndex = array.length - 1;
      currentIndex > 0;
      currentIndex--
    ) {
      const newIndex = Math.floor(Math.random() * (currentIndex + 1));
      const temp = array[currentIndex];
      array[currentIndex] = array[newIndex];
      array[newIndex] = temp;
    }
    return array;
  };

  const fetchQuestions = async () => {
    try {
      setLoader(true)
      const questionResponse = await getCandidateDetails(id!);
      if (questionResponse) {
        fetchTimeLeft(questionResponse?.data?.data[0]?.timeSpent);
        setQuestionDetails(questionResponse);
        setItem('candidateName', questionResponse?.data?.data[0]?.name === '' ? getNameFromEmail(questionResponse?.data?.data[0]?.email) : questionResponse?.data?.data[0]?.name)
        const responseData = questionResponse?.data?.data[0];
        if (responseData?.status === candidateStatus.invited) {
          navigate(`${RouterNames.assessmentDetails}/${id}`);
        }
        if(responseData?.status === candidateStatus.submitted || responseData?.status === candidateStatus.notAttempted){
          navigate(`${RouterNames.assessmentCompleted}/${id}?${examSubmitted}`);
        }
        const newData = { ...questions };
        newData.question.codingChallenges =
          responseData?.assessmentDetails?.questions;
        const filteredIDEs: LanguageType[] = [];
        responseData?.assessmentDetails?.languages.forEach((item: number) => {
          filteredIDEs.push(Languages.find((ide) => ide?.id === item)!);
        });
        setLanguages(filteredIDEs);
        newData.question.openEnd =
          responseData?.assessmentDetails?.openEndQuestions;
        newData.question.multipleChoice =
          responseData?.assessmentDetails?.multipleChoiceQuestions;
        newData.question.databaseQuestions =
          responseData?.assessmentDetails?.databaseQuestions;
        newData.question.comprehension =
          responseData?.assessmentDetails?.comprehensionQuestions;
        if (responseData?.testResponse?.questionsResponse) {
          newData.answer = responseData?.testResponse?.questionsResponse;
        }
        newData?.question?.multipleChoice?.map((question: any) => {
          shuffleOptions(question.options);
        });
        newData?.question?.comprehension?.map((question: any) => {
          question.questionSet.map((mcq: any) => {
            shuffleOptions(mcq?.options);
          });
        });
        const arrangeAnswerToQuestions = newData?.answer?.codingChallenges?.reduce(
          (map: any, obj: any) => {
            map[obj?.id] = obj;
            return map;
          },
          {}
        );
        newData.answer.codingChallenges = newData?.question?.codingChallenges?.map(
          (obj: any) => arrangeAnswerToQuestions[obj._id] || null
        );
        setQuestions(newData);
        setCandidateName(
          questionResponse?.data?.data[0]?.name
            ? questionResponse?.data?.data[0]?.name
            : getNameFromEmail(questionResponse?.data?.data[0]?.email)
        );
        const enterprise = await getEnterpriseById(
          questionResponse?.data?.data[0]?.assessmentDetails?.enterpriseId
        );
        enterprise &&
          setEnterpriseSubscriptions(enterprise?.data?.data?.subscriptions);
        setEnterpriseLogo(enterprise?.data?.data?.imageURL);
        setItem('enterpriseId', enterprise?.data?.data?._id);
      }
      setLoader(false)
    } catch (error) {
      console.error(error);
    }
  };

  const onClickCheckBoxHandler = (id: string) => {
    if (markedForReviewQuestions.includes(id)) {
      const newList = markedForReviewQuestions.filter(
        (questionId: string) => questionId !== id
      );
      setMarkedForReviewQuestions([...newList]);
      localStorage.setItem(`markedForReview`, JSON.stringify(newList));
    } else {
      const newList = markedForReviewQuestions ? [...markedForReviewQuestions, id] : [id];
      setMarkedForReviewQuestions(newList);
      localStorage.setItem(`markedForReview`, JSON.stringify(newList));
    }
  };

  const openHandler = (index: number, setIndex?: boolean) => {
    !setIndex && setItem('currentQuestionIndex', index.toString());
    const currentSection = localStorage.getItem('section');
    const newData = { ...questions };
    if (currentSection === SECTIONS.codingChallenges || currentSection === SECTIONS.databaseQuestions) {
      newData.questionNo[`${currentSection}`] = index;
      setQuestions(newData);
      // for phase 2 -- time for individual sections
      // const temp = { ...sectionTimer };
      // SectionDetails.forEach(sectionDetail => {
      //     temp[sectionDetail.key] = refs.current[sectionDetail.key].current?.state.timeDelta.total;
      // });
      // setSectionTimer(temp);
      // setDate(Date.now());
      setIsCodeEditorOpen(true);
    }

  };

  const createNewSession = async (assessmentId: string, urlToken: string | null, localstorageToken: string | null) => {
    const response = await createCompareSession(assessmentId, urlToken, localstorageToken);
    if (response.status === 200) {
      localStorage.setItem('sessionToken', response.hashedToken);
      navigate(`${RouterNames.assessment}/${id}/${response.newToken}`);
    }
  }

  const questionLength = () =>
    questions.question.multipleChoice?.length > 0
      ? SECTIONS.multipleChoice
      : questions.question.codingChallenges?.length > 0
        ? SECTIONS.codingChallenges
        : questions.question.openEnd?.length > 0
          ? SECTIONS.openEnd
          : questions.question.comprehension?.length > 0
            ? SECTIONS.comprehension
            : questions.question.databaseQuestions?.length > 0
              ? SECTIONS.databaseQuestions
              : SECTIONS.multipleChoice;

  useEffect(() => {
    const handlePopState = async () => {
      await updateResponses();
    };
    window.history.pushState(null, window.location.href);
    window.onpopstate = () => window.history.go(1);
    window.addEventListener('popstate', handlePopState);
    return () => window.removeEventListener('popstate', handlePopState);
  });

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.ctrlKey && event.key === 'c') {
        setLeftTab(false)
      }
      if ((event.ctrlKey && event.key === 'f') || (event.keyCode === 9)) {
        event.preventDefault();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // useEffect(() => {
  //   const handleBeforeunload = async () => {
  //     await updateResponses();
  //   };
  //   window.addEventListener('beforeunload', handleBeforeunload);
  //   return () => window.removeEventListener('beforeunload', handleBeforeunload);
  // });

  const fetchProfileDetails = async () => {
    const response = await getEnterpriseTheme(enterpriseId!);
    response &&
      response?.data &&
      response?.data?.data &&
      setProfileDetailSettings(response?.data?.data);
    setProfileDetails(response?.data?.data);
  };

  useEffect(() => {
    const sendMarkForReview = async () => {
      const markedForReviewData = localStorage.getItem('markedForReview');
      await updateMarkForReview(id!, markedForReviewQuestions.length !== 0 ? markedForReviewQuestions : JSON.parse(markedForReviewData!));
    }
    window.addEventListener("beforeunload", sendMarkForReview)
    return () => window.removeEventListener('beforeunload', sendMarkForReview);
  });

  useEffect(() => {
    fetchQuestions();
    enterpriseId && fetchProfileDetails();
    const markedForReviewData = localStorage.getItem('markedForReview');
    markedForReviewData && setMarkedForReviewQuestions(JSON.parse(markedForReviewData));
    id && createNewSession(id, sessionToken ? sessionToken : null, localStorage.getItem('sessionToken'));
  }, []);

  useEffect(() => {
    const index: any = localStorage.getItem('currentQuestionIndex');
    const indexValue: any = parseInt(index);
    (indexValue) >= 0 && openHandler(indexValue, true);
  }, [])

  // useEffect for changing the section status (incomplete, complete, in progress)
  useEffect(() => {
    const temp_data = { ...status };
    SectionDetails.forEach((sectionDetail) => {
      let questionsAttempted = 0;
      questions.answer[sectionDetail.key].forEach((value: string) => {
        if (value) {
          questionsAttempted = questionsAttempted + 1;
        }
      });
      if (questionsAttempted === 0)
        temp_data[sectionDetail.key] = sectionStatus.inComplete;
      else if (
        questionsAttempted < questions.question[sectionDetail.key].length
      )
        temp_data[sectionDetail.key] = sectionStatus.inProgress;
      else temp_data[sectionDetail.key] = sectionStatus.complete;
    });
    let questionsSubmitted = 0;
    questions.answer.codingChallenges.forEach(
      (answer: { code: boolean }) => {
        if (answer?.code) {
          questionsSubmitted = questionsSubmitted + 1;
        }
      }
    );
    if (questionsSubmitted === 0) {
      temp_data.codingChallenges = sectionStatus.inComplete;
    } else if (
      questionsSubmitted < questions.question.codingChallenges.length
    ) {
      temp_data.codingChallenges = sectionStatus.inProgress;
    } else {
      temp_data.codingChallenges = sectionStatus.complete;
    }

    setStatus(temp_data);

    const sectionData: any =
      getItem('section') &&
        questions.question?.[getItem('section') ?? '']?.length > 0
        ? getItem('section')
        : questionLength();

    setSection(sectionData);
  }, [questions]);

  const onBlur = async () => {
    let Count = getItem('leavingTabsCount')
      ? Number(getItem('leavingTabsCount'))
      : 0;
    Count = Count + 1;
    setLeftTab(true);
    let sec = getItem('section');
    if (sec === 'multipleChoice') {
      sec = 'mcq';
    } else if (sec === 'openEnd') {
      sec = 'openEnd';
    } else if (sec === 'comprehension') {
      sec = 'comprehension';
    } else if (sec === 'databaseQuestions') {
      sec = 'databaseQuestions';
    } else {
      sec = 'codingChallenges';
    }
    try {
      const response = await getPlagiarismDetails(id!);
      const leavingTabs = {
        section: sec,
        time: new Date().getHours() + ':' + new Date().getMinutes(),
        count: Count,
      };
      if (
        response?.data?.data[0]?.lastTimeDetected?.section !=
        leavingTabs.section ||
        response?.data?.data[0]?.lastTimeDetected?.time != leavingTabs.time
      ) {
        leavingTabs.count = 1;
        Count = 1;
      }
      setItem('leavingTabsCount', Count.toString());
      await updatePlagarismDetails(leavingTabs, id!, null, null, null);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    window.addEventListener('blur', onBlur);

    socket.on('connection', () => {
      /**/
    });
    socket.on(`FromAPI/${id}`, (data: any) => {
      fetchTimeLeft();
      setSnackbarTrigger(true);
      setPopupMessage(`${data} minutes added to your test`);
    });
    return () => {
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  useEffect(() => {
    removeItem('videoSeen');
  }, []);

  useEffect(() => {
    socket.on(`sessionToken/${id}`, (data: {newToken: string, hashedToken: string}) => {
      if (localStorage.getItem('sessionToken')) {
        if (localStorage.getItem('sessionToken') === data.hashedToken) {
          navigate(`${RouterNames.assessment}/${id}/${data.newToken}`);
        } else {
          navigate('/')
        }
      }
    });
  }, []);

  const snackbarCloseHandler = () => {
    setSnackbarTrigger(false);
  };
  const getSelectedQuestion = (key: string, id: string) =>
    questions?.answer[key]?.find((item: any) => item?.id === id) ? true : false;

  const getSelectedComprehensionQuestion = (questionSet: any[]) => {
    const key = SECTIONS.comprehension;
    for (const question of questionSet) {
      const foundQuestion = (questions as any)?.answer[key]?.find(
        (item: any) => item?.id === question.id
      );
      if (foundQuestion) {
        return true;
      }
    }
    return false;
  };
  return (
    <>
      {!isCodeEditorOpen ? (
        <MainWrapper>
          <SidenavContainer>
            <SectionTopMargin>
              <StyledSectionBox>
                <SectionsTypography>Sections</SectionsTypography>
              </StyledSectionBox>
              <StyledEachTabContainer >
                {SectionDetails.map(
                  (sectionDetail, ind) =>
                    questions.question[sectionDetail.key]?.length > 0 && (
                      <StyledEachTab key={ind}>
                        <EachSection
                          onClick={async () => {
                            if (section !== sectionDetail.key) {
                              setPopupTrigger(true);
                              setPopupSection(sectionDetail.key);
                            }
                            await updateResponses();
                          }}
                        >{sectionDetail.Icon !== DatabaseIcon ?
                          <StyledIcon
                            src={sectionDetail?.Icon}
                            alt={sectionDetail.key}
                          /> : <StyledIcon src={DatabaseIcon} alt="Database questions"></StyledIcon>}
                          <SectionName >
                            {sectionDetail.sectionName}
                          </SectionName>
                        </EachSection>
                        <StyledQuestionsDiv
                          $background={section === sectionDetail.key}
                        >
                          {questions.question[sectionDetail.key].map(
                            (question: any, index: number) => (
                              <EachQuestionDiv
                                key={index}
                                $border={
                                  questions.questionNo[sectionDetail.key] ===
                                  index && section === sectionDetail.key
                                }
                                $background={
                                  markedForReviewQuestions?.length > 0
                                    ? markedForReviewQuestions?.includes(question._id!)
                                      ? '#FBB753'
                                      : (sectionDetail.key === SECTIONS.comprehension
                                        ? getSelectedComprehensionQuestion(question.questionSet)
                                        : getSelectedQuestion(sectionDetail.key, question._id)
                                        && sectionDetail.key !== SECTIONS.codingChallenges)
                                        || (questions?.answer.codingChallenges[index]
                                          && sectionDetail.key === SECTIONS.codingChallenges)
                                        ? '#28B894'
                                        : '#D9D9D9'
                                    : (sectionDetail.key === SECTIONS.comprehension
                                      ? getSelectedComprehensionQuestion(question.questionSet)
                                      : getSelectedQuestion(sectionDetail.key, question._id)
                                      && sectionDetail.key !== SECTIONS.codingChallenges)
                                      || (questions?.answer.codingChallenges[index]
                                        && sectionDetail.key === SECTIONS.codingChallenges)
                                      ? '#70DAAF'
                                      : '#D9D9D9'
                                }
                                onClick={async () => {
                                  if (
                                    sectionDetail.key ===
                                    SECTIONS.codingChallenges ||
                                    sectionDetail.key ===
                                    SECTIONS.databaseQuestions
                                  ) {
                                    if (section !== sectionDetail.key) {
                                      setPopupTrigger(true);
                                      setPopupSection(sectionDetail.key);
                                    }
                                    if (section === sectionDetail.key) {
                                      sectionQuestions(
                                        sectionDetail.key,
                                        index
                                      );
                                      await updateResponses();
                                    }
                                  } else {
                                    if (section !== sectionDetail.key) {
                                      setPopupTrigger(true);
                                      setPopupSection(sectionDetail.key);
                                    }
                                    sectionQuestions(
                                      sectionDetail.key,
                                      index
                                    );
                                    await updateResponses();
                                  }
                                }}
                                className={
                                  ((questions.answer[sectionDetail.key][
                                    index
                                  ] &&
                                    sectionDetail.key !==
                                    SECTIONS.codingChallenges) ||
                                    (questions?.answer.codingChallenges[
                                      index
                                    ] &&
                                      sectionDetail.key ===
                                      SECTIONS.codingChallenges)) &&
                                  'lightGreen'
                                }
                              >
                                {index + 1}
                              </EachQuestionDiv>
                            )
                          )}
                        </StyledQuestionsDiv>
                      </StyledEachTab>
                    )
                )}
              </StyledEachTabContainer>
            </SectionTopMargin>
            <StyledButtonContainer>
              <StyledButton onClick={submitHandler}>Submit</StyledButton>
              <LabelGrid container item xs={10} spacing={1}>
                <Grid item xs={5}>
                  <EachLabelDiv>
                    <StyledLabel src={Review} alt='review' />
                    <StyledLabelTypography>
                      {QuestionStatus.review}
                    </StyledLabelTypography>
                  </EachLabelDiv>
                </Grid>
                <Grid item xs={5}>
                  <EachLabelDiv>
                    <StyledLabel src={Answered} alt='answered' />
                    <StyledLabelTypography>
                      {QuestionStatus.Answered}
                    </StyledLabelTypography>
                  </EachLabelDiv>
                </Grid>
                <Grid item xs={5}>
                  <EachLabelDiv>
                    <StyledLabel src={Unanswered} alt='Unanswered' />
                    <StyledLabelTypography>
                      {QuestionStatus.Unanswered}
                    </StyledLabelTypography>
                  </EachLabelDiv>
                </Grid>
              </LabelGrid>
            </StyledButtonContainer>
          </SidenavContainer>
          <Container>
            <StyledHeader>
              <NameAndTimeWrapper>
                <NameContainer>
                  <img src={NameIcon} alt='name' height={19} width={20} />
                  <Name>{candidateName}</Name>
                </NameContainer>
                <CountDownContainer>
                  <TimeLeftWrapper>
                    <img src={timeIcon} alt='time' />
                    <span>Time left :</span>
                    <CountDown
                      updateResponses={updateResponses}
                      totalTime={totalTimeTaken ?? 0}
                      timeEnd={timeEnd}
                      markedForReviewQuestions={markedForReviewQuestions}
                    />
                  </TimeLeftWrapper>
                </CountDownContainer>
                <LogoWrapper>
                  <ZecodeLogo
                    className='Logo'
                    src={
                      profileDetails?.imageURL
                        ? profileDetails?.imageURL
                        : enterpriseLogo
                          ? enterpriseLogo
                          : zecodeLogo
                    }
                    alt='zecode'
                  />
                </LogoWrapper>
              </NameAndTimeWrapper>
            </StyledHeader>
            {loader ? (
              <StyledMainContent >
                <LoaderWrapper>
                  <span>
                    <LoaderImageWrapper src={Loader} />
                  </span>
                </LoaderWrapper>
              </StyledMainContent>) : (<StyledMainContent>
                {SectionDetails.map(
                  (sectionDetail, index) =>
                    questions.question[sectionDetail.key]?.length > 0 &&
                    section === sectionDetail.key && (
                      <SectionHeader key={index}>
                        <SectionHeading ref={questionRef}>{sectionDetail.section}</SectionHeading>
                        {sectionDetail.section !== 'Coding Questions' &&
                          sectionDetail.section !== 'Database Questions' && (
                            <StyledFormControlLabel
                              control={
                                <CheckboxWrapper
                                  icon={
                                    <SquareRoundedIconWrapper fontSize='small' />
                                  }
                                  checkedIcon={
                                    <CheckBoxIconWrapper fontSize='small' />
                                  }
                                  checked={
                                    markedForReviewQuestions?.length > 0
                                      ? markedForReviewQuestions.includes(
                                        questions.question?.[section]?.[
                                          questions.questionNo?.[section]
                                        ]?._id
                                      )
                                      : false
                                  }
                                  onChange={() => {
                                    onClickCheckBoxHandler(
                                      questions.question?.[section]?.[
                                        questions.questionNo?.[section]
                                      ]?._id
                                    );
                                  }}
                                />
                              }
                              label='Mark for review'
                            />
                          )}
                      </SectionHeader>
                    )
                )}
                <div>
                  {section === SECTIONS.openEnd &&
                    questions.question.openEnd.length > 0 && (
                      <OpenEnded
                        questions={questions}
                        setQuestions={setQuestions}
                        setPopupSection={setPopupSection}
                        setPopupTrigger={setPopupTrigger}
                        updateResponses={updateResponses}
                        markedForReviewQuestions={markedForReviewQuestions}
                        onClickCheckBoxHandler={onClickCheckBoxHandler}
                        leftTab={leftTab}
                        setLeftTab={setLeftTab}
                      />
                    )}
                  {section === SECTIONS.multipleChoice &&
                    questions.question.multipleChoice.length > 0 && (
                      <MultipleChoice
                        questions={questions}
                        setQuestions={setQuestions}
                        setPopupSection={setPopupSection}
                        setPopupTrigger={setPopupTrigger}
                        updateResponses={updateResponses}
                        markedForReviewQuestions={markedForReviewQuestions}
                        onClickCheckBoxHandler={onClickCheckBoxHandler}
                      />
                    )}
                  {section === SECTIONS.codingChallenges &&
                    questions.question.codingChallenges.length > 0 && (
                      <CodingChallenges
                        refs={refs}
                        questions={questions}
                        setQuestions={setQuestions}
                        setPopupTrigger={setPopupTrigger}
                        setPopupSection={setPopupSection}
                        setIsCodeEditorOpen={setIsCodeEditorOpen}
                        markedForReviewQuestions={markedForReviewQuestions}
                        openHandler={openHandler}
                      />
                    )}
                  {section === SECTIONS.comprehension &&
                    questions?.question?.comprehension?.length > 0 && (
                      <Comprehension
                        questions={questions}
                        setQuestions={setQuestions}
                        setPopupSection={setPopupSection}
                        setPopupTrigger={setPopupTrigger}
                        updateResponses={updateResponses}
                        markedForReviewQuestions={markedForReviewQuestions}
                        onClickCheckBoxHandler={onClickCheckBoxHandler}
                        questionRef={questionRef}
                      />
                    )}
                  {section === SECTIONS.databaseQuestions &&
                    questions.question.databaseQuestions.length > 0 && (
                      <DatabaseQuestions
                        refs={refs}
                        questions={questions}
                        setQuestions={setQuestions}
                        setPopupTrigger={setPopupTrigger}
                        setPopupSection={setPopupSection}
                        setIsCodeEditorOpen={setIsCodeEditorOpen}
                        markedForReviewQuestions={markedForReviewQuestions}
                        openHandler={openHandler}
                      />
                    )}
                </div>
              </StyledMainContent>)}
          </Container>
        </MainWrapper>
      ) : (loader ?
        <CodingContainer $isDark={theme}>
          <LoaderWrapper>
            <span>
              <LoaderImageWrapper src={Loader} />
            </span>
          </LoaderWrapper>
        </CodingContainer> : (
          <CandidateCoding
            questions={questions}
            popupTrigger={popupTrigger}
            popupSection={popupSection}
            setQuestions={setQuestions}
            setPopupTrigger={setPopupTrigger}
            setPopupSection={setPopupSection}
            languages={languages}
            candidateId={id}
            currentTime={currentTime}
            remainingTime={remainingTime}
            timeLeft={timeLeft}
            timeCompletion={timeCompletion}
            updateResponses={updateResponses}
            setIsCodeEditorOpen={setIsCodeEditorOpen}
            markedForReviewQuestions={markedForReviewQuestions}
            onClickCheckBoxHandler={onClickCheckBoxHandler}
            leftTab={leftTab}
            setLeftTab={setLeftTab}
            candidateName={candidateName}
            totalTime={totalTimeTaken ?? 0}
            isDatabaseQuestion={section === SECTIONS.databaseQuestions}
            malpracticeFound={malpracticeFound}
            theme={theme}
            setTheme={setTheme}
          />
        ))}
      <PopupMessage
        questions={questions}
        popupTrigger={popupTrigger}
        popupSection={popupSection}
        id={id}
        setSection={setSection}
        setQuestions={setQuestions}
        setPopupTrigger={setPopupTrigger}
        setIsCodeEditorOpen={setIsCodeEditorOpen}
        updateResponses={updateResponses}
        markedForReviewQuestions={markedForReviewQuestions}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={4000}
        open={snackbarTrigger}
        onClose={snackbarCloseHandler}
      >
        <Alert
          variant='filled'
          onClose={snackbarCloseHandler}
          severity='info'
          sx={{ width: '100%', background: 'var(--first-color)' }}
        >
          {popupMessage}
        </Alert>
      </Snackbar>
      {enterpriseSubscriptions?.includes(AvailableModules.proctoring) && (
        <Proctor
          align={isCodeEditorOpen ? 'left' : 'right'}
          markedForReviewQuestions={markedForReviewQuestions}
          setMalpracticeFound={setMalpracticeFound}
          updateResponses={updateResponses}
        />
      )}
    </>
  );
};

export default Assessment;