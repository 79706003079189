import { client } from '../client';
import Endpoints from '../Endpoints';

export const updatePlagarismDetails = (
  leavingTabs: object | null,
  candidateId: string,
  ctrlC: number | null,
  ctrlV: number | null,
  movementDetectionCount: number | null
) => {
  return client.put(`${Endpoints.updatePlagarismDetailsUrl}`, {
    candidateId,
    ctrlC: ctrlC !== null ? ctrlC : null,
    ctrlV: ctrlV !== null ? ctrlV : null,
    leavingTabs,
    movementDetectionCount:
    movementDetectionCount !== null ? movementDetectionCount : null,
  });
};

export const getPlagiarismDetails=(candidateId: string)=>{
  return client.get(`${Endpoints.plagarismUrl}/${candidateId}`);
  };

export const saveCandidateForPlagarismDetails = (candidateId: string) => {
  return client.post(`${Endpoints.plagarismUrl}`, {
    candidateId,
  });
};

