import React, { useEffect, useState } from 'react';
import { Box, Dialog } from '@mui/material';
import styled from 'styled-components';
import { commonStrings } from '../../static/commonStrings';
import { ProctorStatus, RouterNames, StorageKeys, examSubmitted, leavingTabsCount, warningsLeft } from '../../static/constants';
import { getItem, removeItem, setItem } from '../../util/storage';
import { useNavigate, useParams } from 'react-router';
import { updatePlagarismDetails } from '../../api/requests/plagarism';
import { addSubmitTime } from '../../api/requests/assessmentTimer';
import Button from '@mui/material/Button';
import { useStylesCommon } from '../common/styles';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  okButtonContent: {
    fontWeight: 400,
    fontSize: '13px',
    marginBottom: '15px',
  },
  popupMessage: {
    width: '37%',
    background: 'white',
    height: 'fit-content',
    padding: '15px 10px'
  },
  proctorMessage: {
    fontWeight: 400,
    fontSize: '16px',
    padding:'10px 0px',
  },
  attentionMessage: {
    fontWeight: 600,
    fontSize: '20px',
    color: 'red',
  },
  warningText: {
    color: 'red',
    fontSize: '18px',
    fontWeight: 500
  },
  marginLeft: {
    marginLeft: '18px'
  }
}));

interface CameraPopupProps {
  isModal: any,
  markedForReviewQuestions: string[],
  setMalpracticeFound: any
  updateResponses: any,
  capture: any
}

const PopupMessage = styled(Box)`
    background-color: #FFFFFF;
    font-family: var(--header-font-family);
    color: var(--bodyText-color);
    border-radius: 10px;
    position: fixed;
    transform: translate(-50%, -50%);
    inset: 50% 7% 25% 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0rem 1.5rem;
    && p {
        cursor: pointer;
        padding-right: 1rem;
        text-decoration: underline;
    }
    && p:hover {
        text-decoration: none;
    }
    && h2 {
        margin-left: 0;
        color:black;
    }
    && h1 {
      margin-left:18px;
    }
`
const StyledParagraph = styled.p`
  text-decoration: none !important;
  margin: 5px 0px;
`;

const CameraPopup: React.FC<CameraPopupProps> = ({
  isModal,
  markedForReviewQuestions,
  setMalpracticeFound,
  updateResponses,
  capture,
}) => {
  const navigate = useNavigate()
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);
  const commonStyles = useStylesCommon();
  const classes = useStyles();
  const [headingText, setHeadingText] = useState<string>(getItem(StorageKeys.ProctorStatus + id) ? getItem(StorageKeys.ProctorStatus + id)! : '');
  const handleClose = () => {
    if (isModal === null) {
      setOpen(false);
    }
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const [remainingWarningCount, setRemainingWarningCount] = useState<number>(
    getItem(StorageKeys.WarningCount + id)
      ? warningsLeft - Number(getItem(StorageKeys.WarningCount + id))
      : warningsLeft
  );
  const [movementDetectionsCount, setMovementDetectionsCount] =
    useState<number>(
      getItem(StorageKeys.WarningCount + id)
        ? Number(getItem(StorageKeys.WarningCount + id))
        : 0
    );
  const [showModal, setShowModal] = useState<boolean>(false);

  //To do for later

  // const updateStatus = async () => {
  //   const updatedDetails: any = await addSubmitTime(id!, markedForReviewQuestions)
  //   removeItem(StorageKeys.WarningCount + id);
  //   removeItem(StorageKeys.ProctorStatus + id);
  //   removeItem(leavingTabsCount);
  //   removeItem(StorageKeys.candidateName);
  //   if (updatedDetails?.status === 200) {
  //     setMalpracticeFound(true);
  //     updateResponses();
  //     navigate(`${RouterNames.assessmentCompleted}/${id}?${examSubmitted}`)
  //   }
  // }
  const showPopup = () => {
    if (isModal !== null && isModal !== '' && isModal !== ProctorStatus.Camera) {
      if(movementDetectionsCount < warningsLeft)
      {
       capture();
      }
      const warningCount = getItem(StorageKeys.WarningCount + id);
      if (warningCount && parseInt(warningCount) >= warningsLeft) {
        setOpen(false);
        setShowModal(false)
      } else {
        setShowModal(true);
        if (open) {
          setRemainingWarningCount(Number(warningsLeft-1) - Number(warningCount!));
          // setItem(
          //   StorageKeys.WarningCount + id,
          //   warningCount ? (parseInt(warningCount) + 1).toString() : '1'
          // );
        }
        if (!open) {
          setRemainingWarningCount(Number(warningsLeft-1) - Number(warningCount!));
          // setItem(
          //   StorageKeys.WarningCount + id,
          //   warningCount ? (parseInt(warningCount) + 1).toString() : '1'
          // );
        }
        setTimeout(() => {
          setShowModal(false);
        }, 5000);
      }
      const updatedCount = movementDetectionsCount + 1;
      setItem(
        StorageKeys.WarningCount + id,
        warningCount ? (parseInt(warningCount) + 1).toString() : '1'
      );
      updatePlagarismDetails(null, id!, null, null, updatedCount);
      setMovementDetectionsCount(updatedCount);
    }
  };

  useEffect(() => {
    if (!open) {
      showPopup();
      if (isModal === ProctorStatus.Camera) {
        setHeadingText(commonStrings.popUp.popUpMessages.cameraWarning);
        setItem(StorageKeys.ProctorStatus + id, commonStrings.popUp.popUpMessages.cameraWarning);
      }
      else if (isModal == ProctorStatus.MultipleFaces) {
        setHeadingText(commonStrings.popUp.popUpMessages.multipleFacesWarning);
        setItem(StorageKeys.ProctorStatus + id, commonStrings.popUp.popUpMessages.multipleFacesWarning);
      }
      else if (isModal == ProctorStatus.NoFace) {
        setHeadingText(commonStrings.popUp.popUpMessages.noFaceWarning);
        setItem(StorageKeys.ProctorStatus + id, commonStrings.popUp.popUpMessages.noFaceWarning);
      }
      if (isModal !== '' && isModal !== null) {
        handleOpen();
      }
    }
  }, [isModal])

  // To do for later 

  // useEffect(() => {
  //   if (movementDetectionsCount === warningsLeft) {
  //     updateStatus();
  //   }
  // }, [movementDetectionsCount])

  return (
    <Dialog aria-labelledby='simple-dialog-title' open={open}>
      <PopupMessage className={classes.popupMessage}>
        <h1 className={classes.attentionMessage}>Attention</h1>
        <div className={classes.marginLeft}>
          <h2 className={classes.proctorMessage}>{headingText}</h2>
          {/*To do for later*/}
          {/* <StyledParagraph className={classes.warningText}>
            Warning : {movementDetectionsCount}
          </StyledParagraph> */}
          <h6 className={classes.okButtonContent}>
            {
              headingText === commonStrings.popUp.popUpMessages.noFaceWarning &&
              commonStrings.popUp.popUpMessages.proctorNote
            }
            {
              headingText === commonStrings.popUp.popUpMessages.multipleFacesWarning &&
              commonStrings.popUp.popUpMessages.proctorNoteMultipleFaces
            }
          </h6>
          <Button className={commonStyles.primaryButton} onClick={handleClose} disabled={isModal !== null}>
            OK
          </Button>
        </div>
      </PopupMessage>
    </Dialog>
  );
}

export default CameraPopup;
