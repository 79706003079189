import * as esbuild from 'esbuild-wasm';
import { LoaderValues, AssessmentType} from './constants';

export const runJsCode = async (editorValue: string) => {
    const div = document.getElementById('log') as HTMLElement;
    div.innerHTML = '';
    const errorDiv = document.getElementById('error') as HTMLElement;
    errorDiv.innerHTML = '';
    console.log = function (...args) {
      for (let i = 0; i < args.length; i++) {
        if (typeof args[i] == 'object') {
          div.innerHTML +=
            (JSON && JSON.stringify
              ? JSON.stringify(args[i], undefined, 2)
              : args[i]) + '<br />';
        } else {
          div.innerHTML += args[i];
        }
      }
    };
    if (editorValue) {
      // Compiled will get evaluted and results will be displayed
      eval(editorValue);
    }
  };

  /**
   * 
   * @param value 
   * @returns 
   */
  
  export const CompileCode = async (value: string, selectedIDE: number) => {
    const loader = LoaderValues(selectedIDE);
    const options: esbuild.TransformOptions = {
      loader: loader,
    };
    try {
      const result = await esbuild.transform(value, options);
      return result.code;
    } catch (err) {
      const div = document.getElementById('error');
      if (div && err) {
        div.innerHTML = JSON.stringify(err);
      }
    }
  };
  
  export const handleJSEditorChange = async (value: string | undefined, updatedValue: string | undefined, previousValue: string, selectedIDE: number, JsOrTs: boolean, questions: any, challengeIdx: number, setMonacoCode: any, setEditorValue: any, setQuestions: any, updateResponses: any, firstColor: string) => {
  
    // Converting packages to cdn and Import them from cdn, for example import React, then React will load 'https://cdn.skypack.dev/React
         if (value?.startsWith('import') && value?.endsWith(';')) {
      const match = value.match(/(["'])(?:(?=(\\?))\2.)*?\1/g);
      match?.forEach((element) => {
        const pkg = element.slice(1, element.length - 1);
        value = value?.replace(element, `'https://cdn.skypack.dev/${pkg}'`);
      });
      updatedValue = value;
    }
  
    previousValue = `${value}`;
  
    if (value?.includes(previousValue) && updatedValue !== undefined) {
      const match: any = value?.match(previousValue);
      value = value?.replace(match, `${updatedValue}`);
    }

    //  Value is 16 is HTML/CSS, So admational compliation is not required
    if (selectedIDE === 17) {
      const iframe = document.getElementById(
        'output'
      ) as HTMLIFrameElement;
      iframe.srcdoc = `${value}`;
    } else {
     // Compile the code , Result will be JS (browser understanble code)
      CompileCode(`${value}`, selectedIDE)
        .then((compiled) => {
          const iframe = document.getElementById(
            'output'
          ) as HTMLIFrameElement;
          const head = iframe?.contentWindow?.document.head;
          if( head ) {
            head.innerHTML = '';
          }
          const iframeScript = document.createElement('script');
          iframeScript.type = 'module';
          iframeScript.innerHTML = `${compiled}`;
          const styles = document.createElement('style')
          const scrollBarStyles = `body { overflow: auto; } 
            body::-webkit-scrollbar { width: 5px; }
            body::-webkit-scrollbar-thumb { background-color: ${firstColor}; border-radius: 50px; }
            body::-webkit-scrollbar-track { background-color: transparent; }`
          styles.innerHTML = scrollBarStyles
          // Append the Compilation result to Iframe
          head?.append(iframeScript);
          head?.append(styles);
          if (JsOrTs) {
            // User Written code will be update here
            setMonacoCode(value);
            // Complied  code snippet which needs to pass to eval
            setEditorValue(`${compiled}`);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    // Revert Imports from cdn packages, this only for display purpose
    if (selectedIDE === 1) {
      if (value?.startsWith('import') && value?.endsWith(';')) {
        const match = value.match(/(["'])(?:(?=(\\?))\2.)*?\1/g);
        match?.forEach((element) => {
          const splitElements = element.split('/');
          const replaceElement = splitElements[splitElements.length - 1];
          value = value?.replace(element, `'${replaceElement}`);
        });
      }
    }
    const questionsCopy = { ...questions };
    const { codingChallenges } = questionsCopy.answer;
    codingChallenges[challengeIdx].code = [value];
    await updateResponses();
    setQuestions(questionsCopy);
  };
  
  export const clearLogForJsOrTs = (jsOrTs: boolean) => {
    if(jsOrTs) {
      const div = document.getElementById('log') as HTMLElement;
                        if (div?.innerHTML) {
                          div.innerHTML = '';        
    }}
    if(!jsOrTs) {
      const iframe = document.getElementById(
        'output'
      ) as HTMLIFrameElement;
      iframe.srcdoc = '';
    }
    const div = document.getElementById('error') as HTMLElement;
                        if (div?.innerHTML) {
                          div.innerHTML = '';
                        }
  }
