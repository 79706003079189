import { Box, Dialog, DialogTitle } from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { memo, useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { addSubmitTime } from "../../api/requests/assessmentTimer";

// Static
import {
  examSubmitted,
  leavingTabsCount,
  RouterNames,
  SectionDetails,
  SECTIONS,
  Status,
  StorageKeys,
} from "../../static/constants";
import { commonStrings } from "../../static/commonStrings";

// Util
import { removeItem, setItem } from "../../util/storage";
import { PopupMessageProps } from "../Types/Index";
import { saveInterviewerRemarks } from "../../api/requests/interview";

const StyledPopUp = styled(Box)<{ isFeedbackSubmission?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  position: fixed;
  width: 640px;
  height: 240px;
  /* height: ${(props) => (props.isFeedbackSubmission ? "426px" : "237px")};
  width: ${(props) => (props.isFeedbackSubmission ? "600px" : "493px")}; */
  z-index: 15;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.14);
  left: 50%;
  gap: 15px;
  margin-right: -50%;
  border-radius: 10px;
  padding: 40px;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 1);
`;

const DialogTitleWrapper = styled(DialogTitle)`
  /* h2 { */
  color: var(--first-color);
  font-family: var(--bodyText-font-family) !important;
  font-size: 24px !important;
  line-height: 20px !important;
  padding: 0px !important;
  /* } */
`;

export const StyledTypography = styled(Typography)`
  && {
    font-family: var(--bodyText-font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 36px;
    color: #000821;
  }
`;

const StyledButton = styled.button`
  background: var(--first-color);
  border: 2px solid var(--first-color);
  border-radius: 8px;
  font-family: var(--bodyText-font-family);
  font-size: 15px !important;
  font-weight: 500;
  text-transform: capitalize;
  color: var(--button-text-color);
  &:hover {
    background: ${(props) => !props.disabled && "var(--first-color)"};
    border: ${(props) => !props.disabled && "2px solid var(--first-color)"};
    box-shadow: ${(props) => !props.disabled && "0 0 10px var(--first-color)"};
  }
  width: 106px;
  height: 38px;
`;

const StyledNoButton = styled.button`
  background: #ffffff;
  border: 2px solid var(--first-color);
  border-radius: 8px;
  color: var(--first-color);
  font-family: var(--bodyText-font-family);
  font-size: 15px !important;
  font-weight: 500;
  text-transform: capitalize;
  &:hover {
    border: ${(props) => !props.disabled && "2px solid var(--first-color)"};
    box-shadow: ${(props) => !props.disabled && "0 0 10px var(--first-color)"};
  }
  width: 106px;
  height: 38px;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 30px;
  margin-top: 10px;
  width: 100%;
`;

const StyledDescriptionTypography = styled(Typography)`
  && {
    font-family: var(--bodyText-font-family);
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }
`;

const PopupMessage: React.FC<PopupMessageProps> = ({
  setPopupTrigger,
  setSection,
  popupSection,
  submittedData,
  interviewId,
  popupTrigger,
  questions,
  setQuestions,
  updateResponses,
  setIsCodeEditorOpen,
  endInterviewHandler,
  leaveInterviewHandler,
  onDeleteQuestion,
  onEndCall,
  id,
  setSelectedIDE,
  setOutput,
  tempSelectIDE,
  resetCodeHandler,
  ideChangeHandler,
  codeSnippet,
  testCasesExecute,
  selectedIDE,
  isDatabaseQuestion,
  markedForReviewQuestions,
  loading,
  title,
  setRenderMonaco = () => console.log(),
}) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const isInterviewerData = searchParams.get("i");
  const [CodingValue, setCodingValue] = useState<number | null>(0);
  const [communicationValue, setCommunicationValue] = useState<number | null>(
    0
  );
  const [skillValue, setSkillValue] = useState<number | null>(0);
  const [isIcon, setIsIcon] = useState("");

  const challengeIndex = questions?.questionNo.codingChallenges;
  const databasechallengeIndex = questions?.questionNo.databaseQuestions;
  const AddSubmittedTime = async () => {
    try {
      const params = {
        testId: id!,
        markedForReview: markedForReviewQuestions!,
        manualSubmit: true,
      };
      await addSubmitTime(params);
    } catch (error) {
      console.error(error);
    }
  };

  const InterviewRemarks = async () => {
    const response = await saveInterviewerRemarks(interviewId!, {
      codeQualityAndDocumentation: CodingValue,
      communication: communicationValue,
      languageSkills: skillValue,
      Recommendation: isIcon,
    });
    setPopupTrigger(false);
    navigate(
      `${RouterNames.interviewCompleted}/${interviewId}?i=${isInterviewerData}`
    );
  };

  const changeSection = async () => {
    SectionDetails.map(
      (sectionDetail) =>
        popupSection === sectionDetail.key &&
        (setSection(sectionDetail.key), setItem("section", sectionDetail.key))
    );
    if (popupSection === `${SECTIONS.openEnd}submit`) {
      if (questions.question.comprehension.length > 0) {
        setSection(SECTIONS.comprehension);
        setItem("section", SECTIONS.comprehension);
      } else if (questions.question.databaseQuestions.length > 0) {
        setSection(SECTIONS.databaseQuestions);
        setItem("section", SECTIONS.databaseQuestions);
      }
    } else if (popupSection === `${SECTIONS.multipleChoice}submit`) {
      if (questions.question.codingChallenges.length > 0) {
        setSection(SECTIONS.codingChallenges);
        setItem("section", SECTIONS.codingChallenges);
      } else if (questions.question.openEnd.length > 0) {
        setSection(SECTIONS.openEnd);
        setItem("section", SECTIONS.openEnd);
      } else if (questions.question.comprehension.length > 0) {
        setSection(SECTIONS.comprehension);
        setItem("section", SECTIONS.comprehension);
      } else if (questions.question.databaseQuestions.length > 0) {
        setSection(SECTIONS.databaseQuestions);
        setItem("section", SECTIONS.databaseQuestions);
      }
    } else if (popupSection === `${SECTIONS.codingChallenges}submit`) {
      if (questions.question.openEnd.length > 0) {
        setSection(SECTIONS.openEnd);
        setItem("section", SECTIONS.openEnd);
      } else if (questions.question.comprehension.length > 0) {
        setSection(SECTIONS.comprehension);
        setItem("section", SECTIONS.comprehension);
      } else if (questions.question.databaseQuestions.length > 0) {
        setSection(SECTIONS.databaseQuestions);
        setItem("section", SECTIONS.databaseQuestions);
      }
    } else if (popupSection === `${SECTIONS.comprehension}submit`) {
      setSection(SECTIONS.databaseQuestions);
      setItem("section", SECTIONS.databaseQuestions);
    } else if (popupSection === Status.resetCode) {
      const newData = { ...questions };
      setOutput?.("");
      newData?.answer?.databaseQuestions[databasechallengeIndex]?.code?.splice(
        0,
        0,
        ""
      );
      if (
        typeof newData?.answer?.codingChallenges[challengeIndex]?.code ===
        "string"
      ) {
        newData.answer.codingChallenges[challengeIndex].code = codeSnippet;
      } else if (
        typeof newData?.answer?.codingChallenges[challengeIndex]?.code !==
        "string"
      ) {
        newData?.answer?.codingChallenges[challengeIndex]?.code?.splice(
          0,
          0,
          codeSnippet
        );
      }
      setQuestions(newData);
    } else if (popupSection === Status.submitCode) {
      localStorage.setItem("currentQuestionIndex", "-1");
      const newData = { ...questions };
      if (newData.answer.codingChallenges[challengeIndex]) {
        newData.answer.codingChallenges[challengeIndex].codeSubmit = true;
      }
      setQuestions(newData);
      setIsCodeEditorOpen(false);
      await updateResponses();
      if (
        (selectedIDE ?? 0) >= 5 &&
        selectedIDE !== 17 &&
        !isDatabaseQuestion
      ) {
        await testCasesExecute();
      }
    } else if (popupSection === Status.backToAssessment) {
      localStorage.setItem("currentQuestionIndex", "-1");
      setIsCodeEditorOpen(false);
      await updateResponses();
      if (
        (selectedIDE ?? 0) >= 5 &&
        selectedIDE !== 17 &&
        !isDatabaseQuestion
      ) {
        await testCasesExecute();
      }
    } else if (popupSection === Status.submitAssessment) {
      await AddSubmittedTime();
      await updateResponses();
      removeItem(StorageKeys.WarningCount + id);
      removeItem(StorageKeys.ProctorStatus + id);
      removeItem(leavingTabsCount);
      removeItem(StorageKeys.candidateName);
      navigate(`${RouterNames.assessmentCompleted}/${id}?${examSubmitted}`);
    } else if (popupSection === Status.timeCompleted) {
      removeItem(StorageKeys.WarningCount + id);
      removeItem(StorageKeys.ProctorStatus + id);
      removeItem(leavingTabsCount);
      removeItem(StorageKeys.candidateName);
      navigate(`${RouterNames.assessmentCompleted}/${id}?${examSubmitted}`);
    } else if (popupSection === Status.changeIDE) {
      setSelectedIDE!(tempSelectIDE!);
      setOutput!("");
    }
    setPopupTrigger(false);
    setRenderMonaco();
  };
  const handleClose = async () => {
    if (popupSection === Status.timeCompleted) {
      await AddSubmittedTime();
      removeItem(StorageKeys.WarningCount + id);
      removeItem(StorageKeys.ProctorStatus + id);
      removeItem(leavingTabsCount);
      navigate(`${RouterNames.assessmentCompleted}/${id}?${examSubmitted}`);
    }
    setPopupTrigger(false);
  };

  useEffect(() => {
    submittedData === "Submitted" &&
      navigate(`${RouterNames.interviewCompleted}/${interviewId}`);
  }, [submittedData]);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={popupTrigger}
    >
      {" "}
      <>
        <StyledPopUp
          isFeedbackSubmission={popupSection === Status.submitInterviewFeedback}
        >
          {SectionDetails.map((sectionDetail) => (
            <React.Fragment key={sectionDetail.section}>
              {popupSection === sectionDetail.key && (
                <>
                  <DialogTitleWrapper id="alert-dialog-title">
                    {" "}
                    {commonStrings.popUp.popupTitles.changeSection}
                  </DialogTitleWrapper>
                  <StyledTypography>
                    {`${commonStrings.popUp.popUpMessages.changeSectionText} ${sectionDetail.section}?`}
                  </StyledTypography>
                  <StyledDescriptionTypography>
                    {commonStrings.popUp.popUpMessages.changeSectionDescription}
                  </StyledDescriptionTypography>
                </>
              )}
              {popupSection === `${sectionDetail.key}submit` && (
                <>
                  <DialogTitleWrapper id="alert-dialog-title">
                    {commonStrings.popUp.popupTitles.nextSection}
                  </DialogTitleWrapper>
                  <StyledTypography>
                    {commonStrings.popUp.popUpMessages.nextSectionText}
                  </StyledTypography>
                  <StyledDescriptionTypography>
                    {commonStrings.popUp.popUpMessages.changeSectionDescription}
                  </StyledDescriptionTypography>
                </>
              )}
            </React.Fragment>
          ))}
          {(popupSection === Status.resetCode ||
            popupSection === Status.interviewResetCode) && (
            <>
              <DialogTitleWrapper>
                {commonStrings.popUp.popupTitles.resetCode}
              </DialogTitleWrapper>
              <StyledTypography>
                {commonStrings.popUp.popUpMessages.resetCodeText}
              </StyledTypography>
              <StyledDescriptionTypography>
                {commonStrings.popUp.popUpMessages.changeIDEdescription}
              </StyledDescriptionTypography>
            </>
          )}
          {popupSection === Status.submitCode && (
            <>
              <DialogTitleWrapper>
                {commonStrings.popUp.popupTitles.submitCode}
              </DialogTitleWrapper>
              <StyledTypography>
                {commonStrings.popUp.popUpMessages.submitCodeText}
              </StyledTypography>
              <StyledDescriptionTypography>
                {commonStrings.popUp.popUpMessages.submitCodeDescription}
              </StyledDescriptionTypography>
            </>
          )}
          {popupSection === Status.backToAssessment && (
            <>
              <DialogTitleWrapper>
                {commonStrings.popUp.popupTitles.goBack}
              </DialogTitleWrapper>
              <StyledTypography>
                {commonStrings.popUp.popUpMessages.backToAssessmentText}
              </StyledTypography>
              <StyledDescriptionTypography>
                {commonStrings.popUp.popUpMessages.backToAssessmentDescription}
              </StyledDescriptionTypography>
            </>
          )}
          {(popupSection === Status.changeIDE ||
            popupSection === Status.interviewChangeIDE) && (
            <>
              <DialogTitleWrapper>
                {commonStrings.popUp.popupTitles.changeIDE}
              </DialogTitleWrapper>
              <StyledTypography>
                {commonStrings.popUp.popUpMessages.changeIDEText}
              </StyledTypography>
              <StyledDescriptionTypography>
                {commonStrings.popUp.popUpMessages.changeIDEdescription}
              </StyledDescriptionTypography>
            </>
          )}
          {popupSection === Status.interviewCompleted && (
            <>
              <DialogTitleWrapper>
                {commonStrings.popUp.popupTitles.endInterview}
              </DialogTitleWrapper>
              <StyledTypography>
                {commonStrings.popUp.popUpMessages.interviewSubmissionText}
              </StyledTypography>
              <StyledDescriptionTypography>
                {
                  commonStrings.popUp.popUpMessages
                    .interviewSubmissionDescription
                }
              </StyledDescriptionTypography>
            </>
          )}
          {popupSection === Status.deleteQuestion && (
            <>
              <DialogTitleWrapper>
                {commonStrings.popUp.popupTitles.deleteQuestion}
              </DialogTitleWrapper>
              <StyledTypography>
                {commonStrings.popUp.popUpMessages.deleteQuestionText}
              </StyledTypography>
              <StyledDescriptionTypography>
                {commonStrings.popUp.popUpMessages.questionDeletionText}
              </StyledDescriptionTypography>
            </>
          )}
          {popupSection === Status.endCall && (
            <>
              <DialogTitleWrapper>
                {commonStrings.popUp.popupTitles.endCall}
              </DialogTitleWrapper>
              <StyledTypography>
                {commonStrings.popUp.popUpMessages.endCallText}
              </StyledTypography>
              <StyledDescriptionTypography>
                {commonStrings.popUp.popUpMessages.endCallMessage}
              </StyledDescriptionTypography>
            </>
          )}
          {popupSection === Status.interviewLeave && (
            <>
              <DialogTitleWrapper>
                {commonStrings.popUp.popupTitles.interviewLeave}
              </DialogTitleWrapper>
              <StyledTypography>
                {commonStrings.popUp.popUpMessages.interviewLeave}
              </StyledTypography>
              <StyledDescriptionTypography>
                {commonStrings.popUp.popUpMessages.interviewLeaveText}
              </StyledDescriptionTypography>
            </>
          )}
          {popupSection === Status.submitAssessment && (
            <>
              <DialogTitleWrapper>
                {commonStrings.popUp.popupTitles.submitExam}
              </DialogTitleWrapper>
              <StyledTypography>
                {commonStrings.popUp.popUpMessages.submitExamText}
              </StyledTypography>
              <StyledDescriptionTypography>
                {commonStrings.popUp.popUpMessages.submitExamDescription}
              </StyledDescriptionTypography>
            </>
          )}
          {popupSection === Status.timeCompleted && (
            <>
              <DialogTitleWrapper>
                {commonStrings.popUp.popupTitles.timeCompleted}
              </DialogTitleWrapper>
              <StyledTypography>
                {commonStrings.popUp.popUpMessages.allotedTimeText}
              </StyledTypography>
              <StyledDescriptionTypography>
                {commonStrings.popUp.popUpMessages.allotedTimeTextDescription}
              </StyledDescriptionTypography>
            </>
          )}
          {popupSection === Status.submitAssessment ? (
            <StyledButtonContainer>
              <StyledButton onClick={changeSection}>
                {commonStrings.popUp.buttonContent.submit}
              </StyledButton>
              <StyledNoButton onClick={handleClose}>
                {commonStrings.popUp.buttonContent.cancel}
              </StyledNoButton>
            </StyledButtonContainer>
          ) : (
            <StyledButtonContainer>
              {popupSection === Status.timeCompleted ? (
                <StyledButton onClick={changeSection}>
                  {commonStrings.popUp.buttonContent.submit}
                </StyledButton>
              ) : (
                <StyledButton
                  disabled={loading}
                  onClick={() => {
                    if (popupSection === Status.interviewChangeIDE) {
                      ideChangeHandler && ideChangeHandler();
                    } else if (popupSection === Status.interviewResetCode) {
                      resetCodeHandler && resetCodeHandler();
                    } else if (popupSection === Status.interviewCompleted) {
                      endInterviewHandler && endInterviewHandler();
                    } else if (
                      popupSection === Status.submitInterviewFeedback
                    ) {
                      InterviewRemarks && InterviewRemarks();
                    } else if (popupSection === Status.deleteQuestion) {
                      onDeleteQuestion && onDeleteQuestion();
                    } else if (popupSection === Status.endCall) {
                      onEndCall && onEndCall();
                    } else if (popupSection === Status.interviewLeave) {
                      leaveInterviewHandler && leaveInterviewHandler();
                    } else {
                      changeSection();
                    }
                  }}
                >
                  {commonStrings.popUp.buttonContent.yes}
                </StyledButton>
              )}
              {popupSection !== Status.timeCompleted && (
                <StyledNoButton disabled={loading} onClick={handleClose}>
                  {commonStrings.popUp.buttonContent.no}
                </StyledNoButton>
              )}
            </StyledButtonContainer>
          )}
        </StyledPopUp>
      </>
    </Dialog>
  );
};
export default memo(PopupMessage);
