import React, { FC, useState } from 'react';
import ReactMde from 'react-mde';
import Showdown from 'showdown';
import { MarkDownEditorProps } from '../Types/Index';
import styled from 'styled-components';

const ReactMarkdownEditor = styled.div`
  width: 100%;
  
  .react-mde {
    border: 1px solid #c8ccd0;
    border-radius: 10px;
    white-space: pre-line;
  }

  .mde-header .mde-tabs button.selected {
    border: 1px solid #c8ccd0;
    border-radius: 10px;
    background:#F3F3F3;
}

  .mde-header {
    border-radius: 10px 10px 0 0;
  }

  && img {
    width: 422px;
    border: 1px solid black;
  }

  && p {
    font-size:14px;
  }

  && li{
    font-size:14px;
  }

  && ul {
    margin-bottom:0px;
  }

  && h3 {
    font-size:16px;
    margin-top: 0px;
  }

.mde-preview .mde-preview-content pre>code {
    white-space: pre-line;
  }

  .mde-preview .mde-preview-content {
    height: 210px;
    padding: 10px;
    overflow: auto;
  }
`;

const MarkDownEditor: FC<MarkDownEditorProps> = ({ value, onChange }) => {
  const [selectedTab, setSelectedTab] = useState<'write' | 'preview'>('write');

  const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true,
  });

  return (
    <div>
    <ReactMarkdownEditor>
    <ReactMde
      value={value}
      onChange={onChange}
      selectedTab={selectedTab}
      onTabChange={setSelectedTab}
      generateMarkdownPreview={(markdown) =>
        Promise.resolve(converter.makeHtml(markdown))
      }
    />
    </ReactMarkdownEditor>
    </div>
  );
};
export default MarkDownEditor;
