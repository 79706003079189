import React, { FC } from 'react';
import MonacoEditor from '@monaco-editor/react';

interface MonacoComponentProps {
    language?: string;
    value?: string;
    onChange?: (val: any) => void;
    theme?: any;
    path?: string;
    key?: string;
}

const MonacoComponent: FC<MonacoComponentProps> = ({
    language = '',
    value = '',
    onChange = (val: any) => console.log(),
    theme,
    path = '',
    key = '',
}) => {

    return (
        <div key={key} style={{ height: '100%' }}>
            <MonacoEditor
                language={language}
                defaultValue={value}
                onChange={onChange}
                theme={theme ? 'light' : 'vs-dark'}
                path={path}
                options={{ minimap: { enabled: false }, contextmenu: false }}
            />
        </div>
    );
};

export default MonacoComponent;
