import React, { useEffect } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import CandidateRoutes from './components/candidateRoutes';
import './css/candidate.css';
import styled from 'styled-components';
import { RouterNames } from './static/constants';
const AppContainer = styled.div`
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

const App = () => {
  return (
    <AppContainer>
      <div className='light'>
        <Router>
          <CandidateRoutes />
        </Router>
      </div>
    </AppContainer>
  );
};

export default App;
