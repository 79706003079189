import React from 'react';
import {
    SandpackProvider,
    SandpackPreview,
    SandpackCodeEditor,
    SandpackStack,
    SandpackLayout,
    SandpackFileExplorer,
    useSandpack,
} from '@codesandbox/sandpack-react';
import styled from 'styled-components';
import { sectionStatus } from '../../static/constants';
import { nightOwl } from '@codesandbox/sandpack-themes';




const SandpackCodeEditorWrapper = styled(SandpackCodeEditor)`
   .sp-c-gGbYbQ::-webkit-scrollbar-thumb {
     background-color: var(--first-color);
      border-radius: 50px;
    }
    .sp-c-gGbYbQ::-webkit-scrollbar {
      width: 2px;
      height: 5px;
    }
    .sp-c-gGbYbQ::-webkit-scrollbar-track {
      background-color: #e4e4e4;
      border-radius: 50px;
      margin-left: 10px;
    }
`

const SandpackFileExplorerWrapper = styled(SandpackFileExplorer)`
  & div:nth-child(2){
    padding-left: 18px;
  }
`;

const CodeViewer = (props: any) => {
    const { sandpack } = useSandpack();
    const { files, activeFile } = sandpack;
    props.setCodeFiles(files);
    props.setActiveFiles(activeFile)
    return <pre></pre>;
}

interface SandpackComponentProps {
    template?: string | undefined,
    activeFile?: any,
    intitalFileState?: any,
    codeFiles?: any,
    handleUpdation?: any,
    setActiveFile?: any,
    typingAccessAllowed?: boolean,
    status?: string
}

const SandpackComponent: React.FC<SandpackComponentProps> = ({
    template,
    activeFile,
    intitalFileState,
    codeFiles,
    handleUpdation,
    setActiveFile,
    typingAccessAllowed,
    status
}) => {
    return (
        <SandpackProvider template={template as any} options={{ activeFile: activeFile }} theme={nightOwl} files={intitalFileState} >
            <SandpackStack>
                <SandpackLayout >
                    <SandpackFileExplorerWrapper style={{ height: '590px' }} />
                    <CodeViewer codeFiles={codeFiles} setCodeFiles={handleUpdation} setActiveFiles={setActiveFile} />
                    <SandpackCodeEditorWrapper readOnly={(!typingAccessAllowed && status !== sectionStatus.submitted)} style={{ height: '590px' }} showTabs showLineNumbers={true} showInlineErrors wrapContent closableTabs key={codeFiles} />
                    <SandpackPreview style={{ height: '590px', width: '2000px' }} showOpenInCodeSandbox={false} />
                </SandpackLayout>
            </SandpackStack>
        </SandpackProvider>
    )
}

export default SandpackComponent;
