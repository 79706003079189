import React, { useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import SideNav from '../common/SideNav';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import CheckBoxOutlineBlankTwoToneIcon from '@mui/icons-material/CheckBoxOutlineBlankTwoTone';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

// Static
import { getItem, removeItem, setItem } from '../../util/storage';
import {
  RouterNames,
  SectionDetails,
  sectionStatus,
  Languages,
  SECTIONS,
  zecodeLogo,
  StorageKeys,
  leavingTabsCount,
  examSubmitted,
  contactUsUrl,
  guidelinesVariables,
  candidateStatus,
} from '../../static/constants';
import { convertTime } from '../../util/convertTime';
import { LanguageType } from './assessment';
import { Box, Checkbox, Dialog, Grid, Typography } from '@mui/material';
import { ThemeConfigurationType } from '../Types/Index';
import { commonStrings } from '../../static/commonStrings';
import Tooltip from '@material-ui/core/Tooltip';


//apiRequests
import {
  getCandidateDetails,
  updateAssessmentStatus,
} from '../../api/requests/candidate';
import {
  setTime,
} from '../../api/requests/assessmentTimer';
import { saveCandidateForPlagarismDetails } from '../../api/requests/plagarism';
import { setProfileDetailSettings } from '../../util/util';
import { getEnterpriseById } from '../../api/requests/enterprise';
import { getEnterpriseTheme } from '../../api/requests/themeConfiguration';
import { getNameFromEmail } from '../common/getNameFromEmail';
import { useStylesCommon } from '../common/styles';
import rehypeKatex from 'rehype-katex';
import gfm from 'remark-gfm';
import math from 'remark-math';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import ReactPlayer from 'react-player';
import useDisableInspect from '../common/useDisableInspect';
import PlayCircleOutline from '@mui/icons-material/PlayCircleOutline';

//Icons
const NameIcon = '/images/NameIcon.svg';
const LevelIcon = '/images/LevelIcon.svg';
const RoleIcon = '/images/RoleIcon.svg';
const SkillSet = '/images/SkillSet.svg';
const TimeIcon = '/images/TimeIcon.svg';

//Videos
const assessmentVideo = '/videos/zecodeAssessmentVideo.mp4';


const Container = styled(Box)`
  display: flex;
  width: calc(100vw - 261px);
  flex-direction: column;
  min-height: 100vh;
  height: 100vh;
  ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    width: 0px;
  }
  ::-webkit-scrollbar {
    width: 0px;
  }
`;

const MainContentWrapper = styled(Box)`
  overflow: auto;
  max-height: calc(100vh - 62px);
  ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
  }
  ::-webkit-scrollbar {
    width: 0.2vw;
  }
`;

const MainContent = styled.div`
  padding: 10px 8.8% 10px 5%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
`;

const StyledHeader = styled(Box)`
  && {
    display: flex;
    position: sticky;
    height: 62px;
    top: 0;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.17);
    z-index: 1;
    padding-left: 5%;
  }
`;

const Heading = styled(Typography)`
  && {
    font-family: var(--bodyText-font-family);
    font-size: 28px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;
    color: black;
  }
`;

const ScrollableContainer = styled(Box)`
  max-height: fit-content;
  overflow: auto;
  padding: 17px 29px 17px 42px;
  ::-webkit-scrollbar-thumb {
    background-color: var(--first-color);
    border-radius: 50px;
  }
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background-color: #f7f7f7;
    border-radius: 50px;
    margin: 10px 0px;
  }
`;
const ScrollableContainerForPopup = styled(Box)<{dialogData?:string}>`
  display: flex;
  flex-direction:column;
  max-height: fit-content;
  padding:${(props)=>props?.dialogData === guidelinesVariables.clickHere && '38px 44px 17px 45px'};
`;

const GuidelinesText = styled(Box)`
  && {
    font-family: var(--bodyText-font-family);
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: justified;
    display: list-item;
    color: #494949;
  }
  &::marker {
    font-size: 15px;
  }
`;

const ReactMarkdownWrapper = styled(Box)`
  && {
    font-family: var(--bodyText-font-family);
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: justified;
    color: #494949;
  }
  &::marker {
    font-size: 15px;
  }
  h3 {
    font-size: 16px;
    font-weight: 600;
    margin: 10px 0px;
    }
`;

const GuidelinesWrapper = styled(Box)`
&& {
  font-family: var(--bodyText-font-family);
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: #494949;
  text-align-last:left;
  text-align:justify;
}
&::marker {
  font-size: 15px;
}
h3 {
  font-size: 14px;
  font-weight: 600;
  margin: 8px 0px;
  }
h2 {
  font-weight: 600;
  font-size: 1.6em;
  margin: 10px 0px 30px 0px;
}
h4 {
  font-weight: 400;
  font-size: 12px;
  margin-top: 20px;
}
h1{
  font-weight: 600;
  font-size: 1.6em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px 30px 0px;
}
`

const StyledQuestionContainer = styled.div`
  height: fit-content;
  max-height: 271px;
  width: 100%;
  left: 355px;
  top: 578px;
  border-radius: 10px;
  border: 0.5px solid #0d2225;
  margin-top: 10px;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  && .grid-container {
    max-width: 100%;
    align-items: flex-start;
  }
`;

const OptionsHeading = styled(Typography)`
  && {
    font-family: var(--bodyText-font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--first-color);
    padding-left: 42px;
    margin-top: 10px;
    flex: 33.3%;
    display: flex;
    align-items: center;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  && {
    .MuiTypography-root {
      font-family: var(--bodyText-font-family);
      font-weight: 400;
      font-size: 14px;
      color: #000821;
    }
  }
`;

const StyledRadio = styled(Radio)`
  && {
    &.Mui-checked {
      color: var(--first-color);
    }
  }
`;

const StyledFormControl = styled(FormControl)`
  && {
    margin: 10px 0px 0px 42px;
  }
`;
const OptionDetailingText = styled(Typography)`
  && {
    font-family: var(--bodyText-font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #000821;
  }
`;

const StyledButton = styled.button<{isTermsChecked:boolean,isGuidelinesChecked:boolean, isVideoSeen?:boolean}>`
  &:hover {
     background-color: ${(props)=> (props.isTermsChecked && props.isGuidelinesChecked && props.isVideoSeen) ? 'var(--first-color)' : 'grey ! important'};
     box-shadow: ${(props)=> (props.isTermsChecked && props.isGuidelinesChecked &&  props.isVideoSeen) ? '0 0 10px var(--first-color)' : '0 0 10px white !important'};
     color: ${(props)=> (props.isTermsChecked && props.isGuidelinesChecked && props.isVideoSeen) ? 'red' : '#666666 ! important'};
   };
  height: 5%;
  width: 20%;
  text-transform: capitalize;
  margin-top: 14px;
  font-size:14px;
  background-color: ${(props)=> (props.isTermsChecked && props.isGuidelinesChecked &&  props.isVideoSeen) ? 'var(--first-color)':'grey !important'};
  cursor: ${(props)=> (props.isTermsChecked && props.isGuidelinesChecked &&  props.isVideoSeen) ? 'pointer':'not-allowed'};

`;

const EachItem = styled.div`
  && {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 20px;
  }
`;
const GuidelineBox = styled.div`
  padding: 0px 19px 23px 0px;
  margin-top: 7px;
  background: #ffffff;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  height: 100%;
`;

const MainWrapper = styled(Box)`
  display: flex;
`;

const ZecodeLogo = styled.img`
  margin-left:auto;
  margin-right:60px;
  padding: 20px;
  width: 65px;
  height: 87px;
  border-radius: 50%;
`;

const CheckBoxOutlineBlankTwoToneIconWrapper = styled(
  CheckBoxOutlineBlankTwoToneIcon
)`
  color: var(--first-color);
`;

const CheckBoxIconWrapper = styled(CheckBoxIcon)`
    color: var(--first-color);
`;

const CheckboxWrapper = styled.div`
    display: flex;
    flex-direction:column;
    margin-left:10px;
`
const CheckboxAndTextWrapper = styled.div`
  display:flex;
  align-items:baseline;
`
const TextWrapper = styled.span`
    font-size:14px;
    font-family: var(--bodyText-font-family);
    line-height:0;
    padding:0px;
    margin:0px;
`;

const LinkWrapper = styled.span`
    text-decoration:underline;
    cursor:pointer;
    color:blue;
`;

const DialogWrapper = styled(Dialog)<{dialogData?:string}>`
  &&{
    margin-top: 40px;
    .MuiDialog-paperWidthSm {
      border-radius: 10px;
    }
    .MuiPaper-root {
      background-color:${(props)=>props.dialogData === guidelinesVariables.clickHere ? '#ffffff' : 'transparent'};
      box-shadow:none;
      max-width: ${(props)=>props.dialogData === guidelinesVariables.clickHere ? '40%' : '100%'};
      overflow: auto;
      ::-webkit-scrollbar-thumb {
        background-color: var(--first-color);
        border-radius: 50px;
      }
      ::-webkit-scrollbar {
        width: 4px;
      }
      ::-webkit-scrollbar-track {
        background-color: #f7f7f7;
        border-radius: 50px;
        margin: 10px 0px;
      }
   }
  }
`;

const GuidelinesPopUp = styled(Box)<{$dialogData?:string}>`
    display:flex;
    flex-direction:column;
    max-height:${(props)=>props?.$dialogData === guidelinesVariables.clickHere ? '68%' : '100%'};
    max-width:100%;
`;

const CloseIconWrapper = styled(Box)<{dialogData:string}>`
  width:40%;
  display:flex;
  justify-content:flex-end;
  padding:${(props)=>props.dialogData === guidelinesVariables.clickHere && '10px 10px 8px 0px'};
  cursor:pointer;
  position:${(props)=>props.dialogData === guidelinesVariables.clickHere ? 'fixed' : 'absolute'};
  top:${(props)=>props.dialogData !== guidelinesVariables.clickHere && '7px'};
  right:${(props)=>props.dialogData !== guidelinesVariables.clickHere &&  '5px'};
`;


const ContactUsWrapper = styled.a`
    color:blue;
`;

const VideoPlayerWrapper = styled(Box)`
   display: flex;
   align-items: center;
   justify-content: center;
   position:relative;
`;

const StyledVideoContainer = styled.div`
   display: flex;
   && video {
    border-radius: 8px;
  } 
`;

const VideoText = styled(Box)`
    color:#000000;
    font-size:14px;
    font-family:var(--bodyText-font-family);
    margin-top:20px;
    width:100%;
    margin-left:48px;
    display: flex;
    align-items: center;
`;

const Wrapper = styled.div`
`;

const WatchVideotext = styled.p`
   font-size:12px;
   color:var(--button-text-color);
   font-family:var(--bodyText-font-family);
   font-weight:500;
`;

const WatchVideoButton = styled.button`
  padding: 1px 10px !important;
  background-color:var(--first-color);
  margin-left:5px;
`;

const WatchVideoTextWrapper = styled(Box)`
   display:flex;
   align-items:center;
   gap:5px;
`;


const AssessmentDetails = () => {
  const { id } = useParams();
  const commonClasses = useStylesCommon();
  const enterpriseId = getItem('enterpriseId');
  const navigate = useNavigate();
  const [candidateName, setCandidateName] = useState<string>();
  const defaultGuideLines =
    commonStrings.assessment.assessmentStartPage.testGuidelines;
  const [userData, setUserData] = useState<any>();
  const [languages, setLanguages] = useState<Array<LanguageType>>([]);
  const [section, setSection] = useState(
    getItem('section') ? getItem('section') : SECTIONS.codingChallenges
  );
  const [isGuidelinesChecked,setIsGuidelinesChecked] = useState<boolean>();
  const [isTermsChecked,setIsTermsChecked] = useState<boolean>();
  const [isGuidelinesPopupOpened,setIsGuidelinesPopupOpened] = useState<boolean>(false);
  const [totalQuestions, setTotalQuestions] = useState<any>({
    openEnd: 0,
    multipleChoice: 0,
    codingChallenges: 0,
    databaseQuestions: 0,
    comprehension: 0,
  });
  const [guideLinesData, setGuideLinesData] = useState<string>();
  const [profileDetails, setProfileDetails] =
    useState<ThemeConfigurationType>();
  const [enterpriseLogo, setEnterpriselogo] = useState('');
  const [playedSeconds, setPlayedSeconds] = useState<any>(0);
  const [dialogData, setDialogData] = useState<string>(guidelinesVariables.clickHere);
  const reactPlayerRef = useRef<any>(null);
  const videoSeen = getItem('videoSeen');
  const isVideoSeen = videoSeen ==='true';
  const handleDisable = useDisableInspect();
  
  handleDisable();
  // To do for later
  const totalSections = () => {
    let count = 0;
    if (totalQuestions?.codingChallenges > 0) {
      count = count + 1;
    }
    if (totalQuestions?.multipleChoice > 0) {
      count = count + 1;
    }
    if (totalQuestions?.openEnd > 0) {
      count = count + 1;
    }
    if (totalQuestions?.databaseQuestions > 0) {
      count = count + 1;
    }
    if (totalQuestions?.comprehension > 0) {
      count = count + 1;
    }
    return count;
  };
  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setItem('section', (event.target as HTMLInputElement).value);
    setSection((event.target as HTMLInputElement).value);
  };

  let guideLinesArray = defaultGuideLines;
  if (guideLinesData) {
    guideLinesArray = guideLinesData.split('\n');
  }

  if (guideLinesArray.length > 0) {
    if (
      guideLinesArray[guideLinesArray.length - 1] === '' ||
      guideLinesArray[guideLinesArray.length - 1] === '\n'
    ) {
      guideLinesArray.pop();
    }
  }

  const updateStatus = async () => {
    try {
      const params ={isGuidelinesChecked,isTermsChecked,inProgress:true}
      const response = await updateAssessmentStatus(params,id!);
      return await response?.status === "success";
    } catch (error) {
      console.error(error);
    }
  };

  const saveTimer = async () => {
    try {
      const timeDuration = userData?.testDuration?.split(':') ?? ['00', '00'];
      const testDurationMinutes =
        parseInt(timeDuration[0]) * 60 + parseInt(timeDuration[1]);
      const response = await setTime(id!, 0, convertTime(testDurationMinutes));
      return await response?.data?.message === "Success"; 
    } catch (error) {
      console.error(error);
    }
  };

  const onClickHandler = async () => { 
    if (isGuidelinesChecked && isTermsChecked)
   {
    try {
      const timerResponse = await saveTimer();
      const plagarismDetailsResponse = await saveCandidateForPlagarismDetails(id!);
      const statusResponse = await updateStatus();
      localStorage.removeItem('markedForReview');
      setItem('pasteCount', '0');
       timerResponse && 
       plagarismDetailsResponse?.data?.status === "success" &&
       statusResponse &&
      navigate(`${RouterNames.assessment}/${id}/${null}`);
    } catch (error) {
      console.error(error);
    }
  }
  };

  const onClickHereHandler = (value:string) => {
    setDialogData(value);
    setIsGuidelinesPopupOpened(true);
  };

  const fetchCandidateDetails = async () => {
    const response = await getCandidateDetails(id!);
    setCandidateName(getNameFromEmail(response?.data?.data[0]?.email));
    setUserData(response?.data?.data[0]?.assessmentDetails);
    setGuideLinesData(response?.data?.data[0]?.assessmentDetails?.guideLines);
    const newData = { ...totalQuestions };
    if (response?.data?.data[0]?.status === candidateStatus.inProgress) {
      navigate(`${RouterNames.assessment}/${id}`);
    } else if (response?.data?.data[0]?.status === sectionStatus.complete) {
      removeItem(StorageKeys.WarningCount+id);
      removeItem(StorageKeys.ProctorStatus+id);
      removeItem(leavingTabsCount);
      removeItem(StorageKeys.candidateName);
      navigate(`${RouterNames.assessmentCompleted}/${id}?${examSubmitted}`);
    }
    newData.codingChallenges = response?.data?.data[0]?.assessmentDetails?.questions?.length;
    newData.openEnd = response?.data?.data[0]?.assessmentDetails?.openEndQuestions?.length;
    newData.multipleChoice = response?.data?.data[0]?.assessmentDetails?.multipleChoiceQuestions?.length;
    newData.databaseQuestions = response?.data?.data[0]?.assessmentDetails?.databaseQuestions?.length;
    newData.comprehension =
      response?.data?.data[0]?.assessmentDetails?.comprehensionQuestions?.length;
    SectionDetails.map(
      (section) => newData[section.key] > 0 && setItem('section', section.key)
    );
    setTotalQuestions(newData);
    const filteredData: LanguageType[] = [];
    response?.data?.data[0]?.assessmentDetails?.languages.forEach((item: number) => {
      filteredData.push(Languages.find((ide) => ide.id === item)!);
    });
    setLanguages(filteredData);

    const enterprise = await getEnterpriseById(
      response?.data?.data[0].assessmentDetails?.enterpriseId
    );
    setEnterpriselogo(enterprise?.data?.data?.imageURL);
    setItem('enterpriseId', enterprise?.data?.data?._id);     
    const enterpriseID = response?.data?.data[0].assessmentDetails?.enterpriseId;
    const themeResponse = enterpriseID && await getEnterpriseTheme(enterpriseID);
    themeResponse && themeResponse?.data &&
    themeResponse?.data?.data &&
        setProfileDetailSettings(themeResponse?.data?.data);
      setProfileDetails(themeResponse?.data?.data);
  };

  const handleCloseDialog = (value:string) => {
    value !== guidelinesVariables.clickHere && setPlayedSeconds(Math.floor(reactPlayerRef.current.getCurrentTime()));
    setIsGuidelinesPopupOpened(false);
  };

  const readyPlayer = () => {
    (playedSeconds !== 0) && reactPlayerRef.current?.seekTo(playedSeconds, 'seconds');
    setPlayedSeconds(0);
  }

  const videoEnded = () => {
    setItem('videoSeen', 'true');
  };
 

  useEffect(() => {
    fetchCandidateDetails();
  }, []);

  const NavbarContent = [
    {
      name: 'Candidate Name',
      icon: NameIcon,
      width: 20,
      height: 19,
      value: candidateName,
    },
    {
      name: 'Time limit',
      icon: TimeIcon,
      width: 20,
      height: 19,
      value: userData?.testDuration,
    },
    {
      name: 'Role',
      icon: RoleIcon,
      width: 20,
      height: 19,
      value: userData?.role,
    },
    // {
    //   name: 'Level',
    //   icon: LevelIcon,
    //   width: 20,
    //   height: 19,
    //   value: userData?.experienceLevel,
    // },
    {
      name: 'Skill set',
      icon: SkillSet,
      width: 20,
      height: 19,
      value: languages.map((item) => item?.data).join(', '),
    },
  ];

    const renderGuideline = (guideline:string) => {
      const guidelineWithLink = guideline.replace(/\$\$([^$]+)\$\$/g,(contactUsUrl!));  
      const ContactUsLink = () => {
        return (
          <ContactUsWrapper href={contactUsUrl} target="_blank" rel="noopener noreferrer">
            contact us
          </ContactUsWrapper>
        );
      };
      
      return (
        <ReactMarkdown remarkPlugins={[gfm, math]} rehypePlugins={[rehypeKatex]} components={{ a: ContactUsLink }}>
          {guidelineWithLink} 
        </ReactMarkdown>
      );
    };

  return (
    <MainWrapper>
      <SideNav content={NavbarContent} />
      <Container>
        <StyledHeader>
          <ZecodeLogo
            className='Logo'
            src={
              profileDetails?.imageURL
                ? profileDetails?.imageURL
                : enterpriseLogo
                ? enterpriseLogo
                : zecodeLogo
            }
            alt='zecode'
          />
        </StyledHeader>
        <MainContentWrapper>
          <MainContent>
            <Heading>
              {
                commonStrings.assessment.assessmentStartPage
                  .testGuidelinesHeading
              }
            </Heading>
            <GuidelineBox>
              <ScrollableContainer>
                {guideLinesArray?.map((guideline: string, index: number) => (
                  <ReactMarkdownWrapper key={index}>
                    <ReactMarkdown remarkPlugins={[gfm, math]} rehypePlugins={[rehypeKatex]}>{guideline}</ReactMarkdown>
                  </ReactMarkdownWrapper>
                ))}
              </ScrollableContainer>
              <CheckboxWrapper>
              <CheckboxAndTextWrapper>
                <Wrapper>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankTwoToneIconWrapper fontSize='small' />}
                    checkedIcon={<CheckBoxIconWrapper fontSize='small' />}
                    onChange={(val: any) => {
                      setIsGuidelinesChecked(val.target.checked);
                    }}
                    />
                  </Wrapper>
                  <Wrapper>
                    <TextWrapper>{commonStrings.assessment.assessmentStartPage.guidelinesAcknowledge}</TextWrapper>
                  </Wrapper>
                </CheckboxAndTextWrapper>
                <CheckboxAndTextWrapper>
                  <Wrapper>
                  <Checkbox
                  icon={
                    <CheckBoxOutlineBlankTwoToneIconWrapper fontSize='small' />
                  }
                  checkedIcon={
                    <CheckBoxIconWrapper fontSize='small' />
                  }
                  onChange={(val: any) => {
                    setIsTermsChecked(val.target.checked);
                  }}
                />
                </Wrapper>
                <Wrapper>
                <TextWrapper>{commonStrings.assessment.assessmentStartPage.guidelinesForImageCapture}
                   <LinkWrapper onClick={() => onClickHereHandler(guidelinesVariables.clickHere)}> {guidelinesVariables.clickHere} </LinkWrapper>{commonStrings.assessment.assessmentStartPage.guidelinesForImageCaptureSubText}
                  </TextWrapper>
                  </Wrapper>
                </CheckboxAndTextWrapper>
              </CheckboxWrapper>
              <VideoText>
               {guidelinesVariables.zecodeVideoText}
                 <WatchVideoButton
                     onClick={()=>onClickHereHandler(guidelinesVariables.watchVideo)}> 
                     <WatchVideoTextWrapper>
                        {window.getComputedStyle(document.documentElement).getPropertyValue('--button-text-color') === '#000000' ? 
                        <PlayCircleOutlineIcon style={{width:'16px', fill:'#000000'}}/> : 
                        <PlayCircleFilledWhiteOutlinedIcon style={{width:'16px'}}/>}
                        <WatchVideotext>{guidelinesVariables.watchVideo}</WatchVideotext> 
                    </WatchVideoTextWrapper>
                 </WatchVideoButton>
              </VideoText>
              
            </GuidelineBox>
            <StyledQuestionContainer>
              <OptionsHeading>
                {
                  commonStrings.assessment.assessmentStartPage
                    .sectionsSubHeading
                }
              </OptionsHeading>
              <Grid
                container
                item
                xs={8}
                justifyContent='flex-start'
                className='grid-container'
              >
                <Grid item xs={6}>
                  <StyledFormControl>
                    <RadioGroup
                      defaultValue='openEnd'
                      aria-labelledby='demo-radio-buttons-group-label'
                      name='radio-buttons-group'
                      value={section}
                      onChange={handleChange}
                    >
                      {commonStrings.assessment.assessmentStartPage?.sectionsList?.map(
                        ({ label, value, apikey }) => {
                          const userPoint: any = { ...userData };
                          return (
                            userPoint?.[apikey]?.length > 0 && (
                              <StyledFormControlLabel
                                key={label}
                                value={value}
                                control={
                                  userPoint?.questions +
                                    userPoint?.multipleChoiceQuestions +
                                    userPoint?.openEndQuestions +
                                    userPoint?.comprehensionQuestions +
                                    userPoint?.databaseQuestions ===
                                  1 ? (
                                    <StyledRadio checked />
                                  ) : (
                                    <StyledRadio />
                                  )
                                }
                                label={label}
                              />
                            )
                          );
                        }
                      )}
                    </RadioGroup>
                  </StyledFormControl>
                </Grid>
                <Grid item xs={3}>
                  <EachItem>
                    {userData?.multipleChoiceQuestions && userData?.multipleChoiceQuestions?.length > 0 ? (
                      <OptionDetailingText>
                        {userData?.multipleChoiceQuestions?.length} {userData.multipleChoiceQuestions.length < 2 ? 'Question' : 'Questions'}
                      </OptionDetailingText>
                    ) : (
                      ''
                    )}
                    {userData?.questions &&
                    userData?.questions?.length > 0 ? (
                      <OptionDetailingText>
                        {userData?.questions?.length} {userData.questions.length < 2 ? 'Question' : 'Questions'}
                      </OptionDetailingText>
                    ) : (
                      ''
                    )}
                    {userData?.openEndQuestions &&
                    userData?.openEndQuestions?.length > 0 ? (
                      <OptionDetailingText>
                        {userData?.openEndQuestions?.length} {userData.openEndQuestions.length < 2 ? 'Question' : 'Questions'}
                      </OptionDetailingText>
                    ) : (
                      ''
                    )}
                    {userData?.comprehensionQuestions &&
                    userData?.comprehensionQuestions?.length > 0 ? (
                      <OptionDetailingText>
                        {userData?.comprehensionQuestions?.length} {userData.comprehensionQuestions.length < 2 ? 'Question' : 'Questions'}
                      </OptionDetailingText>
                    ) : (
                      ''
                    )}
                    {userData?.databaseQuestions &&
                    userData?.databaseQuestions?.length > 0 ? (
                      <OptionDetailingText>
                        {userData?.databaseQuestions?.length} {userData.databaseQuestions.length < 2 ? 'Question' : 'Questions'}
                      </OptionDetailingText>
                    ) : (
                      ''
                    )}
                  </EachItem>
                </Grid>
                <Grid item xs={3}>
                  <EachItem>
                    {userData?.multipleChoiceQuestions && userData?.multipleChoiceQuestions.length > 0 ? (
                      <OptionDetailingText>
                        {userData?.weightage?.multipleChoice}% weightage
                      </OptionDetailingText>
                    ) : (
                      ''
                    )}
                    {userData?.questions &&
                    userData?.questions.length > 0 ? (
                      <OptionDetailingText>
                        {userData?.weightage?.codingChallenges}% weightage
                      </OptionDetailingText>
                    ) : (
                      ''
                    )}
                    {userData?.openEndQuestions &&
                    userData?.openEndQuestions.length > 0 ? (
                      <OptionDetailingText>
                        {userData?.weightage?.openEnd}% weightage
                      </OptionDetailingText>
                    ) : (
                      ''
                    )}
                    {userData?.comprehensionQuestions &&
                    userData?.comprehensionQuestions.length > 0 ? (
                      <OptionDetailingText>
                        {userData?.weightage?.comprehension}% weightage
                      </OptionDetailingText>
                    ) : (
                      ''
                    )}
                    {userData?.databaseQuestions &&
                    userData?.databaseQuestions.length > 0 ? (
                      <OptionDetailingText>
                        {userData?.weightage?.databaseQuestions}% weightage
                      </OptionDetailingText>
                    ) : (
                      ''
                    )}
                  </EachItem>
                </Grid>
              </Grid>
            </StyledQuestionContainer>
            <Tooltip title={isVideoSeen === false ? 'Please watch full video to start exam' : '' } arrow>
              <StyledButton  
                onClick={onClickHandler}
                className={commonClasses.primaryButton}
                isTermsChecked={isTermsChecked!}
                isGuidelinesChecked={isGuidelinesChecked!}
                isVideoSeen = {isVideoSeen}
                disabled={isVideoSeen === false}
              >
                Start Exam
              </StyledButton>
            </Tooltip>
          </MainContent>
        </MainContentWrapper>
      </Container>
      <DialogWrapper open={isGuidelinesPopupOpened!} onBackdropClick={()=>handleCloseDialog(dialogData)} dialogData={dialogData}>
        <GuidelinesPopUp $dialogData = {dialogData}>
          {dialogData ===  guidelinesVariables.clickHere && <CloseIconWrapper onClick={()=>handleCloseDialog(dialogData)} dialogData={dialogData} >
          <HighlightOffIcon/>
          </CloseIconWrapper>}
          <ScrollableContainerForPopup dialogData={dialogData}>
            {dialogData === guidelinesVariables.clickHere ? 
              commonStrings.assessment.assessmentStartPage.guidelinesForImageCapturePupup?.map((guideline: string, index: number) => (
                <GuidelinesWrapper  key={index}>
                  {renderGuideline(guideline)}
                </GuidelinesWrapper>
              )) : 
              <VideoPlayerWrapper>
                <StyledVideoContainer>
                    <ReactPlayer
                      ref={reactPlayerRef}
                      url={assessmentVideo}
                      controls
                      onReady={readyPlayer}
                      onEnded={videoEnded}
                      width="900px"
                      height="100%"
                    />
                </StyledVideoContainer>
                {dialogData !== guidelinesVariables.clickHere && <CloseIconWrapper onClick={()=>handleCloseDialog(dialogData)} dialogData={dialogData}>
                  <HighlightOffIcon/>
                  </CloseIconWrapper>
                }
              </VideoPlayerWrapper>}
          </ScrollableContainerForPopup>
        </GuidelinesPopUp>
      </DialogWrapper>
    </MainWrapper>
  );
};

export default AssessmentDetails;
