import { client } from '../client';
import Endpoints from '../Endpoints';
import { getData } from '../apiUtils';

export const getSaveSQLScore = (
  candidateId: string,
  questionId: string,
  score: number,
  type: string
) => {
  return client
    .post(`${Endpoints.assessmentCredits}`, {
      candidateId,
      questionId,
      score,
      type,
    })
    .then(getData);
};
