import axios from 'axios';
import { baseUrl } from '../../static/constants';
import { getData } from '../apiUtils';
import { client } from '../client';
import Endpoints from '../Endpoints';

const axiosInstance = axios.create();

export const s3UrlDetails = async (payload: object) => {
  return await client
    .post(`${baseUrl}${Endpoints.proctoring}/putObject`, payload)
    .then(getData);
};

export const s3ImageUrl = async (imageURL: string, file: any) => {
  return await axiosInstance
    .put(imageURL, file, { headers: { 'Content-Type': 'image/png' } })
    .then(getData);
};
