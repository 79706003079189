const Endpoints = {
  updateMarkForReview:'/candidates/updateMarkForReview',
  addSubmitTimeUrl: '/userTime/addSubmittedTime',
  assessmentStatusUrl: '/candidates/updateStatusById',
  compareSessionToken: '/candidates/compareSessionToken',
  candidateAssessmentDataUrl: '/candidates/getAssessmentDataByCandidateId',
  enterpriseDataUrl: '/enterprise/getEnterpriseById',
  candidateDetailsUrl: '/candidates/getCandidateById',
  candidateResponseUrl: '/candidates/addCandidateResponses',
  createAndUpdateQuestionUrl: '/interviews/createAndUpdateQuestion',
  createInterviewQuestionsAndResponse: '/interviews/createInterviewQuestionsAndResponse',
  deleteInterviewQuestionsAndResponse: '/interviews/deleteInterviewQuestionsAndResponse',
  updateEditorCodeDetail: '/interviews/updateEditorCodeDetail',
  updateQuestionDetail: '/interviews/updateQuestionDetail',
  endTimeUrl: '/userTime/getEndTime',
  fetchProjectSetupDataUrl: '/codeSnippet',
  fetchQuestionArrayUrl: '/interviews/fetchQuestionArray',
  updateStatusToSubmitted: '/interviews/updateStatusToSubmitted',
  fetchResponseArray: '/interviews/fetchResponseArray',
  setActiveQuestion: '/interviews/setActiveQuestion',
  getInterviewCandidateUrl: '/interviews/getCandidateById',
  addSocketId: '/interviews/addSocketId',
  updateCandidateLastActiveStatus: '/interviews/updateCandidateLastActiveStatus',
  updateInterviewerLastActiveStatus: '/interviews/updateInterviewerLastActiveStatus',
  fetchCandidateLastActiveStatus: '/interviews/fetchCandidateLastActiveStatus',
  fetchInterviewerLastActiveStatus: '/interviews/fetchInterviewerLastActiveStatus',
  requestAccess: '/interviews/requestAccess',
  requestAllowed: '/interviews/requestAllowed',
  endCall: '/interviews/endCall',
  getInterviewDetailsById: '/interviews/getInterviewDetails',
  interviewStatusUrl: '/interviews/updateStatusById',
  interviewToggleUrl: '/interviews/togglestatusById',
  callAccepted: '/interviews/callAccepted',
  screenSharingEnded: '/interviews/screenSharingEnded',
  screenReloaded: '/interviews/screenReloaded',
  plagarismUrl: '/plagarism',
  runCodeUrl: '/code/runInterviewCode',
  setTimerUrl: '/userTime/setTime',
  getPlagiarismUrl: '/plagarism/parameters',
  updatePlagarismDetailsUrl: '/plagarism/parameter',
  submissionMail: '/candidates/submissionUpdate',
  interviewrRemarksURL: '/interviews/updateInterviewParameters',
  proctoring: '/proctoring',
  themeConfiguration: '/themeConfiguration',
  createCandidate: '/candidates/generateLink',
  enterprise: '/enterprise',
  assessmentCredits: '/assessment/credits',
  executionMonitor: '/executionMonitor',
  databaseQuestions: '/databaseQuestion',
  executeSQLCodeURL: '/code/runInterviewCode',
};
export default Endpoints;
