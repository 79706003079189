export const getNameFromEmail = (email: string) => {
  let result = '';

  if (email?.includes('.') && email?.indexOf('.') < email?.indexOf('@')) {
    const username = email?.split('.')[0];
    result = username.charAt(0).toUpperCase() + username.slice(1);
  } else {
    result = email?.split('@')[0];
  }
  return result;
};
