import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { RouterNames, sectionStatus, zecodeLogo } from "../../static/constants";
import Tooltip from "@material-ui/core/Tooltip";

// CSS

import "../../css/assessmentCompleted.css";

// Static
import { commonStrings } from "../../static/commonStrings";
import { Box } from "@mui/material";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  getInterviewCandidate,
  updateInterviewStatus,
} from "../../api/requests/interview";
import { ThemeConfigurationType } from "../Types/Index";
import { setProfileDetailSettings } from "../../util/util";
import { getEnterpriseById } from "../../api/requests/enterprise";
import { getEnterpriseTheme } from "../../api/requests/themeConfiguration";
import ZecodeUrl from "../common/ZecodeUrl";
import { useStylesCommon } from "../common/styles";
import { PoweredBy, Span } from "../assessment/assessmentCompleted";
import { ZecodeText } from "../common/zecodeText";

const BackgroundImage = "/images/interviewsBackground.svg";
const Zecode = "/images/ZecodeLogo.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      borderRadius: "40px",
      width: "65px",
      height: "65px",
    },
    interviewTextContent: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    mainContainer: {
      backgroundImage: `url(${BackgroundImage})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    },
    color: {
      color: "#4A90E2",
    },
    enterpriseName: {
      fontWeight: 500,
      paddingBottom: "30px",
    },
    footerLogo: {
      width: "75px",
      height: "18px",
      marginLeft: "10px",
      marginTop: "3px",
      marginBottom: "27px",
    },
  })
);

const Footer = styled.div`
  padding: 0vh 4vw 0vh 2vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: var(--bodyText-font-family);
`;

const InterviewText = styled.p`
  margin-bottom: 20px;
  font-family: var(--bodyText-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #ffffff;
`;

const LinkText = styled.p`
  font-family: var(--bodyText-font-family);
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #e0e0e0;
`;

const InvitedText = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--bodyText-font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #e0e0e0;
`;

const WelcomeText = styled.p`
  font-family: var(--bodyText-font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  display: flex;
  align-items: center;
  color: #ffffff;
`;

const Hr = styled.hr`
  width: 500px;
  height: 0px;
  margin-bottom: 30px;
  border: 1px solid #e0e0e0;
`;

const StyledContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
`;

const LogoWrapper = styled(Box)`
  display: flex;
  margin-right: 80px;
  margin-top: 20px;
  margin-left: 38px;
`;

const StyledButton = styled.button`
  height: 100%;
  width: 20%;
  text-transform: capitalize;
  margin-top: 14px;
  font-size: 14px;
  color: #ffffff;
`;

const InterviewDetails = () => {
  const classes = useStyles();
  const { id } = useParams();
  const commonClasses = useStylesCommon();
  const navigate = useNavigate();

  const [profileDetails, setProfileDetails] =
    useState<ThemeConfigurationType>();
  const [enterpriseId, setEnterpriseId] = useState();
  const [enterpriseLogo, setEnterpriselogo] = useState("");
  const [enterpriseName, setEnterpriseName] = useState("");
  const [interviewStarted, setInterViewStarted] = useState<boolean>(false);
  const [isCamera, setIsCamera] = useState<boolean>(false);
  const [date, setDate] = useState<string>("");

  const updateStatus = async () => {
    try {
      id && updateInterviewStatus({ id, status: sectionStatus.inProgress });
    } catch (error) {
      console.error(error);
    }
  };

  const onClickProceedHandler = async () => {
    try {
      if (id) {
        const response = await getInterviewCandidate({ id });
        const isCandidateActive = response?.data?.data?.isActive;

        if (isCandidateActive === true) {
          await updateStatus();
          window.open(`${RouterNames.interview}/${id}`, "_blank");
        } else {
          navigate("/");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCandidateDetails = async () => {
    try {
      if (id) {
        const response = await getInterviewCandidate({ id });
        const enterpriseID = response?.data?.data?.enterpriseId;
        const themeResponse =
          enterpriseID && (await getEnterpriseTheme(enterpriseID));
        themeResponse &&
          themeResponse?.data &&
          themeResponse?.data?.data &&
          setProfileDetailSettings(themeResponse?.data?.data);
        setProfileDetails(themeResponse?.data?.data);
        const currentDateTime = new Date();
        const inputDateTimeString = response.data?.data?.scheduledDateTime;
        const inputDateTime = new Date(inputDateTimeString);

        const formattedDateWithMonthName = inputDateTime.toLocaleDateString(
          "en-US",
          {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          }
        );
        setDate(formattedDateWithMonthName);

        if (inputDateTime <= currentDateTime) {
          setInterViewStarted(true);
        }
        setEnterpriseId(response?.data?.data?.enterpriseId);
        if (response?.data?.data?.status === sectionStatus.submitted) {
          navigate("/");
        }
        const enterprise = await getEnterpriseById(
          response?.data?.data?.enterpriseId
        );
        setEnterpriselogo(enterprise?.data?.data?.imageURL);
        setEnterpriseName(enterprise?.data?.data?.name);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCandidateDetails();
  }, []);

  useEffect(() => {
    const checkCamera = async () => {
      try {
        const details = await navigator.mediaDevices.enumerateDevices();
        const detailsData = details.find(
          (obj: any) => obj.kind === "videoinput"
        );
        if (!detailsData?.deviceId || !detailsData?.groupId) {
          setIsCamera(false);
        } else {
          setIsCamera(true);
        }
      } catch (error) {
        console.error(error);
      }
    };
    checkCamera();
  }, []);

  return (
    <StyledContainer className={classes.mainContainer}>
      <LogoWrapper>
        <img
          src={
            profileDetails?.imageURL
              ? profileDetails?.imageURL
              : enterpriseLogo
              ? enterpriseLogo
              : zecodeLogo
          }
          alt="logo"
          className={classes.logo}
        />
      </LogoWrapper>

      <Box className={classes.interviewTextContent}>
        <WelcomeText>
          Welcome to ze<b className={classes.color}>[</b>code
          <b className={classes.color}>]</b>
        </WelcomeText>
        <InterviewText>
          {commonStrings.interview.interviewStartPage.interviewPlatform}
        </InterviewText>
        <InvitedText>
          {commonStrings.interview.interviewStartPage.invitedText}
          <Box className={classes.enterpriseName}>{enterpriseName}</Box>
        </InvitedText>

        <Hr />
        {interviewStarted ? (
          <>
            <LinkText>
              {commonStrings.interview.interviewStartPage.invitedLink}
            </LinkText>
            <StyledButton
              onClick={onClickProceedHandler}
              className={
                isCamera
                  ? commonClasses.outlineButton
                  : commonClasses.disabledOutlineButton
              }
            >
              {commonStrings.interview.interviewStartPage.interviewJoinButton}
            </StyledButton>
          </>
        ) : (
          <LinkText>Interview starts on {date}</LinkText>
        )}
      </Box>

      <Footer>
        <ZecodeUrl />
        <ZecodeText />
      </Footer>
    </StyledContainer>
  );
};

export default InterviewDetails;
