import { baseUrl, Languages, showTableQuery } from '../../static/constants';
import { getData } from '../apiUtils';
import { client } from '../client';
import Endpoints from '../Endpoints';

export const fetchInitialCodeSnippet = async (
  domainId: number,
  questionId: string
) => {
  if(domainId){
    return client
      .get(
        `${baseUrl}${Endpoints.fetchProjectSetupDataUrl}?domainId=${domainId}&questionId=${questionId}`
      )
      .then(getData);
  }
};
export const fetchAllAvailableTables = async () => {
  const response = await client.post(`${Endpoints.executeSQLCodeURL}`, {
    language: Languages[12].value,
    sourcecode: showTableQuery,
  });
  return getData(response);
};
