import React, { useEffect, useRef, useState } from 'react';
import { AsciiValue } from '../../static/constants';
import styled from 'styled-components';
import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  Checkbox,
  RadioGroup,
} from '@mui/material';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useParams } from 'react-router-dom';
import 'react-mde/lib/styles/css/react-mde-all.css';
import gfm from 'remark-gfm';
import math from 'remark-math';
import rehypeKatex from 'rehype-katex';
// CSS
import '../../css/assessmentSections.css';

// Static
import { Status, SECTIONS } from '../../static/constants';
import MarkDownEditor from '../common/markdownEditor';
import { commonStrings } from '../../static/commonStrings';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import MathJax from 'react-mathjax';
import Showdown from 'showdown';
import { getItem, setItem } from '../../util/storage';
import { updatePlagarismDetails } from '../../api/requests/plagarism';
import 'katex/dist/katex.min.css';
// Icons
const startExamIcon = '/images/launchIcon.svg';
const editIcon = '/images/editIcon.svg';

const ReviewWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledContainer = styled.div`
  display: flex;
  margin-right: 3.5vw;
  margin-top: 10px;
  flex-direction: column;
  gap: 16px;
  && .mde-header {
    background: #ebebeb;
    border-radius: 10px 10px 0px 0px;
    border: none;
  }
  && .react-mde.react-mde-tabbed-layout {
    border: 0.5px solid #ebebeb;
    border-radius: 10px 10px 8px 8px;
  }
  && .mde-text {
    background: transparent;
  }
`;
const StyledRadioOptionsContainer = styled.div`
  && {
    padding: 42px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-size:14px;
    width: 100%;
  }
`;

const ComprehesionStyledRadioOptionsContainer = styled.div`
  && {
    padding: 15px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel) <{
  $background: boolean;
}>`
  && {
    height: 38px;
    width: 100%;
    margin: 0px;
    border: 0.5px solid #ebebeb;
    border-radius: 8px;
    background-color: ${(props) =>
    props.$background ? '#d8d8d8' : ' #FCFCFC'};
    color: #000821;
  }
  && span:nth-child(2) {
    font-size: 14px;
    font-family: var(--bodyText-font-family);
  }
`;
const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
});

const StyledRadio = styled(Radio)`
  && {
    &.Mui-checked {
      color: var(--first-color);
    }
  }
`;

const StyledButtonContainer = styled.div`
  && {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    gap: 30px;
  }
`;

const MCQWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;
const OptionMathWrapper = styled.div`
  margin: -30px 0 0 58px;
`;
const MultipleOptionMathWrapper = styled.div`
  margin: -54px 0 0 58px;
  padding:10px;
`;

const Passage = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-family: var(--bodyText-font-family);
  color: #000821;
  display: flex;
  width: 70%;
  && img {
    display: flex;
    width: 400px;
    border: 1px solid black;
    margin-top: 20px;
    margin-left: 40px;
  }
`;

const Question = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-family: var(--bodyText-font-family);
  color: #000821;
  display: flex;
  width: 70%;
  && img {
    display: flex;
    width: 400px;
    border: 1px solid black;
    margin-top: 20px;
    margin-left: 40px;
  }
  && pre {
    white-space:break-spaces;
  }
`;

const StyledButton = styled(Button) <{ $edit?: boolean }>`
  && {
    background: ${(props) => (props.$edit ? '#F2A22E' : 'var(--first-color)')};
    border: 1px solid
      ${(props) => (props.$edit ? '#F2A22E' : 'var(--first-color)')};
    border-radius: 8px;
    height: 38px;
    font-family: var(--bodyText-font-family);
    font-size: 14px!important;
    font-weight: 500;
    text-transform: capitalize;
    color: var(--button-text-color);
    padding: 5px 30.3px 5px 30.3px !important;
    &:hover {
      background: var(--first-color);
      border:1px solid var(--first-color);
      box-shadow: 0 0 10px var(--first-color);
    }
  }
`;

const StyledRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding-right: 15px;
`;

const MCQQuestions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const StyledTableData = styled.div`
  display: flex;
  font-family: var(--bodyText-font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000821;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
`;

const StyledCodingSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 3.5vw;
  gap: 50px !important;
  font-family: var(--bodyText-font-family);
`;

const Attempted = styled.div`
  display: flex;
  color: #28b894;
  font-size: 16px;
  width: 250px;
  font-weight: 500;
`;

const NotAttempted = styled.div`
  color: #d9d9d9;
  font-size: 16px;
  width: 250px;
  font-weight: 500;
  display: flex;
`;

const MarkedForReview = styled.div`
  display: flex;
  width: 250px;
  align-items: center;
  color: #fbb753;
  font-size: 16px;
  font-weight: 500;
`;

const QuestionIconWrapper = styled(Box)`
  width: 75px;
  display: flex;
  justify-content: end;
  align-items: center;
  cursor: pointer;
`;

const EditQuestionIconWrapper = styled(Box)`
  width: 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const UnCheckedIcon = styled(CropSquareIcon)`
  color: var(--first-color);
`;

const CheckedIcon = styled(CheckBoxIcon)`
  color: var(--first-color);
`;

const Label = styled.label`
  font-family: var(--bodyText-font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000821;
  && img {
    display: flex;
    width: 400px;
    border: 1px solid black;
    margin-top: 20px;
    margin-left: 40px;
  }
  ul{
  padding-left:35px;
  }
`;

const OpenEndedMathWrapper = styled.div`
  margin: -2px 0 0 3px;
`;

const MCQMathWrapper = styled.div`
  margin: -25px 0px 0px 32px;
  max-width: 950px;
`;

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  overflow-y: auto;
  max-height: 55vh;
  &::-webkit-scrollbar {
    width: 3px;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--first-color);
    border-radius: 10px;
  }
`;

const QuestionNo = styled.div`
  font-family: var(--bodyText-font-family);
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0em;
`;

const mathHandler = (text: any) => {
  return (<ReactMarkdown remarkPlugins={[remarkGfm, math]} rehypePlugins={[rehypeKatex]}>
    {text}
  </ReactMarkdown>)
}

export const OpenEnded = (props: any) => {
  const {
    setPopupTrigger,
    setPopupSection,
    questions,
    setQuestions,
    updateResponses,
    markedForReviewQuestions,
    onClickCheckBoxHandler,
    leftTab,
    setLeftTab
  } = props;

  const candidateId = useParams();
  const handleNext = async () => {
    // await updateResponses();
    if (questions.questionNo.openEnd < questions.question.openEnd.length - 1)
      setQuestions({
        ...questions,
        questionNo: {
          ...questions.questionNo,
          openEnd: questions.questionNo.openEnd + 1,
        },
      });
  };

  const handlePrev = async () => {
    // await updateResponses();
    if (questions.questionNo.openEnd !== 0)
      setQuestions({
        ...questions,
        questionNo: {
          ...questions.questionNo,
          openEnd: questions.questionNo.openEnd - 1,
        },
      });
  };

  const getButtonContent = () => {
    if (
      questions.questionNo.openEnd ===
      questions.question.openEnd.length - 1
    ) {
      if (questions?.question?.comprehension?.length === 0) {
        if (questions?.question?.databaseQuestions?.length === 0) {
          return commonStrings.assessment.buttonContent.submit;
        }
      }
      return commonStrings.assessment.buttonContent.nextSection;
    } else {
      return commonStrings.assessment.buttonContent.next;
    }
  };

  const handleSubmit = () => {
    updateResponses();
    if (
      getButtonContent() === commonStrings.assessment.buttonContent.nextSection
    ) {
      setPopupSection(`${SECTIONS.openEnd}submit`);
    } else if (
      getButtonContent() === commonStrings.assessment.buttonContent.submit
    ) {
      setPopupSection(Status.submitAssessment);
    }
    setPopupTrigger(true);
  };

  const handleChange = (value: string, id: string) => {
    const newData = { ...questions };
    if (
      newData.answer.openEnd[questions.questionNo.openEnd]?.value?.length ===
      undefined &&
      value.length > 1
    ) {
      updatePlagiarismParameters();
    }
    if (
      value.length -
      newData.answer.openEnd[questions.questionNo.openEnd]?.value?.length >
      1
    ) {
      updatePlagiarismParameters();
    }
    newData.answer.openEnd[questions.questionNo.openEnd] = {
      value,
      id,
    };
    setQuestions(newData);
  };

  const updatePlagiarismParameters = () => {
    let pasteCount = getItem('pasteCount') ? Number(getItem('pasteCount')) : 0;
    if (leftTab) {
      pasteCount += 1;
      setItem('pasteCount', pasteCount.toString());
      updatePlagarismDetails(null, candidateId.id!, null, pasteCount, null);
      setLeftTab(false);
    }
  };

  const clickHandler = (questions: any) => {
    if (
      questions.questionNo.openEnd !==
      questions.question.openEnd.length - 1
    ) {
      return handleNext;
    }
    return handleSubmit;
  };

  //Saving open ended responses after 1sec 
  useEffect(() => {
    const getData = setTimeout(async () => {
          await updateResponses();
        }, 1000);
    return () => clearTimeout(getData);
  }, [questions])

  return (
    <StyledContainer>
      <ReviewWrapper>
        <Question>
          <ReactMarkdown remarkPlugins={[gfm, math]} rehypePlugins={[rehypeKatex]}>
            {`Q${questions.questionNo.openEnd + 1}.&nbsp;${questions.question.openEnd[
              questions.questionNo.openEnd
            ]?.question
              }`}
          </ReactMarkdown>
        </Question>
      </ReviewWrapper>
      <Box>
        <MarkDownEditor
          value={
            (questions.answer.openEnd[questions.questionNo.openEnd]?.value ?? '')}
          onChange={(value) =>
            handleChange(
              value,
              questions.question.openEnd[questions.questionNo.openEnd]._id
            )
          }
        />
      </Box>
      <StyledButtonContainer>
        {questions.questionNo.openEnd !== 0 && (
          <StyledButton disableRipple onClick={handlePrev}>
            Previous
          </StyledButton>
        )}
        {getButtonContent() !== 'Submit Exam' && (
          <StyledButton onClick={clickHandler(questions)} disableRipple>
            {getButtonContent()}
          </StyledButton>
        )}
      </StyledButtonContainer>
    </StyledContainer>
  );
};

export const MultipleChoice = (props: any) => {
  const {
    setPopupTrigger,
    setPopupSection,
    questions,
    setQuestions,
    updateResponses,
    markedForReviewQuestions,
    onClickCheckBoxHandler,
  } = props;
  const [selectedOption, setSelectedOption] = useState<any>();
  const handleNext = async () => {
    // await updateResponses();
    if (
      questions.questionNo.multipleChoice <
      questions.question.multipleChoice.length - 1
    )
      setQuestions({
        ...questions,
        questionNo: {
          ...questions.questionNo,
          multipleChoice: questions.questionNo.multipleChoice + 1,
        },
      });
  };

  const handlePrev = async () => {
    // await updateResponses();
    if (questions.questionNo.multipleChoice !== 0)
      setQuestions({
        ...questions,
        questionNo: {
          ...questions.questionNo,
          multipleChoice: questions.questionNo.multipleChoice - 1,
        },
      });
  };

  const onChangeHandler = (option: string, id: string, radio: boolean) => {
    const newData = { ...questions };
    if (
      newData.answer.multipleChoice.find(
        (item: any) => item.option === option && item.id === id
      ) &&
      !radio
    ) {
      const filteredAnswer = newData.answer.multipleChoice.filter(
        (item: any) => item.id !== id
      );
      const revisedData = newData.answer.multipleChoice.filter(
        (item: any) => item.option !== option && item.id === id
      );
      setQuestions({
        ...newData,
        answer: {
          ...newData.answer,
          multipleChoice: [...revisedData, ...filteredAnswer],
        },
      });
    } else if (radio) {
      const revisedData = newData.answer.multipleChoice.filter(
        (item: any) => item.id !== id
      );
      setQuestions({
        ...newData,
        answer: {
          ...newData.answer,
          multipleChoice: [
            ...revisedData,
            {
              id,
              option,
            },
          ],
        },
      });
    } else {
      newData.answer.multipleChoice.push({
        id,
        option,
      });
      setQuestions(newData);
    }
  };
  const getButtonContent = () => {
    if (
      questions.questionNo.multipleChoice ===
      questions.question.multipleChoice.length - 1
    ) {
      if (questions?.question?.codingChallenges?.length === 0) {
        if (questions.question.openEnd.length === 0) {
          if (questions?.question?.comprehension?.length === 0) {
            if (questions?.question?.databaseQuestions?.length == 0) {
              return commonStrings.assessment.buttonContent.submit;
            }
          }
        }
      }
      return commonStrings.assessment.buttonContent.nextSection;
    } else {
      return commonStrings.assessment.buttonContent.next;
    }
  };

  const handleSubmit = () => {
    updateResponses();
    if (
      getButtonContent() === commonStrings.assessment.buttonContent.nextSection
    ) {
      setPopupSection(`${SECTIONS.multipleChoice}submit`);
    } else if (
      getButtonContent() === commonStrings.assessment.buttonContent.submit
    ) {
      setPopupSection(Status.submitAssessment);
    }
    setPopupTrigger(true);
  };

  const clickHandler = (questions: any) => {
    if (
      questions.questionNo.multipleChoice !==
      questions.question.multipleChoice.length - 1
    ) {
      return handleNext;
    }
    return handleSubmit;
  };

  const getOption = (index: number) => {
    return String.fromCharCode(AsciiValue + index);
  };

  const getSelectedValue = (value: string, data: any[], id: string) => {
    return data.filter(
      (item: any) => item?.option === value && item?.id === id
    );
  };

  const getNoOfAnswers = (data: any[], id: string) => {
    return (
      data
        ?.find((obj: any) => obj?._id === id)
        ?.options?.filter((item: any) => item?.isCorrect)?.length > 1
    );
  };

  //Saving multiple choice responses on selection
  useEffect(() => {
    updateResponses();
  }, [questions])

  return (
    <StyledContainer>
      <div>
        <ReviewWrapper>
          <Label>
            <ReactMarkdown remarkPlugins={[gfm, math]} rehypePlugins={[rehypeKatex]}>
              {`Q${questions.questionNo.multipleChoice + 1
                }.&nbsp;${questions.question.multipleChoice[
                  questions.questionNo.multipleChoice
                ]?.question.split('`').join(' ')
                }`}
            </ReactMarkdown>
          </Label>
        </ReviewWrapper>
        <StyledRadioOptionsContainer>
          {questions.question.multipleChoice[
            questions.questionNo.multipleChoice
          ]?.options?.map((option: any, index: number) =>
            getNoOfAnswers(
              questions.question.multipleChoice,
              questions.question.multipleChoice[
                questions.questionNo.multipleChoice
              ]?._id
            ) ? (
              <>
                <StyledFormControlLabel
                  $background={
                    getSelectedValue(
                      option.option,
                      questions.answer.multipleChoice,
                      questions.question.multipleChoice[
                        questions.questionNo.multipleChoice
                      ]?._id
                    )?.length > 0
                      ? true
                      : false
                  }
                  key={index}
                  control={
                    <Checkbox
                      icon={<UnCheckedIcon fontSize='medium' />}
                      checkedIcon={<CheckedIcon fontSize='medium' />}
                      checked={
                        getSelectedValue(
                          option.option,
                          questions.answer.multipleChoice,
                          questions.question.multipleChoice[
                            questions.questionNo.multipleChoice
                          ]?._id
                        )?.length > 0
                          ? true
                          : false
                      }
                      onChange={() => {
                        onChangeHandler(
                          option.option,
                          questions.question.multipleChoice[
                            questions.questionNo.multipleChoice
                          ]?._id,
                          false
                        );
                      }}
                    />
                  }
                  label={`${getOption(index)}. `}
                />
                <MultipleOptionMathWrapper>
                  {mathHandler(option.option)}
                </MultipleOptionMathWrapper>
              </>
            ) : (
              <RadioGroup
                key={option?.option}
                row
                value={
                  getSelectedValue(
                    option.option,
                    questions.answer.multipleChoice,
                    questions.question.multipleChoice[
                      questions.questionNo.multipleChoice
                    ]._id
                  )?.[0]?.option ?? ''
                }
                onChange={(event: any) => {
                  setSelectedOption(event.target.value);
                  onChangeHandler(
                    event.target.value,
                    questions.question.multipleChoice[
                      questions.questionNo.multipleChoice
                    ]?._id,
                    true
                  );
                }}
              >
                <StyledFormControlLabel
                  value={option.option}
                  control={<StyledRadio />}
                  label={`${getOption(index)}. `}
                  $background={
                    selectedOption === option.option ? true : false
                  }
                />
                <OptionMathWrapper>
                  {mathHandler(option.option)}
                </OptionMathWrapper>
              </RadioGroup>
            )
          )}
        </StyledRadioOptionsContainer>
      </div>
      <StyledButtonContainer>
        {questions.questionNo.multipleChoice !== 0 && (
          <StyledButton onClick={handlePrev} disableRipple>
            Previous
          </StyledButton>
        )}
        {getButtonContent() !== 'Submit Exam' && (
          <StyledButton onClick={clickHandler(questions)} disableRipple>
            {getButtonContent()}
          </StyledButton>
        )}
      </StyledButtonContainer>
    </StyledContainer>
  );
};

export const CodingChallenges = (props: any) => {
  const {
    setIsCodeEditorOpen,
    questions,
    setQuestions,
    setPopupTrigger,
    setPopupSection,
    markedForReviewQuestions,
    openHandler
  } = props;

  const handleSubmitCodingChallenge = async () => {
    setPopupSection(`${SECTIONS.codingChallenges}submit`);
    setPopupTrigger(true);
  };


  const getButtonContent = () => {
    if (questions.question.openEnd.length === 0) {
      if (questions?.question?.comprehension?.length === 0) {
        {
          if (questions?.question?.databaseQuestions?.length == 0) {
            return commonStrings.assessment.buttonContent.submit;
          }
        }
      }
    }
    return commonStrings.assessment.buttonContent.nextSection;
  };

  const clickHandler = (questions: any) => {
    return handleSubmitCodingChallenge;
  };

  return (
    <StyledCodingSection>
      <QuestionContainer>
        {questions.question.codingChallenges.map(
          (questionDetail: any, index: number) => (
            <StyledRow key={index}>
              <StyledTableData>
                Q{index + 1}.&nbsp; {questionDetail?.title}
              </StyledTableData>
              {markedForReviewQuestions?.includes(questionDetail._id) ? (
                <MarkedForReview>(Marked for review)</MarkedForReview>
              ) : questions?.answer?.codingChallenges[index] ? (
                <Attempted>(Attempted)</Attempted>
              ) : (
                <NotAttempted>(Not Attempted)</NotAttempted>
              )}

              <>
                {questions?.answer?.codingChallenges[index] ? (
                  <EditQuestionIconWrapper>
                    <img
                      src={editIcon}
                      alt='Edit Exam'
                      onClick={() => openHandler(index)}
                    />
                    <img
                      src={startExamIcon}
                      alt='edit Exam'
                      onClick={() => openHandler(index)}
                    />
                  </EditQuestionIconWrapper>
                ) : (
                  <QuestionIconWrapper>
                    <img
                      src={startExamIcon}
                      alt='Start Exam'
                      onClick={() => openHandler(index)}
                    />
                  </QuestionIconWrapper>
                )}
              </>
            </StyledRow>
          )
        )}
        </QuestionContainer>
        {getButtonContent() ===
          commonStrings.assessment.buttonContent.nextSection && (
            <StyledButtonContainer>
              <StyledButton onClick={clickHandler(questions)} disableRipple>
                {getButtonContent()}
              </StyledButton>
            </StyledButtonContainer>
          )}
      
    </StyledCodingSection>
  );
};

export const Comprehension = (props: any) => {
  const {
    setPopupTrigger,
    setPopupSection,
    questions,
    setQuestions,
    updateResponses,
    markedForReviewQuestions,
    onClickCheckBoxHandler,
    questionRef 
  } = props;
  const [selectedOption, setSelectedOption] = useState<any>();
  const handleNext = async () => {
    if (
      questions.questionNo.comprehension <
      questions.question.comprehension.length - 1
    )
      setQuestions({
        ...questions,
        questionNo: {
          ...questions.questionNo,
          comprehension: questions.questionNo.comprehension + 1,
        },
      });
      questionRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handlePrev = async () => {
    if (questions.questionNo.comprehension !== 0)
      setQuestions({
        ...questions,
        questionNo: {
          ...questions.questionNo,
          comprehension: questions.questionNo.comprehension - 1,
        },
      });
      questionRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const getButtonContent = () => {
    if (
      questions.questionNo.comprehension ===
      questions.question.comprehension.length - 1
    ) {
      if (questions?.question?.databaseQuestions?.length === 0) {
        return commonStrings.assessment.buttonContent.submit;
      }
      return commonStrings.assessment.buttonContent.nextSection;
    } else {
      return commonStrings.assessment.buttonContent.next;
    }
  };

  const getNoOfAnswers = (data: any[], id: string) => {
    return (
      data
        ?.find((obj: any) => obj?.id === id)
        ?.options?.filter((item: any) => item?.isCorrect)?.length > 1
    );
  };

  const getSelectedValue = (value: string, data: any[], id: string) => {
    return data.filter(
      (item: any) => item?.option === value && item?.id === id
    );
  };

  const onChangeHandler = (option: string, id: string, radio: boolean) => {
    const newData = { ...questions };
    if (
      newData.answer.comprehension.find(
        (item: any) => item.option === option && item.id === id
      ) &&
      !radio
    ) {
      const filteredAnswer = newData.answer.comprehension.filter(
        (item: any) => item.id !== id
      );
      const revisedData = newData.answer.comprehension.filter(
        (item: any) => item.option !== option && item.id === id
      );
      setQuestions({
        ...newData,
        answer: {
          ...newData.answer,
          comprehension: [...revisedData, ...filteredAnswer],
        },
      });
    } else if (radio) {
      const revisedData = newData.answer.comprehension.filter(
        (item: any) => item.id !== id
      );
      setQuestions({
        ...newData,
        answer: {
          ...newData.answer,
          comprehension: [
            ...revisedData,
            {
              id,
              option,
            },
          ],
        },
      });
    } else {
      newData.answer.comprehension.push({
        id,
        option,
      });
      setQuestions(newData);
    }
  };

  const getOption = (index: number) => {
    return String.fromCharCode(AsciiValue + index);
  };

  const handleSubmit = () => {
    updateResponses();
    if (getButtonContent() === commonStrings.assessment.buttonContent.submit) {
      setPopupSection(Status.submitAssessment);
    } else if (
      getButtonContent() === commonStrings.assessment.buttonContent.nextSection
    ) {
      setPopupSection(`${SECTIONS.comprehension}submit`);
    }
    setPopupTrigger(true);
  };

  const clickHandler = (questions: any) => {
    if (
      questions.questionNo.comprehension !==
      questions.question.comprehension.length - 1
    ) {
      return handleNext;
    }
    return handleSubmit;
  };

  //Saving comprehension related multiple choice responses on selection
  useEffect(() => {
    updateResponses();
  }, [questions])

  return (
    <StyledContainer >
      <ReviewWrapper>
        <Passage>
          <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeKatex]}>
            {`Q${questions.questionNo.comprehension + 1}.&nbsp;${questions.question.comprehension[
              questions.questionNo.comprehension
            ]?.question
              }`}
          </ReactMarkdown>
        </Passage>
      </ReviewWrapper>
      <QuestionNo>
        <p>
          Questions to attend : 1 to{' '}
          {
            questions.question.comprehension[questions.questionNo.comprehension]
              .questionSet.length
          }
        </p>
      </QuestionNo>
      <MCQWrapper>
        {questions.question.comprehension[questions.questionNo.comprehension]
          .questionSet &&
          questions.question.comprehension[questions.questionNo.comprehension]
            .questionSet.length > 0 &&
          questions.question.comprehension[
            questions.questionNo.comprehension
          ].questionSet.map((question: any, index1: number) => (
            <MCQQuestions key={index1}>
              <ReviewWrapper>
                <Label>
                  <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeKatex]}>
                    {`Q${index1 + 1}.&nbsp;${question.question}`}
                  </ReactMarkdown>
                </Label>
              </ReviewWrapper>
              <ComprehesionStyledRadioOptionsContainer>
                {getNoOfAnswers(
                  questions.question.comprehension[
                    questions.questionNo.comprehension
                  ]?.questionSet,
                  questions.question.comprehension[
                    questions.questionNo.comprehension
                  ].questionSet[index1]?.id
                )
                  ? question.options?.map((option: any, index: number) => (
                    <StyledFormControlLabel
                      $background={
                        getSelectedValue(
                          option.option,
                          questions.answer.comprehension,
                          questions.question.comprehension[
                            questions.questionNo.comprehension
                          ].questionSet[index1].id
                        )?.length > 0
                      }
                      key={option?.option}
                      control={
                        <Checkbox
                          icon={<UnCheckedIcon fontSize='medium' />}
                          checkedIcon={<CheckedIcon fontSize='medium' />}
                          checked={
                            getSelectedValue(
                              option.option,
                              questions.answer.comprehension,
                              questions.question.comprehension[
                                questions.questionNo.comprehension
                              ].questionSet[index1].id
                            )?.length > 0
                              ? true
                              : false
                          }
                          onChange={() => {
                            onChangeHandler(
                              option.option,
                              question.id,
                              false
                            );
                          }}
                        />
                      }
                      label={`${getOption(index)}. ${option.option}`}
                    />
                  ))
                  : question.options?.map((option: any, index: number) => (
                    <RadioGroup
                      key={option?.option}
                      row
                      value={
                        getSelectedValue(
                          option.option,
                          questions.answer.comprehension,
                          questions.question.comprehension[
                            questions.questionNo.comprehension
                          ].questionSet[index1].id
                        )?.[0]?.option ?? ''
                      }
                      onChange={(event: any) => {
                        setSelectedOption(event.target.value);
                        onChangeHandler(
                          event.target.value,
                          questions.question.comprehension[
                            questions.questionNo.comprehension
                          ].questionSet[index1].id,
                          true
                        );
                      }}
                    >
                      <StyledFormControlLabel
                        value={option.option}
                        control={<StyledRadio />}
                        label={`${getOption(index)}. ${option.option}`}
                        $background={
                          selectedOption === option.option ? true : false
                        }
                      />
                    </RadioGroup>
                  ))}
              </ComprehesionStyledRadioOptionsContainer>
            </MCQQuestions>
          ))}
      </MCQWrapper>
      <StyledButtonContainer>
        {questions.questionNo.comprehension !== 0 && (
          <StyledButton onClick={handlePrev}>Previous</StyledButton>
        )}
        {getButtonContent() === commonStrings.assessment.buttonContent.next && (
          <StyledButton onClick={clickHandler(questions)}>
            {getButtonContent()}
          </StyledButton>
        )}
        {getButtonContent() ===
          commonStrings.assessment.buttonContent.nextSection && (
            <StyledButton onClick={handleSubmit}>
              {getButtonContent()}
            </StyledButton>
          )}
      </StyledButtonContainer>
    </StyledContainer>
  );
};

export const DatabaseQuestions = (props: any) => {
  const {
    setIsCodeEditorOpen,
    questions,
    setQuestions,
    setPopupTrigger,
    setPopupSection,
    markedForReviewQuestions,
    openHandler
  } = props;

  const handleSubmitDatabaseQuestion = async () => {
    setPopupSection(`${SECTIONS.databaseQuestions}submit`);
    setPopupTrigger(true);
  };

  const handleSubmit = (index: number) => {
    const newData = { ...questions };
    newData.questionNo.databaseQuestions = index;
    setQuestions(newData);
    setIsCodeEditorOpen(true);
  };

  const getButtonContent = () => {
    return commonStrings.assessment.buttonContent.submit;
  };

  const clickHandler = (questions: any) => {
    return handleSubmitDatabaseQuestion;
  };

  return (
    <StyledCodingSection>
      <QuestionContainer>
        {questions.question.databaseQuestions.map(
          (questionDetail: any, index: number) => (
            <StyledRow key={index}>
              <StyledTableData>
                Q{index + 1}.&nbsp; {questionDetail?.title}
              </StyledTableData>
              {markedForReviewQuestions?.includes(questionDetail._id) ? (
                <MarkedForReview>(Marked for review)</MarkedForReview>
              ) : questions?.answer?.databaseQuestions[index] ? (
                <Attempted>(Attempted)</Attempted>
              ) : (
                <NotAttempted>(Not Attempted)</NotAttempted>
              )}

              <>
                {questions.answer.databaseQuestions[index] ? (
                  <EditQuestionIconWrapper>
                    <img
                      src={editIcon}
                      alt='Edit Exam'
                      onClick={() => openHandler(index)}
                    />
                    <img
                      src={startExamIcon}
                      alt='edit Exam'
                      onClick={() => openHandler(index)}
                    />
                  </EditQuestionIconWrapper>
                ) : (
                  <QuestionIconWrapper>
                    <img
                      src={startExamIcon}
                      alt='Start Exam'
                      onClick={() => openHandler(index)}
                    />
                  </QuestionIconWrapper>
                )}
              </>
            </StyledRow>
          )
        )}
        {getButtonContent() ===
          commonStrings.assessment.buttonContent.nextSection && (
            <StyledButtonContainer>
              <StyledButton onClick={clickHandler(questions)}>
                {getButtonContent()}
              </StyledButton>
            </StyledButtonContainer>
          )}
      </QuestionContainer>
    </StyledCodingSection>
  );
};
