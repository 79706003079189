import { client } from "../client";
import Endpoints from "../Endpoints";
import { getData } from "../apiUtils";

export const updateInterviewStatus = ({
  id,
  status,
  isInterviewerData,
  enterpriseId,
}: {
  id: string;
  status: string;
  isInterviewerData?: string | null;
  enterpriseId?: string;
}) => {
  return client.post(`${Endpoints.interviewStatusUrl}`, {
    id,
    status,
    isInterviewerData,
    enterpriseId,
  });
};

export const interviewToggle = (isActive: boolean, id?: string) => {
  return client.post(`${Endpoints.interviewToggleUrl}`, {
    isActive,
    id,
  });
};

export const candidateCallAccepted = (id: string, isCallAccepted: boolean) => {
  return client.post(`${Endpoints.callAccepted}`, {
    id,
    isCallAccepted,
  });
};

export const screenSharingEnded = (id: string, screenSharingEnded: boolean) => {
  return client.post(`${Endpoints.screenSharingEnded}`, {
    id,
    screenSharingEnded,
  });
};

export const screenReloaded = (
  id: string,
  isScreenReloaded: boolean,
  isInterviewer: boolean
) => {
  return client.post(`${Endpoints.screenReloaded}`, {
    id,
    isScreenReloaded,
    isInterviewer,
  });
};

export const saveInterviewerRemarks = (id: string, interviewerRemarks: any) => {
  return client.post(`${Endpoints.interviewrRemarksURL}`, {
    id,
    interviewerRemarks,
  });
};

export const requestAccess = (
  id?: string,
  isInterviewer?: boolean,
  accessRequest?: boolean
) => {
  return client
    .post(`${Endpoints.requestAccess}`, {
      id,
      isInterviewer,
      accessRequest,
    })
    .then(getData);
};

export const requestAllowed = (
  id?: string,
  isInterviewer?: boolean,
  requestAllowed?: boolean
) => {
  return client
    .post(`${Endpoints.requestAllowed}`, {
      id,
      isInterviewer,
      requestAllowed,
    })
    .then(getData);
};

export const endCall = (id: string) => {
  return client
    .post(`${Endpoints.endCall}`, {
      id,
    })
    .then(getData);
};

export const saveSocketId = (
  id: string,
  candidateSocketId?: string | null,
  interviewerSocketId?: string | null
) => {
  return client
    .post(`${Endpoints.addSocketId}`, {
      id,
      candidateSocketId,
      interviewerSocketId,
    })
    .then(getData);
};

export const updateCandidateLastActiveStatus = (id: string) => {
  return client
    .post(`${Endpoints.updateCandidateLastActiveStatus}`, { id })
    .then(getData);
};

export const updateInterviewerLastActiveStatus = async (id: string) => {
  const response = await client.post(
    `${Endpoints.updateInterviewerLastActiveStatus}`,
    { id }
  );
  return getData(response);
};

export const fetchCandidateLastActiveStatus = (id: string) => {
  return client
    .post(`${Endpoints.fetchCandidateLastActiveStatus}`, { id })
    .then(getData);
};

export const fetchInterviewerLastActiveStatus = (id: string) => {
  return client
    .post(`${Endpoints.fetchInterviewerLastActiveStatus}`, { id })
    .then(getData);
};

export const getInterviewCandidate = ({
  id,
  isInterviewerStartTime,
  isInterviewerData,
}: {
  id: string;
  isInterviewerStartTime?: boolean;
  isInterviewerData?: string | null;
}) => {
  return client.post(`${Endpoints.getInterviewCandidateUrl}`, {
    id,
    isInterviewerStartTime,
    isInterviewerData,
  });
};
