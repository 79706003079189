import React from 'react';
import MonacoEditor from '@monaco-editor/react';
import { CodeResponse } from '../Types/Index';
import { sectionStatus } from '../../static/constants';

interface InterviewMonacoComponentProps {
    activeCodeDetails?: CodeResponse,
    selectedIDE?: number,
    theme?: boolean,
    onMonacoEditorChange?: (value: string | undefined) => void,
    typingAccessAllowed?: boolean,
    loader?: boolean,
    status?: string
}

const InterviewMonacoComponent: React.FC<InterviewMonacoComponentProps> = ({
    activeCodeDetails,
    selectedIDE,
    theme,
    onMonacoEditorChange,
    typingAccessAllowed,
    status
}) => {
    return (
        <MonacoEditor
            key={activeCodeDetails?._id}
            language={selectedIDE === 16
                ? 'python'
                : activeCodeDetails?.template}
            value={activeCodeDetails?.code}
            onChange={onMonacoEditorChange}
            theme={theme ? 'light' : 'vs-dark'}
            path={selectedIDE === 16
                ? 'python'
                : activeCodeDetails?.template}
            options={{ minimap: { enabled: false }, readOnly: (!typingAccessAllowed && status !== sectionStatus.submitted)}} />
    )
}

export default InterviewMonacoComponent;
