import { client } from '../client';
import Endpoints from '../Endpoints';
import { statusType } from '../../components/Types/Index';
import { getData } from '../apiUtils';

export interface updateAssessmentStatusProps {
  timeSpent?: number; 
  markedForReview?: string[];
  isGuidelinesChecked?: boolean; 
  isTermsChecked?: boolean; 
  inProgress?:boolean;  
  timeUp?:boolean;  
  }
  export const updateAssessmentStatus = (props:updateAssessmentStatusProps,id:string) => {
  return client.post(`${Endpoints.assessmentStatusUrl}`, {
  props,
  id
  })
  .then(getData); 
  };

export const updateMarkForReview=(
  id:string,
  markedForReview:string[]
)=>{
    return client.post(`${Endpoints.updateMarkForReview}`,{
      id,
      markedForReview
    })
}

export const getCandidateDetails = (id: string) => {
  return client.post(`${Endpoints.candidateDetailsUrl}`, {
    id,
  });
};

export const candidateResponses = (
  testStatus: statusType,
  questionsResponse: any,
  id: string,
  questionId?:string,
  section? : string
) => {
  return client.post(`${Endpoints.candidateResponseUrl}`, {
    id,
    testStatus,
    questionsResponse,
    questionId,
    section
  });
};

export const getAssessmentDataByCandidateId = (id: string) => {
  return client.get(`${Endpoints.candidateAssessmentDataUrl}/${id}`);
};

export const getSubmissionEmail = (
  email: string,
  assessmentName: string,
  enterpriseName: string,
  enterpriseLogo: string,
  enterpriseColor: string
) => {
  return client.post(`${Endpoints.submissionMail}`, {
    email,
    assessmentName,
    enterpriseName,
    enterpriseLogo,
    enterpriseColor,
  }).then(getData);
};

export const createCompareSession = (
  id: string,
  urlToken: string | null,
  localstorageToken: string | null,
) => {
  return client.post(`${Endpoints.compareSessionToken}`, {
    id,
    urlToken,
    localstorageToken
  }).then(getData);
}
