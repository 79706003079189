import { client } from "../client";
import Endpoints from "../Endpoints";
import { getData } from "../apiUtils";
import { Question } from "../../components/Types/Index";

export const createQuestionsAndUpdateQuestions = (
  activeQuestionInfo: Question[],
  activeQuestionNo: number,
  id?: string,
  isInterviewer?: boolean,
  loader?: boolean,
  activeFile?: string
) => {
  return client.post(`${Endpoints.createAndUpdateQuestionUrl}`, {
    activeQuestionInfo: activeQuestionInfo,
    activeQuestionNo,
    id,
    isInterviewer,
    loader,
    activeFile,
  });
};

export const updateEditorCode = (
  id?: string,
  code?: string,
  ide?: number,
  template?: string,
  files?: any,
  isInterviewer?: boolean,
  activeFile?: string
) => {
  return client.post(`${Endpoints.updateEditorCodeDetail}`, {
    id,
    editorCode: code,
    ide,
    template,
    files,
    isInterviewer,
    activeFile,
  });
};

export const updateQuestion = (id?: string, questionDescription?: string) => {
  return client.post(`${Endpoints.updateQuestionDetail}`, {
    id,
    questionDescription,
  });
};

export const createQuestionAndResponse = (
  id?: string,
  enterpriseId?: string
) => {
  return client.post(`${Endpoints.createInterviewQuestionsAndResponse}`, {
    id,
    enterpriseId,
  });
};

export const deleteQuestionAndResponse = (
  id?: string,
  questionId?: string,
  index?: number,
  nextQuestionId?: string,
  nextResponseId?: string,
  ide?: number
) => {
  return client.post(`${Endpoints.deleteInterviewQuestionsAndResponse}`, {
    id,
    questionId,
    index,
    nextQuestionId,
    nextResponseId,
    ide,
  });
};

export const setActiveQuestion = (
  interviewId?: string,
  questionId?: string,
  responseId?: string,
  isInterviewer?: boolean,
  index?: number,
  ide?: number
) => {
  return client.post(`${Endpoints.setActiveQuestion}`, {
    interviewId,
    questionId,
    responseId,
    isInterviewer,
    index,
    ide,
  });
};

export const fetchQuestionsArray = (id?: string) => {
  return client
    .post(`${Endpoints.fetchQuestionArrayUrl}`, {
      id,
    })
    .then(getData);
};

export const fetchResponsesArray = (
  interviewId?: string,
  searchQuestions?: string
) => {
  return client
    .post(`${Endpoints.fetchResponseArray}`, {
      interviewId,
      searchQuestions,
    })
    .then(getData);
};

export const fetchSnippetCode = (
  domainId: number,
  isInterview: boolean,
  questionId: string
) => {
  return client
    .get(
      `${Endpoints.fetchProjectSetupDataUrl}?domainId=${domainId}&isInterview=${isInterview}&questionId=${questionId}`
    )
    .then(getData);
};

export const updateStatusToSubmitted = async (
  id: string,
  enterpriseId: string,
  isInterviewerData: string | null
) => {
  const response = await client.post(`${Endpoints.updateStatusToSubmitted}`, {
    id,
    enterpriseId,
    isInterviewerData,
  });
  return getData(response);
};
